import axios from "axios";

export const createRide = async (
  start,
  destination,
  date,
  time,
  details,
  token
) =>
  await axios.post(
    `/api/create-ride`,
    {
      start,
      destination,
      date,
      time,
      details,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const userRides = async (token) =>
  await axios.get(`/api/all/user/rides`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (token, id) =>
  await axios.get(`/api/ride/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const noUserRead = async (token, id) =>
  await axios.get(
    `/api/nouser/ride/${id}`,
    { data: null },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const searchRides = async (arg) =>
  await axios.post(`/api/search/rides`, {
    start: arg.start,
    destination: arg.destination,
    date: arg.date !== "undefined" ? arg.date : null,
  });

export const startRide = async (token, id) =>
  await axios.put(
    `/api/rideStart/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const endRide = async (token, id) =>
  await axios.put(
    `/api/rideEnd/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const subRideEndRequest = async (token, ride, customer, date) =>
  await axios.post(
    "/api/subride/end",
    { ride, customer, date },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const allUserRides = async (token) =>
  await axios.get(`/api/all/user/rides`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadRidePhotos = async (token, rideId, fileList) =>
  await axios.post(`/api/ride/upload/${rideId}`, fileList, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadRideImages = async (token, type, file, ride, customer) =>
  await axios.post(
    "/api/upload/ride/files",
    {
      files: { url: file, type: type || "start" },
      ride,
      customer,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const loadRideFiles = async (token, id) =>
  await axios.get(`/api/ride/files/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadFinishRidePhotos = async (token, rideId, toUser, fileList) =>
  await axios.post(
    `/api/ride/finish/upload/${rideId}`,
    { toUser, fileList },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const rideEndReq = async (token, rideId) =>
  await axios.get(`/api/rideEnd/${rideId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const rideEndAccept = async (token, rideId) =>
  await axios.post(
    `/api/rideEnd/accept`,
    {
      rideId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const rideEndDecline = async (token, rideId) =>
  await axios.post(
    `/api/rideEnd/decline`,
    {
      rideId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const uploadRideFiles = async (token, images, ride, type, participant) =>
  await axios.post(
    "https://leerfahrten24.de/api/ride/files",
    { images, ride, type, participant },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
