import React from 'react'
import { Container, Button, Toast, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SettingsSidebar = ({ classN }) => {
  return (
    <Col className={classN} xs={12} md={4} lg={4}>
      <div className='border-container'>
        <div className='setting-top-container'>
          <h3 className='setting-top-header'>Informationen zur Person</h3>
        </div>
        <Link to='/settings/personal' className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Persönliche Daten</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
        {/*<div className='divider'></div>
        <Link className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Profilfoto</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>*/}
      </div>
      <div className='border-container'>
        <div className='setting-top-container'>
          <h3 className='setting-top-header'>Konto</h3>
        </div>
        {/*<Link className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Überweisungen</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
        <div className='divider'></div>
        <Link className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Auszahlungsarten</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
        <div className='divider'></div>
        <Link className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Zahlungsübersicht</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
        <div className='divider'></div>*/}
        <Link to='/settings/security' className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Sicherheit</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
        <div className='divider'></div>
        <Link to='/settings/delete' className='settings-container-row'>
          <div className='settings-container-link d-flex justify-content-between align-items-center'>
            <span>Mein Konto deaktivieren</span>
            <svg
              id='Ebene_1'
              data-name='Ebene 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 7.82 14.22'>
              <defs>
                <style></style>
              </defs>
              <polygon
                className='cls-1'
                points='6.4 7.11 0 0.7 0.7 0 7.11 6.4 7.82 7.11 7.11 7.82 0.7 14.22 0 13.52 6.4 7.11'
              />
            </svg>
          </div>
        </Link>
      </div>
    </Col>
  )
}

export default SettingsSidebar
