import React from 'react'
import { Container } from 'react-bootstrap'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import Layout from '../core/Layout'

const ForgotPassword = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <Container>
          <ForgotPasswordForm />
        </Container>
      </div>
    </Layout>
  )
}

export default ForgotPassword
