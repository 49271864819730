import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allUserRides } from "../actions/ride";
import ResultCol from "../components/ResultCol";
import NoFooter from "../core/NoFooter";
import Logo from "../images/Logo";
import {
  addToWishlist,
  getOnlyWishlist,
  readProfile,
  removeFromWishlist,
} from "../actions/auth";
import { toast } from "react-toastify";
import { StarFilled, HeartOutlined, HeartFilled } from "@ant-design/icons";
import moment from "moment";

const UserRides = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();

  const [rides, setRides] = useState([]);
  const [wishlist, setWishlist] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setProfileName();
    setProfileAvatar();
    setProfileEmail();
    setProfileRatings([]);
    setRatingAverage();
  };
  const handleShow = () => setShow(true);

  const [profileName, setProfileName] = useState();
  const [profileAvatar, setProfileAvatar] = useState();
  const [profileEmail, setProfileEmail] = useState();
  const [profileRatings, setProfileRatings] = useState([]);
  const [ratingAverage, setRatingAverage] = useState();
  const [countRides, setCountRides] = useState();
  const [createdAt, setCreatedAt] = useState();

  useEffect(() => {
    if (auth) {
      getOnlyWishlist(auth.token).then((res) => {
        setWishlist(res.data.wishlist);
      });
    }
  }, []);

  const loadProfile = async (e) => {
    const user = e.currentTarget.id;
    readProfile(user).then((res) => {
      handleShow();
      setProfileName(res.data.profile.name);
      setProfileAvatar(res.data.profile.avatar);
      setProfileEmail(res.data.profile.email);
      setProfileRatings(res.data.all);
      setCountRides(res.data.count);
      setCreatedAt(res.data.profile.createdAt);
      let numbers = res.data.all;
      setRatingAverage(
        numbers.reduce((sum, { rating }) => sum + rating, 0) / numbers.length
      );
    });
  };

  const handleAddToWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      addToWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.success("Zum Merkzettel hinzugefügt");
      });
    }
  };

  const handleRemoveFromWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      removeFromWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.error("Aus Merkzettel entfernt");
      });
    }
  };

  const loadRides = async () => {
    let { data } = await allUserRides(auth.token);
    setRides(data);
  };

  useEffect(() => {
    loadRides();
  }, []);
  return (
    <NoFooter>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="profile-tab-top d-flex">
              <div className="profile-tab-top-image">
                <img src={profileAvatar} alt="PB" />
              </div>
              <div
                style={{ flexDirection: "row", marginLeft: "10px" }}
                className="profile-tab-top-details d-flex justify-content-between"
              >
                <div className="d-flex align-items-center">
                  <h2
                    className="profile-name"
                    style={{
                      fontSize: "22px",
                      color: "#363636",
                      marginBottom: "0",
                    }}
                  >
                    {profileName}
                  </h2>
                  <div className="d-flex align-items-start">
                    <b
                      style={{
                        marginRight: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      {typeof ratingAverage === "number" &&
                        ratingAverage.toFixed(1)}{" "}
                    </b>
                    <StarFilled
                      style={{ color: "rgb(250, 182, 20)", fontSize: 16 }}
                    />
                    <div
                      style={{
                        marginLeft: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      ({profileRatings && profileRatings.length})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border-container mb-3" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Allgemeines</h3>
              </div>
            </div>
            <div className="dashboard-container-body">
              <div className="dashboard-body-row d-flex align-items-center justify-space-between">
                <span className="profile-info-general-text">
                  Abgeschlossene Fahrten:
                </span>
                <span className="profile-info-general-text-right">
                  {countRides}
                </span>
              </div>
              <div className="dashboard-body-row d-flex align-items-center justify-space-between">
                <span className="profile-info-general-text">
                  Registriert seit:
                </span>
                <span className="profile-info-general-text-right">
                  {createdAt && createdAt.split("T")[0]}
                </span>
              </div>
            </div>
          </div>
          <div className="border-container" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Bewertungen</h3>
              </div>
            </div>
            <div className="dashboard-container-body dashboard-rating-body">
              {profileRatings.length === 0 && (
                <div className="dashboard-container-empty-body">
                  Der User hat noch keine Bewertungen erhalten.
                </div>
              )}
              {profileRatings.map((rating) => (
                <div key={rating._id} className="dashboard-body-row">
                  <div className="d-flex">
                    <div className="rating-img-container">
                      <img src="/images/profile.jpg" alt="" />
                    </div>
                    <div className="rating-text-container">
                      <div className="rating-text-top d-flex align-items-center">
                        <p>{rating.user.name}</p>
                        <div className="d-flex align-items-center">
                          <div>
                            <b
                              style={{
                                marginRight: "3px",
                                color: "#777",
                                fontSize: 14,
                              }}
                            >
                              ({rating.rating})
                            </b>
                          </div>
                          <StarFilled
                            style={{
                              color: "rgb(250, 182, 20)",
                              fontSize: 14,
                            }}
                          />
                        </div>
                      </div>
                      <div className="rating-text-message">
                        <p>{rating.ratingText}</p>
                      </div>
                    </div>
                    <div className="rating-date-container">
                      <p>{moment(rating.createdAt).fromNow}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="leerfahrten-container pb-5">
        <Container>
          <div className="top-back-bar">
            <div onClick={() => navigate(-1)}>zurück</div>
          </div>
          <div className="leerfahrten-user-rides-container pb-5">
            <h2>Mitfahrten</h2>
            <div className="user-rides-results-container">
              {rides &&
                rides.participant &&
                rides.participant.map((ride) => (
                  <ResultCol
                    ride={ride}
                    rideId={ride._id}
                    loadProfile={loadProfile}
                    wishlist={wishlist}
                    handleRemoveFromWishlist={handleRemoveFromWishlist}
                    handleAddToWishlist={handleAddToWishlist}
                  />
                ))}
            </div>
            <h2>Eigene Fahrten</h2>
            <div className="user-rides-results-container">
              {rides &&
                rides.self &&
                rides.self.map((ride) => (
                  <ResultCol
                    ride={ride}
                    rideId={ride._id}
                    loadProfile={loadProfile}
                    wishlist={wishlist}
                    handleRemoveFromWishlist={handleRemoveFromWishlist}
                    handleAddToWishlist={handleAddToWishlist}
                  />
                ))}
            </div>
          </div>
        </Container>
        <div className="ride-details-footer">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="ride-details-footer-logo navbar-leerfahrten-brand">
              <Logo />
            </div>
            <div className="ride-details-footer-links d-flex">
              <a href="/impress">Impressum</a>
              <a href="/privacy">Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  );
};

export default UserRides;
