import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllChats, getChatById, getMessages } from "../actions/msg";
import io from "socket.io-client";
import moment from "moment";
import NoFooter from "../core/NoFooter";
import { createOffer } from "../actions/offer";
import { readProfile } from "../actions/auth";
import { StarFilled } from "@ant-design/icons";
import ProfileModal from "../components/ProfileModal";
import "moment/locale/de";
import Logo from "../images/Logo";
import axios from "axios";

const socket = io();

const Message = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ride, setRide] = useState();
  const [sendTo, setSendTo] = useState();
  const [conversations, setConversations] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [body, setBody] = useState();
  const [to, setTo] = useState();
  const [price, setPrice] = useState();
  const [show, setShow] = useState(false);
  const [profileName, setProfileName] = useState();
  const [profileRatings, setProfileRatings] = useState([]);
  const [ratingAverage, setRatingAverage] = useState();
  const [avatar, setAvatar] = useState(null);
  const [messageFromUser, setMessageFromUser] = useState();

  const dispatch = useDispatch();
  const getChats = useSelector((state) => state.getChats);
  const { chats } = getChats;

  const chatById = useSelector((state) => state.chatById);
  const { chat } = chatById;

  const { auth } = useSelector((state) => ({ ...state }));
  const { user } = auth;

  const messagesFromConversation = useSelector(
    (state) => state.messagesFromConversation
  );
  const { messages } = messagesFromConversation;

  let messagesEnd = useRef(null);

  const handleClose = () => {
    setShow(false);
    setProfileName();
    setProfileRatings([]);
    setRatingAverage();
  };
  const handleShow = () => setShow(true);

  const loadProfile = async (e) => {
    const user = e.currentTarget.id;
    readProfile(user).then((res) => {
      handleShow();
      setProfileName(res.data.profile.name);
      setProfileRatings(res.data.all);
      setAvatar(res.data.profile.avatar);
      let numbers = res.data.all;
      setRatingAverage(
        numbers.reduce((sum, { rating }) => sum + rating, 0) / numbers.length
      );
    });
  };

  const priceObject = {
    price,
    ride,
    sendTo,
  };

  const showPriceModal = () => {
    const priceModal = document.getElementById("price-modal");
    const priceModalForm = document.getElementById("price-modal-form");
    priceModal.style.transform = "translateY(35%)";
    priceModal.style.opacity = "1";
    priceModal.style.height = "100%";
    priceModalForm.style.display = "block";
  };

  const closePriceModal = () => {
    const priceModal = document.getElementById("price-modal");
    const priceModalForm = document.getElementById("price-modal-form");
    priceModalForm.style.display = "none";
    priceModal.style.transform = "translateY(100%)";
    priceModal.style.opacity = "0";
    priceModal.style.height = "0";
  };

  const scrollToBottom = () => {
    messagesEnd.current.scrollIntoView();
  };

  const priceSubmit = async (e) => {
    e.preventDefault();
    createOffer(auth.token, priceObject).then(async (res) => {
      if (chat) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        };

        const { data } = await axios.post(
          `/api/send`,
          {
            to,
            body: "Neues Angebot!",
            id,
            date: moment().unix(),
            type: {
              type: "offer",
              offer: res.data._id,
            },
          },
          config
        );
        setAllMessages([...allMessages, data.message]);
        socket.emit("new message", data);
        setBody("");
      }
      closePriceModal();
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (body) {
      if (chat) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        };

        const { data } = await axios.post(
          `/api/send`,
          {
            to,
            body,
            id,
            date: moment().unix(),
            type: {
              type: "text",
              offer: null,
            },
          },
          config
        );
        setAllMessages([...allMessages, data.message]);
        socket.emit("new message", data);
        setBody("");
      }
    }
  };

  useEffect(() => {
    socket.emit("setup", user);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!chats) {
      dispatch(getAllChats());
      scrollToBottom();
    }
    dispatch(getChatById(id));
    dispatch(getMessages(id));
    scrollToBottom();
    socket.emit("join chat", id);
  }, [chats, dispatch, id]);

  useEffect(() => {
    if (chat) {
      setTo(chat.receiver._id);
      setRide(chat.ride._id);
      setSendTo(chat.receiver._id);
    }
  }, [chat]);

  useEffect(() => {
    setConversations(chats);
  }, [chats, id]);

  useEffect(() => {
    setAllMessages(messages);
    scrollToBottom();
  }, [messages, id]);

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieved) => {
      setMessageFromUser(newMessageRecieved.message);
    });

    return () => {
      socket.off("message recieved");
    };
  }, []);

  useEffect(() => {
    if (messageFromUser) {
      setAllMessages([...allMessages, messageFromUser]);
      setMessageFromUser();
    }
  }, [messageFromUser]);

  return (
    <NoFooter>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="profile-tab-top d-flex">
              <div className="profile-tab-top-image">
                <img src={avatar} alt="PB" />
              </div>
              <div
                style={{ flexDirection: "row", marginLeft: "10px" }}
                className="profile-tab-top-details d-flex justify-content-between"
              >
                <div className="d-flex align-items-center">
                  <h2
                    className="profile-name"
                    style={{
                      fontSize: "22px",
                      color: "#363636",
                      marginBottom: "0",
                    }}
                  >
                    {profileName}
                  </h2>
                  <div className="d-flex align-items-start">
                    <b
                      style={{
                        marginRight: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      {typeof ratingAverage === "number" &&
                        ratingAverage.toFixed(2)}{" "}
                    </b>
                    <StarFilled
                      style={{ color: "rgb(250, 182, 20)", fontSize: 16 }}
                    />
                    <div
                      style={{
                        marginLeft: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      ({profileRatings && profileRatings.length})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border-container mb-3" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Allgemeines</h3>
              </div>
            </div>
          </div>
          <div className="border-container" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Bewertungen</h3>
              </div>
            </div>
            <div className="dashboard-container-body">
              {profileRatings.length === 0 && (
                <div className="dashboard-container-empty-body">
                  Der User hat noch keine Bewertungen erhalten.
                </div>
              )}
              {profileRatings.map((rating) => (
                <div key={rating._id} className="dashboard-body-row">
                  <div className="d-flex">
                    <div className="rating-img-container">
                      <img src={rating.user.avatar} alt="" />
                    </div>
                    <div className="rating-text-container">
                      <div className="rating-text-top d-flex align-items-center">
                        <p>{rating.user.name}</p>
                        <div className="d-flex align-items-center">
                          <div>
                            <b
                              style={{
                                marginRight: "3px",
                                color: "#777",
                                fontSize: 14,
                              }}
                            >
                              ({rating.rating})
                            </b>
                          </div>
                          <StarFilled
                            style={{
                              color: "rgb(250, 182, 20)",
                              fontSize: 14,
                            }}
                          />
                        </div>
                      </div>
                      <div className="rating-text-message">
                        <p>{rating.ratingText}</p>
                      </div>
                    </div>
                    <div className="rating-date-container">
                      <p>{moment(rating.createdAt).fromNow}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="leerfahrten-container chat-phone"
        style={{ paddingBottom: "150px" }}
      >
        <Container>
          <div className="top-back-bar">
            <div onClick={() => navigate(-1)}>zurück</div>
          </div>
          <div className="chat-wrapper">
            <Row>
              <Col lg={4} className="phone-hide">
                <div className="chat-side-container border-container">
                  <div className="chat-side-top-bar d-flex align-items-center">
                    <div>Anzahl Chats: {chats?.length}</div>
                    <p>Chats</p>
                  </div>
                  <div className="chat-inner-wrapper">
                    {conversations &&
                      user &&
                      conversations.map((c) => (
                        <Link
                          to={`/user/chat/${c._id}`}
                          key={`key-${c._id}`}
                          className="chat-side-inner-container d-flex"
                        >
                          <div className="chat-side-pic">
                            {auth &&
                              c.recipients.map((r) => {
                                if (r._id !== user?._id) {
                                  return (
                                    <img
                                      key={r?._id.toString()}
                                      src={r.avatar}
                                      alt="profile"
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            <div className="chat-side-pic-ntf"></div>
                          </div>
                          <div
                            className="chat-side-text"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color: "#363636",
                            }}
                          >
                            {auth &&
                              c.recipients.map((r) => (
                                <span
                                  key={r?._id.toString()}
                                  className="chat-side-text-name"
                                >
                                  {r._id !== user?._id ? r.name : null}
                                </span>
                              ))}
                            <span
                              className="chat-side-text-text"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {c.lastMessage}
                            </span>
                          </div>
                          <div className="chat-side-ntf">
                            <span className="chat-side-ntf-days">
                              {moment.unix(c.date).fromNow()}
                            </span>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="chat-main-container border-container">
                  <div className="chat-main-top-bar d-flex align-items-center">
                    <div
                      className="chat-main-id phone-hide"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      ID:{" "}
                      <b style={{ color: "#ff441f" }}>
                        {chat && chat.ride._id}
                      </b>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      className="desktop-hide"
                      onClick={() => navigate(-1)}
                    >
                      zurück
                    </div>
                    <div className="d-flex align-items-center">
                      <Link
                        to={`/ride/${chat && chat.ride._id}`}
                        className="chat-order-details ml-auto"
                      >
                        Anfrage Details
                      </Link>
                      {/*<div className="chat-settings d-flex align-items-center">
                        <div></div>
                        <div></div>
                        <div></div>
                            </div>*/}
                    </div>
                  </div>

                  <div className="chat-main-profile-info">
                    <div className="d-flex align-items-center">
                      <div className="chat-side-pic">
                        <img src={chat?.receiver.avatar} alt="profile" />
                      </div>
                      <div className="chat-side-text">
                        {chat ? (
                          <span className="chat-side-text-name">
                            {chat?.receiver.name}
                          </span>
                        ) : null}
                        <div className="chat-main-info-rating"></div>
                      </div>
                      {chat && (
                        <ProfileModal
                          user={chat.receiver._id}
                          extra="ml-auto"
                        />
                      )}
                    </div>
                  </div>
                  <div className="chat-main-inner-container">
                    <div className="chat-show-message-container">
                      <div className="mt-auto">
                        {allMessages &&
                          user &&
                          allMessages.map((m) =>
                            m.from === user._id && m.type?.type === "text" ? (
                              <div
                                key={m._id}
                                className="chat-message d-flex align-items-center"
                              >
                                <div className="chat-side-pic">
                                  <img src={m.from.avatar} alt="profile" />
                                </div>
                                <div className="chat-message-text">
                                  <span
                                    onClick={loadProfile}
                                    id={m.from._id}
                                    className="chat-message-name"
                                  >
                                    {m.from.name}
                                  </span>
                                  <span className="chat-message-time">
                                    {m.date}
                                  </span>
                                  <span className="chat-message-text">
                                    {m.body}
                                  </span>
                                </div>
                              </div>
                            ) : m.type?.type === "offer" ? (
                              <Link
                                to={`/offer/${m.type.offer}`}
                                className="offer-container d-flex align-items-center justify-content-between"
                                key={m._id}
                              >
                                <p>Sie haben ein Angebot erhalten</p>
                                <div>Details</div>
                              </Link>
                            ) : (
                              <div
                                key={m._id}
                                className="chat-message d-flex align-items-center"
                              >
                                <div className="chat-side-pic">
                                  <img src={m.from.avatar} alt="profile" />
                                </div>
                                <div className="chat-message-text">
                                  <span
                                    onClick={loadProfile}
                                    id={m.from._id}
                                    style={{ cursor: "pointer" }}
                                    className="chat-message-name"
                                  >
                                    {m.from.name}
                                  </span>
                                  <span className="chat-message-time">
                                    {moment.unix(m.date).fromNow()}
                                  </span>
                                  <span className="chat-message-text bg-blue">
                                    {m.body}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                      <div ref={messagesEnd}></div>
                    </div>
                    <div className="chat-main-message-container">
                      <Form onSubmit={handleSubmit}>
                        <div className="chat-main-message-input">
                          <Form.Group controlId="message">
                            <Form.Control
                              as="textarea"
                              rows={1}
                              placeholder="Ihre Nachricht"
                              style={{ resize: "none" }}
                              value={body}
                              onChange={(e) => setBody(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                        <div className="chat-main-message-bottom d-flex align-items-center justify-content-between">
                          {/*chat &&
                            chat.ride.participants.includes(auth.user._id) && (
                              <div className="chat-message-bottom-actions">
                                <img src="/images/feedback-icon.svg" alt="" />
                              </div>
                            )*/}
                          <div style={{ marginLeft: "auto" }}>
                            {user && chat && user._id === chat.ride.user && (
                              <Button
                                onClick={showPriceModal}
                                className="chat-message-bottom-send"
                              >
                                Angebot machen
                              </Button>
                            )}

                            <Button
                              className="chat-message-bottom-send"
                              type="submit"
                            >
                              Senden
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div id="price-modal" className="price-modal d-flex">
                      <Form id="price-modal-form" onSubmit={priceSubmit}>
                        <div className="price-modal-top d-flex align-items-center justify-content-between">
                          <div></div>
                          <Button onClick={closePriceModal} variant="secondary">
                            x
                          </Button>
                        </div>
                        <div className="price-modal-body">
                          <div>
                            <h2>Welchen Preis möchten Sie verlangen?</h2>
                          </div>
                          <div>
                            <Form.Group controlId="message">
                              <Form.Control
                                as="input"
                                rows={1}
                                placeholder="Preis"
                                style={{ resize: "none" }}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="price-modal-bottom">
                          <Button type="submit">Angebot senden</Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="ride-details-footer">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="ride-details-footer-logo navbar-leerfahrten-brand">
              <Logo />
            </div>
            <div className="ride-details-footer-links d-flex">
              <a href="/impress">Impressum</a>
              <a href="/privacy">Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  );
};

export default Message;
