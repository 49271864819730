import {
  GET_CHAT_BY_ID_FAIL,
  GET_CHAT_BY_ID_REQUEST,
  GET_CHAT_BY_ID_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  USER_GET_ALL_CHATS_FAIL,
  USER_GET_ALL_CHATS_REQUEST,
  USER_GET_ALL_CHATS_SUCCESS,
} from '../constants/msgConstants'

export const userGetChatsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_ALL_CHATS_REQUEST:
      return { loading: true }
    case USER_GET_ALL_CHATS_SUCCESS:
      return { loading: false, chats: action.payload }
    case USER_GET_ALL_CHATS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getChatByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHAT_BY_ID_REQUEST:
      return { loading: true }
    case GET_CHAT_BY_ID_SUCCESS:
      return { loading: false, chat: action.payload }
    case GET_CHAT_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getMessagesReducer = (state = { messages: [] }, action) => {
  switch (action.type) {
    case GET_MESSAGES_REQUEST:
      return { loading: true }
    case GET_MESSAGES_SUCCESS:
      return { loading: false, messages: action.payload }
    case GET_MESSAGES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const sendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return { loading: true }
    case SEND_MESSAGE_SUCCESS:
      return { loading: false, lastMessage: action.payload }
    case SEND_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
