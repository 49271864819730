import React from 'react'
import { Container, Button } from 'react-bootstrap'
import NoFooter from '../core/NoFooter'
import { Formik, Field, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

const Create = () => {
  const navigate = useNavigate()
  return (
    <div className='create'>
      <NoFooter>
        <Formik
          initialValues={{
            step1: '',
          }}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500))
            // Validation
            // Save in Form Store
            // Save in Storage
            localStorage.setItem('step1', values.step1)
            // Push to next step
            if (values.step1 === 'Umzug') {
              navigate('/create/umzug')
            }
            if (values.step1 === 'Transport / Mitfahrgelegenheit') {
              navigate('/create/transport')
            }
          }}>
          {(values) => (
            <Form>
              <div className='leerfahrten-container'>
                <Container>
                  <div className='create-container d-flex'>
                    <div className='leerfahrten-create-top-container'></div>
                    <div className='leerfahrten-create-text-container'>
                      <h1>Was möchten Sie anbieten?</h1>
                      <p>Kleiner überschrift unten drunter</p>
                    </div>
                    <div className='leerfahrten-create-image-container'></div>
                    <div className='leerfahrten-create-input-container'>
                      <div>
                        <div className='checkbox-container input-custom-container'>
                          <Field
                            id='check-1'
                            className='radio-custom'
                            type='radio'
                            name='step1'
                            value='Transport / Mitfahrgelegenheit'
                          />
                          <label for='check-1' className='radio-custom-label'>
                            Transport / Mitfahrgelegenheit
                          </label>
                        </div>
                        <div className='checkbox-container input-custom-container'>
                          <Field
                            id='check-2'
                            className='radio-custom'
                            type='radio'
                            name='step1'
                            value='Umzug'
                          />
                          <label for='check-2' className='radio-custom-label'>
                            Umzug
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
                <div className='leerfahrten-create-btn-cnt'>
                  <Container>
                    <div className='d-flex justify-content-between'>
                      <Button variant='secondary'>zurück</Button>
                      <Button type='submit'>Weiter</Button>
                    </div>
                  </Container>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </NoFooter>
    </div>
  )
}

export default Create
