import React from 'react'
import { Container } from 'react-bootstrap'
import ResetPasswordForm from '../components/ResetPasswordForm'
import Layout from '../core/Layout'

const ResetPassword = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <Container>
          <ResetPasswordForm />
        </Container>
      </div>
    </Layout>
  )
}

export default ResetPassword
