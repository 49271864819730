import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import NoFooter from '../core/NoFooter'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { rideEndAccept, rideEndDecline, rideEndReq } from '../actions/ride'
import { toast } from 'react-toastify'
import moment from 'moment'
import { sendMessage } from '../actions/msg'
import Logo from '../images/Logo'

const EndRide = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { auth } = useSelector((state) => ({ ...state }))

  const [rideFiles, setRideFiles] = useState([])
  const [start, setStart] = useState()
  const [destination, setDestination] = useState()
  const [date, setDate] = useState()
  const [to, setTo] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    rideEndReq(auth.token, id)
      .then((res) => {
        setStart(res.data.rideDetails.start.name)
        setDestination(res.data.rideDetails.destination.name)
        setRideFiles(res.data.rideFiles)
      })
      .catch(() => {
        navigate(`/ride/${id}`)
      })
  }, [])

  const handleAccept = async () => {
    rideEndAccept(auth.token, id)
      .then(() => {
        navigate(`/rating/${id}`)
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const handleDecline = async () => {
    rideEndDecline(auth.token, id)
      .then(() => {
        toast.success(
          'Sie haben die Fertigstellung abgelehnt. Bitte setzen Sie sich mit dem Fahrer in Verbindung oder wenden Sie sich an den Support.'
        )
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  return (
    <NoFooter>
      <div className='leerfahrten-container'>
        <Container>
          <div className='forgot-password-cnt'>
            <h1>Ihre Fahrt wurde abgeschlossen</h1>
            <div className='divider'></div>
            <div className='end-ride-inner'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2>Route:</h2>
                <Link to={`/ride/${id}`}>zur Fahrt</Link>
              </div>
              <div className='d-flex'>
                <div className='d-flex flex-column align-items-center'>
                  <div className='end-ride-route-dot'></div>
                  <div className='end-ride-route-line'></div>
                  <div className='end-ride-route-dot'></div>
                </div>
                <div className='d-flex flex-column'>
                  <div className='end-ride-route-text'>{start}</div>
                  <div className='end-ride-route-empty'></div>
                  <div className='end-ride-route-text'>{destination}</div>
                </div>
              </div>
              <h2 style={{ marginTop: '20px' }}>Bilder:</h2>
              <div>
                <div className='pictures-row'>
                  <div className='d-flex pictures-row-flex'>
                    {rideFiles &&
                      auth &&
                      auth.user &&
                      rideFiles &&
                      rideFiles.map((r) => (
                        <>
                          {r.files.map((e) => (
                            <img
                              className='participants-row-img'
                              src='/images/profile.jpg'
                              alt=''
                            />
                          ))}
                        </>
                      ))}
                    {rideFiles.length === 0 && (
                      <div>Keine Bilder hochgeladen</div>
                    )}
                  </div>
                </div>
              </div>
              <Button onClick={handleAccept} className='mt-2 mb-2'>
                Akzeptieren & Beenden
              </Button>
              <Button onClick={handleDecline} variant='secondary'>
                Ablehnen
              </Button>
            </div>
          </div>
        </Container>
        <div className='ride-details-footer'>
          <Container className='d-flex align-items-center justify-content-between'>
            <div className='ride-details-footer-logo navbar-leerfahrten-brand'>
              <Logo />
            </div>
            <div className='ride-details-footer-links d-flex'>
              <a href='/impress'>Impressum</a>
              <a href='/privacy'>Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  )
}

export default EndRide
