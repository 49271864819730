import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CreateAutocomplete from "../components/CreateAutocomplete";
import NoFooter from "../core/NoFooter";

export function CreateDestination() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [destination, setDestination] = useState({
    address: "",
    geometry: { lat: null, lng: null },
  });

  const validateStep = async (step, url) => {
    const before = await getValueFromStorage(step);
    if (!before) {
      return navigate(url);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateStep("create-start", "/create/start");
    const storage = getValueFromStorage("create-destination");
    storage && setDestination(JSON.parse(storage));
  }, []);

  const next = () => {
    if (destination) {
      navigate("/create/date");
    }
  };

  if (loading) {
    return (
      <div className="create">
        <NoFooter>
          <div className="leerfahrten-container">
            <Container>
              <div className="create-container d-flex"></div>
            </Container>
          </div>
        </NoFooter>
      </div>
    );
  }

  return (
    <div className="create">
      <NoFooter>
        <div className="leerfahrten-container">
          <Container>
            <div className="create-container d-flex">
              <div className="leerfahrten-create-text-container">
                <h1>Wo willst du hin?</h1>
                <p>Gib die Stadt ein in die Du fährst</p>
              </div>
              <div className="leerfahrten-create-image-container"></div>
              <div className="leerfahrten-create-input-container">
                <CreateAutocomplete
                  type="create-destination"
                  setState={setDestination}
                  state={destination}
                  initialValue={destination.address}
                />
              </div>
            </div>
          </Container>
          <div className="leerfahrten-create-btn-cnt">
            <Container>
              <div className="d-flex justify-content-between">
                <Button onClick={() => navigate(-1)} variant="secondary">
                  zurück
                </Button>
                {destination?.geometry?.lat && (
                  <Button onClick={next} type="submit">
                    Weiter
                  </Button>
                )}
              </div>
            </Container>
          </div>
        </div>
      </NoFooter>
    </div>
  );
}

function getValueFromStorage(value) {
  return localStorage.getItem(value);
}
