import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Layout from '../core/Layout'
import { telefon, serviceemail, itemail } from '../components/KontaktInfo'

const Contact = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <Container className='pt-4'>
          <h1 className='settings-topbar-header'>Kundenservice – Kontakt</h1>
          <div className='border-container py-2 px-3'>
            <p className='contact-subhead'>Unsere Servicezeiten:</p>
            <div className='contact-info'>
              <div className='contact-flex'>
                <div className='contact-flex-inner'>
                  <div>
                    <img src='/images/contact.png' alt='phone' />
                  </div>
                  <div>Telefon:</div>
                </div>
                <div>
                  <a className='bold-text' href={`tel:${telefon}`}>
                    {telefon}
                  </a>
                </div>
                <div>
                  <p>Montag bis Freitag von 8:00 bis 18:00 Uhr</p>
                </div>
              </div>
              <div className='contact-flex'>
                <div className='contact-flex-inner'>
                  <div>
                    <img src='/images/contact.png' alt='phone' />
                  </div>
                  <div>E-Mail-Adresse:</div>
                </div>
                <div>
                  <a className='bold-text' href={`mailto:${serviceemail}`}>
                    {serviceemail}
                  </a>
                </div>
                <div>
                  <p>Montag bis Freitag von 8:00 bis 18:00 Uhr</p>
                </div>
              </div>
              <div className='contact-info-text'>
                <p>
                  <Link to='/faq'>
                    Hier findest Du Antworten auf häufig gestellte Fragen.
                  </Link>{' '}
                  Wenn Du keine Antwort auf Deine Frage gefunden hast, kannst Du
                  uns per E-Mail direkt kontaktieren.
                </p>
                <h3>Weitere wichtige Informationen</h3>
                <p>
                  Wenn Du eine sicherheitsrelvante Schwachstelle auf unseren
                  Seiten gefunden hast und möchtest diese, unter den Richtlinien
                  einer verantwortungsvollen Offenlegung, unserem Team melden,
                  nutze bitte folgende E-Mail-Adresse:{' '}
                  <a href={`mailto:${itemail}`}>{itemail}</a>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Contact
