import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/HeaderTest'
import ScrollToTop from '../components/ScrollToTop'

const NoFooter = ({ children }) => {
  const navigate = useNavigate()
  return (
    <>
      <Header navigate={navigate} />
      <ScrollToTop />
      {children}
    </>
  )
}

export default NoFooter
