import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import NoFooter from "../core/NoFooter";
import { read, loadRideFiles, uploadFinishRidePhotos } from "../actions/ride";
import { createCon } from "../actions/msg";
import { toast } from "react-toastify";
import { Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../images/Logo";
import axios from "axios";

const RideDetailsParticipants = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));

  const [start, setStart] = useState();
  const [destination, setDestination] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [description, setDescription] = useState();
  const [date, setDate] = useState();
  const [profileName, setProfileName] = useState();
  const [userId, setUserId] = useState();
  const [rideId, setRideId] = useState(id);
  const [isUser, setIsUser] = useState(null);
  const [rideStatus, setRideStatus] = useState();
  const [toUser, setToUser] = useState();
  const [participants, setParticipants] = useState([]);
  const [rideFiles, setRideFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);

  const applyUser = async (e) => {
    setToUser(e.target.id);
    setModalShow(true);
  };

  const conversationExists = async (ride, receiver) => {
    await axios
      .post(
        "/api/conversation/exists",
        { ride, receiver },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        navigate(`/user/chat/${res.data._id}`);
      })
      .catch((err) => console.log(err.response?.data));
  };

  const goToConversation = async (e) => {
    if (auth) {
      conversationExists(rideId, e.target.id);
    }
  };

  useEffect(() => {
    loadRideFiles(auth.token, id)
      .then((res) => {
        setRideFiles(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, []);

  useEffect(() => {
    loadRideDetails();
  }, []);

  const loadRideDetails = async () => {
    let res = await read(auth.token, id);
    setStart(res.data.start.name);
    setDestination(res.data.destination.name);
    setProfileName(res.data.user.name);
    setUserId(res.data.user._id);
    setRideId(res.data._id);
    setRideStatus(res.data.status);
    if (res.data.participants) {
      setParticipants(res.data.participants);
    }
    if (auth) {
      if (auth.user._id !== res.data.user._id) {
        setIsUser(false);
      }
      if (auth.user._id === res.data.user._id) {
        setIsUser(true);
      }
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleSubmit = () => {
    if (fileList.length > 0) {
      uploadFinishRidePhotos(auth.token, rideId, toUser, fileList)
        .then(() => {
          setToUser();
          handleModalClose();
          toast.success(
            "Fotos erfolgreich hochgeladen. Sobald Sie akzeptiert wurden, bekommen Sie eine Nachricht."
          );
        })
        .catch(() => {
          toast.error("Error");
        });
    } else {
      toast.error("Bitte lade Bilder von der Fracht hoch");
    }
  };
  return (
    <NoFooter>
      <Modal centered show={modalShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="feedback-header">Fahrt beenden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ textAlign: "center" }}>
            Lade Bilder von der Fracht hoch
          </h5>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 5 && "+ Bilder hochladen"}
          </Upload>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            type="submit"
            style={{ margin: "0 10px", width: "100%" }}
          >
            Fahrt beenden
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="leerfahrten-container">
        <div className="ride-details-mobile-top">
          <Container>
            <div className="d-flex align-items-center justify-content-between">
              <span className="top-id">
                ID: <span className="top-id-bold">{id}</span>
              </span>
            </div>
          </Container>
        </div>
        {isUser === true && (
          <div className="border-container details-isuser-container ride-details-container">
            <div className="ride-details-top-container flex-column d-flex">
              <div className="d-flex align-items-center justify-content-between">
                <span className="top-id">
                  ID: <span className="top-id-bold">{id}</span>
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="ride-details-top-container-heading">
                  Alle Teilnehmer
                </span>
              </div>
            </div>
            <div className="divider"></div>
            <div className="ride-details-body d-flex flex-column">
              <div
                className="pb-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <div>zurück</div>
              </div>
              <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                <span className="ride-details-top-container-heading">
                  Alle Teilnehmer
                </span>
                <span className="ride-details-top-container-date">
                  18.03.2021
                </span>
              </div>
              <div className="ride-details-body-waypoints">
                {participants &&
                  auth &&
                  auth.user &&
                  userId &&
                  participants.map((p) => (
                    <div className="border-container">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="profile-tab-top d-flex">
                            <div className="profile-tab-top-image">
                              <img src="/images/profile.jpg" alt="PB" />
                            </div>
                            <div className="profile-tab-top-details d-flex justify-content-between">
                              <h2 className="profile-name">{p.user.name}</h2>
                              <div>
                                <a href="">Profil anzeigen</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center participants-btn-row">
                          <Button
                            variant="secondary"
                            id={p.user._id}
                            onClick={goToConversation}
                          >
                            zum Chat
                          </Button>
                          <Button
                            className="green-btn"
                            id={p.user._id}
                            onClick={applyUser}
                          >
                            Beenden
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ride-details-footer">
        <Container className="d-flex align-items-center justify-content-between">
          <div className="ride-details-footer-logo navbar-leerfahrten-brand">
            <Logo />
          </div>
          <div className="ride-details-footer-links d-flex">
            <a href="/impress">Impressum</a>
            <a href="/privacy">Datenschutz</a>
          </div>
        </Container>
      </div>
    </NoFooter>
  );
};

export default RideDetailsParticipants;
