import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../core/Layout'

const Faq = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <Container className='pt-4'>
          <h1 className='settings-topbar-header'>
            FAQ - Häufig gestellte Fragen
          </h1>
        </Container>
      </div>
    </Layout>
  )
}

export default Faq
