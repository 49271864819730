import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { read } from "../actions/offer";
import NoFooter from "../core/NoFooter";
import moment from "moment";
import "moment/locale/de";

export default function PaymentOverview() {
  const { offerId } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));
  const [price, setPrice] = useState();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [start, setStart] = useState();
  const [destination, setDestination] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [description, setDescription] = useState();
  const [rideDate, setRideDate] = useState();
  const [billAddress, setBillAddress] = useState({
    firstName: "",
    lastName: "",
    address: "",
    additionalAddress: "",
    postalCode: "",
    city: "",
  });

  const createPayment = async () => {
    if (offerId) {
      await axios
        .post(
          `/api/payment/create`,
          {
            method: "paypal",
            offerId,
            platform: "web",
            billAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then((res) => {
          window?.open(res.data._links.checkout.href);
        })
        .catch((err) => console.log("err"));
    }
  };

  useEffect(() => {
    const loadOfferDetails = async () => {
      let res = await read(auth.token, offerId);
      setPrice(res.data.offer?.price);
      setStart(res.data.ride?.start.address);
      setWaypoints(res.data.ride?.waypoints);
      setDestination(res.data.ride?.destination.address);
      setDescription(res.data.ride?.description);
      setRideDate(res.data.ride?.date);
    };

    const paymentMethodStorage = localStorage.getItem("paymentMethod");
    const storage = localStorage.getItem("billAddress");

    setPaymentMethod(JSON.parse(paymentMethodStorage));
    setBillAddress(JSON.parse(storage));

    if (auth.token) {
      loadOfferDetails();
    }
  }, [auth, offerId]);
  return (
    <NoFooter>
      <div className="payment-container">
        <div className="payment-main">
          <div className="border-container details-isuser-container ride-details-container">
            <div className="ride-details-top-container flex-column d-flex">
              <span className="top-id">
                ID: <span className="top-id-bold">{offerId}</span>
              </span>
              <div className="d-flex align-items-center justify-content-between">
                <span className="ride-details-top-container-heading">
                  Bestätigen
                </span>
                <span className="ride-details-top-container-date">
                  {moment.unix(rideDate).format("DD.MM.YYYY HH:mm")}
                </span>
              </div>
            </div>
            <div className="divider"></div>
            <div className="ride-details-body d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                <span className="ride-details-top-container-heading">
                  Detailansicht
                </span>
                <span className="ride-details-top-container-date">
                  {moment.unix(rideDate).format("DD.MM.YYYY HH:mm")}
                </span>
              </div>
              <div className="ride-details-body-route">
                <h2 className="ride-details-body-heading">Route:</h2>
                <div className="route-row d-flex align-items-center">
                  <div className="route-divider d-flex align-items-center">
                    <div className="divider-dot"></div>
                  </div>
                  <div className="route-address">
                    <p>{start}</p>
                  </div>
                </div>
                <div className="divider-line"></div>
                {waypoints?.length > 0 &&
                  waypoints.map((waypoint) => (
                    <>
                      <div className="route-row d-flex align-items-center">
                        <div className="route-divider d-flex align-items-center">
                          <div className="divider-waypoint-dot"></div>
                        </div>
                        <div className="route-address">
                          <p>{waypoint?.address}</p>
                        </div>
                      </div>
                      <div className="divider-line"></div>
                    </>
                  ))}
                <div className="route-row d-flex align-items-center">
                  <div className="route-divider d-flex align-items-center">
                    <div className="divider-dot"></div>
                  </div>
                  <div className="route-address">
                    <p>{destination}</p>
                  </div>
                </div>
              </div>
              <div className="ride-details-body-description">
                <h2 className="ride-details-body-heading">Beschreibung:</h2>
                <span>
                  {description ?? "Es wurde keine Beschreibung hinterlegt."}
                </span>
              </div>
              <div className="ride-details-body-description">
                <h2 className="ride-details-body-heading">Rechnungsadresse:</h2>
                <p>
                  {billAddress?.firstName} {billAddress?.lastName}
                </p>
                <p>{billAddress?.address}</p>
                {billAddress?.additionalAddress ? (
                  <p>{billAddress?.additionalAddress}</p>
                ) : null}
                <p>
                  {billAddress?.postalCode} {billAddress?.city}
                </p>
              </div>
              <div className="ride-details-body-description">
                <h2 className="ride-details-body-heading">Zahlungsmethode:</h2>
                <span>
                  {paymentMethod
                    ? paymentMethod[0].description
                    : "Keine Zahlungsmethode gewählt"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-footer">
          <div className="payment-price-container">
            <p>Preis:</p>
            <span>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(price)}
            </span>
          </div>
          <div className="payment-button">
            <Button type="submit" onClick={createPayment}>
              Bezahlen
            </Button>
          </div>
        </div>
      </div>
    </NoFooter>
  );
}
