import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import NoFooter from "../core/NoFooter";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { read, loadRideFiles } from "../actions/ride";
import { createCon } from "../actions/msg";
import { addToWishlist, removeFromWishlist } from "../actions/auth";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../images/Logo";

const RideDetailsPictures = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => ({ ...state }));

  const [start, setStart] = useState();
  const [destination, setDestination] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [description, setDescription] = useState();
  const [date, setDate] = useState();
  const [profileName, setProfileName] = useState();
  const [userId, setUserId] = useState();
  const [rideId, setRideId] = useState();
  const [isUser, setIsUser] = useState(null);
  const [rideStatus, setRideStatus] = useState();
  const [participants, setParticipants] = useState([]);
  const [rideFiles, setRideFiles] = useState([]);

  const createConversation = async (e) => {
    let conData = {
      ride: rideId,
      receiver: e.target.id,
    };
    if (auth) {
      createCon(auth.token, conData).then((res) => {
        navigate(`/user/chat/${res.data._id}`);
      });
    }
  };

  useEffect(() => {
    loadRideFiles(auth.token, id)
      .then((res) => {
        setRideFiles(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }, []);

  useEffect(() => {
    loadRideDetails();
  }, []);

  const loadRideDetails = async () => {
    let res = await read(auth.token, id);
    setStart(res.data.start.name);
    setDestination(res.data.destination.name);
    setProfileName(res.data.user.name);
    setUserId(res.data.user._id);
    setRideId(res.data._id);
    setRideStatus(res.data.status);
    if (res.data.participants) {
      setParticipants(res.data.participants);
    }
    if (auth) {
      if (auth.user._id !== res.data.user._id) {
        setIsUser(false);
      }
      if (auth.user._id === res.data.user._id) {
        setIsUser(true);
      }
    }
  };

  const handleAddToWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      addToWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.success("Zum Merkzettel hinzugefügt");
      });
    }
  };

  const handleRemoveFromWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      removeFromWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.error("Aus Merkzettel entfernt");
      });
    }
  };
  return (
    <NoFooter>
      <div className="leerfahrten-container">
        <div className="ride-details-mobile-top">
          <Container>
            <div className="d-flex align-items-center justify-content-between">
              <span className="top-id">
                ID: <span className="top-id-bold">{id}</span>
              </span>
            </div>
          </Container>
        </div>
        {isUser === true && (
          <div className="border-container details-isuser-container ride-details-container">
            <div className="ride-details-top-container flex-column d-flex">
              <div className="d-flex align-items-center justify-content-between">
                <span className="top-id">
                  ID: <span className="top-id-bold">{id}</span>
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="ride-details-top-container-heading">
                  Alle Bilder
                </span>
              </div>
            </div>
            <div className="divider"></div>
            <div className="ride-details-body d-flex flex-column">
              <div
                className="pb-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <div>zurück</div>
              </div>
              <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                <span className="ride-details-top-container-heading">
                  Alle Bilder
                </span>
              </div>
              <div className="ride-details-body-waypoints">
                {rideFiles &&
                  auth &&
                  auth.user &&
                  userId &&
                  rideFiles &&
                  rideFiles.map((r) => (
                    <>
                      <div className="mb-2">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <h2 className="ride-details-body-heading">
                            Bild von {r.user.name}:
                          </h2>
                          <span
                            className="custom-link"
                            id={r.user._id}
                            onClick={createConversation}
                          >
                            zum Chat gehen
                          </span>
                        </div>
                        <div className="d-flex pictures-row-flex">
                          <img
                            className="participants-row-img"
                            src={r.url}
                            alt="uploaded"
                          />
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ride-details-footer">
        <Container className="d-flex align-items-center justify-content-between">
          <div className="ride-details-footer-logo navbar-leerfahrten-brand">
            <Logo />
          </div>
          <div className="ride-details-footer-links d-flex">
            <a href="/impress">Impressum</a>
            <a href="/privacy">Datenschutz</a>
          </div>
        </Container>
      </div>
    </NoFooter>
  );
};

export default RideDetailsPictures;
