import axios from "axios";

export const createOffer = async (token, arg) =>
  await axios.post(`/api/create-offer`, arg, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (token, id) =>
  await axios.get(`/api/offer/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const acceptOffer = async (token, id) =>
  await axios.put(
    `/api/offer/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
