import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { read } from "../actions/offer";
import NoFooter from "../core/NoFooter";

export default function PaymentAddress() {
  const navigate = useNavigate();
  const { offerId } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));
  const [price, setPrice] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");

  const nextStep = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      address !== "" &&
      postalCode !== "" &&
      city !== ""
    ) {
      const billAddress = {
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        city,
      };

      localStorage.setItem("billAddress", JSON.stringify(billAddress));
      navigate(`/payment/overview/${offerId}`);
    }
  };

  useEffect(() => {
    const loadOfferDetails = async () => {
      let res = await read(auth.token, offerId);
      setPrice(res.data.offer?.price);
    };

    if (auth.token) {
      loadOfferDetails();
    }
  }, [auth, offerId]);
  return (
    <NoFooter>
      <div className="payment-container">
        <h2>Rechnungsadresse</h2>
        <div className="payment-main">
          <Form className="leerfahrten-settings-form">
            <div className="leerfahrten-input-container">
              <label>Vorname*</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="leerfahrten-input-container">
              <label>Nachname*</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="leerfahrten-input-container">
              <label>Adresszeile 1*</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="leerfahrten-input-container">
              <label>Adresszusatz / Firma / c/o (optional)</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={additionalAddress}
                  onChange={(e) => setAdditionalAddress(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="leerfahrten-input-container">
              <label>Postleitzahl*</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="leerfahrten-input-container">
              <label>Stadt*</label>
              <Form.Group controlId="message">
                <Form.Control
                  as="input"
                  rows={1}
                  style={{ resize: "none" }}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
            </div>
          </Form>
        </div>
        <div className="payment-footer">
          <div className="payment-price-container">
            <p>Preis:</p>
            <span>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(price)}
            </span>
          </div>
          <div className="payment-button">
            <Button type="submit" onClick={nextStep}>
              Weiter
            </Button>
          </div>
        </div>
      </div>
    </NoFooter>
  );
}
