import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NoFooter from "../core/NoFooter";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

export function CreateDate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();

  useEffect(() => {
    const validateStep = async (step, url) => {
      const before = await getValueFromStorage(step);
      if (!before) {
        return navigate(url);
      } else {
        setLoading(false);
      }
    };

    validateStep("create-destination", "/create/destination");
    const storage = getValueFromStorage("create-date");
    storage && setDate(Date.parse(storage));
  }, [navigate]);

  const chooseDate = (value) => {
    localStorage.setItem("create-date", value);
    setDate(value);
  };

  const next = () => {
    if (date) {
      navigate("/create/time");
    }
  };

  if (loading) {
    return (
      <div className="create">
        <NoFooter>
          <div className="leerfahrten-container">
            <Container>
              <div className="create-container d-flex"></div>
            </Container>
          </div>
        </NoFooter>
      </div>
    );
  }

  return (
    <div className="create">
      <NoFooter>
        <div className="leerfahrten-container">
          <Container>
            <div className="create-container d-flex">
              <div className="leerfahrten-create-text-container">
                <h1>Wann startest du?</h1>
              </div>
              <div className="leerfahrten-create-image-container"></div>
              <div className="leerfahrten-create-input-container">
                <DatePicker
                  className="autocompleteInput"
                  locale="de"
                  selected={date}
                  onChange={(value) => chooseDate(value)}
                  dateFormat="dd.MM.yyyy"
                  minDate={new Date()}
                />
              </div>
            </div>
          </Container>
          <div className="leerfahrten-create-btn-cnt">
            <Container>
              <div className="d-flex justify-content-between">
                <Button onClick={() => navigate(-1)} variant="secondary">
                  zurück
                </Button>
                {date ? (
                  <Button onClick={next} type="submit">
                    Weiter
                  </Button>
                ) : null}
              </div>
            </Container>
          </div>
        </div>
      </NoFooter>
    </div>
  );
}

function getValueFromStorage(value) {
  return localStorage.getItem(value);
}
