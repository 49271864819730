import React from 'react'

export default function PaymentMethod({
  index,
  name,
  chooseMethod,
  selectedMethod,
  uri,
  id,
}) {
  const isActive = selectedMethod === id
  return (
    <div onClick={() => chooseMethod(id)} className='paymentMethod'>
      <div className={isActive ? 'radio-out radio-active' : 'radio-out'}>
        <div className='radio-in'></div>
      </div>
      <img src={uri} alt='payment-method' />
      <span>{name}</span>
    </div>
  )
}
