import React, { useState, useEffect } from 'react'
import Layout from '../core/Layout'
import { Container, Button, Toast, Row, Col, Form } from 'react-bootstrap'
import SettingsSidebar from '../components/SettingsSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { updatePassword } from '../actions/auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const Security = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => ({ ...state }))

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [comparePassword, setComparePassword] = useState('')

  const submitHandler = async (e) => {
    e.preventDefault()
    if (oldPassword && newPassword && comparePassword) {
      if (newPassword === comparePassword) {
        await updatePassword(auth.token, oldPassword, newPassword)
          .then((res) => {
            toast.success('Profil update erfolgreich!')
            setOldPassword('')
            setNewPassword('')
            setComparePassword('')
          })
          .catch((err) => {
            toast.error('Passwörter stimmen nicht überein')
          })
      } else {
        toast.error('Passwörter stimmen nicht überein')
      }
    }
  }
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <div className='settings-topbar'>
          <Container>
            <h1 className='settings-topbar-header'>Einstellungen</h1>
          </Container>
        </div>
        <Container className='mt-4'>
          <div
            className='mb-2 cursor-pointer mobile-back-btn'
            onClick={() => navigate(-1)}>
            zurück
          </div>
          <Row>
            <SettingsSidebar classN='mobile-hide' />
            <Col xs={12} md={8} lg={8}>
              <div className='border-container'>
                <h3 className='settings-header'>Sicherheit</h3>
              </div>
              <div className='border-container'>
                <Form
                  onSubmit={submitHandler}
                  className='leerfahrten-settings-form'>
                  <h3 className='settings-subhead'>Passwort Ändern</h3>
                  <div className='leerfahrten-input-container'>
                    <label>Altes Passwort</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        type='password'
                        rows={1}
                        style={{ resize: 'none' }}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className='leerfahrten-input-container'>
                    <label>Neues Passwort</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        type='password'
                        rows={1}
                        style={{ resize: 'none' }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className='leerfahrten-input-container'>
                    <label>Passwort wiederholen</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        type='password'
                        rows={1}
                        style={{ resize: 'none' }}
                        value={comparePassword}
                        onChange={(e) => setComparePassword(e.target.value)}
                      />
                    </Form.Group>
                    <span className='password-tip'>
                      8 Zeichen oder länger. Verwende Ziffern sowie Groß- und
                      Kleinbuchstaben.{' '}
                    </span>
                  </div>

                  <div className='text-right'>
                    <Button type='submit' variant='primary'>
                      Änderungen speichern
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Security
