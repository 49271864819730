import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetPasswordHandler } from '../actions/auth'

const ResetPasswordForm = () => {
  const { token } = useParams()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const submitHandler = async (e) => {
    e.preventDefault()
    if (newPassword) {
      if (confirmPassword === newPassword) {
        resetPasswordHandler(token, newPassword)
          .then(() => toast.success('Passwort reset erfolgreich'))
          .catch((err) => toast.error('Error'))
      } else {
        toast.error('Passwörter stimmen nicht überein')
      }
    } else {
      toast.error('Bitte gib dein neues Passwort ein')
    }
  }
  return (
    <div className='forgot-password-cnt'>
      <h1>Passwort zurücksetzen</h1>
      <div className='divider'></div>
      <p>
        Bitte gib deine E-Mail-Adresse ein und wir senden dir einen Link zum
        Zurücksetzen deines Passworts.
      </p>
      <Form onSubmit={submitHandler}>
        <Form.Control
          type='password'
          placeholder='Gib dein neues Passwort ein'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Form.Control
          type='password'
          placeholder='Bestätige dein Passwort'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button variant='primary' type='submit'>
          Abschicken
        </Button>
      </Form>
      <Link to={'/login'} style={{ color: '#FF441F' }}>
        zur Anmeldung
      </Link>
    </div>
  )
}

export default ResetPasswordForm
