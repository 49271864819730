import React, { useState, useEffect } from 'react'
import Layout from '../core/Layout'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import SettingsSidebar from '../components/SettingsSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserInfo } from '../actions/auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import AWS from 'aws-sdk'
import { nanoid } from 'nanoid/non-secure'

const awsConfig = {
  accessKeyId: 'AKIAXTAHSKR3DRGPNNEC',
  secretAccessKey: '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',
  region: 'eu-central-1',
  apiVersion: '2010-12-01',
}

const S3 = new AWS.S3(awsConfig)

const SettingsPersonal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => ({ ...state }))

  const [avatar, setAvatar] = useState({
    clientAvatar: '',
    serverAvatar: '',
  })
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [authState, setAuthState] = useState(auth)

  useEffect(() => {
    if (auth) {
      setAvatar({
        clientAvatar: auth.user.avatar,
        serverAvatar: '',
      })
      setName(auth.user.name)
      setEmail(auth.user.email)
      setTel(auth.user.tel)
      setAuthState(auth)
    }
  }, [auth])

  const chooseFile = (file) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      70,
      0,
      async (uri) => {
        try {
          setAvatar({
            serverAvatar: uri,
            clientAvatar: URL.createObjectURL(file),
          })
        } catch (error) {
          console.log(error)
        }
      },
      'blob'
    )
  }

  const submitHandler = async (e) => {
    const token = auth.token
    const serverAvatar = avatar.serverAvatar
    const clientAvatar = avatar.clientAvatar
    e.preventDefault()
    if (serverAvatar !== '') {
      const id = nanoid()

      const imageData = new File([serverAvatar], `photo.jpeg`)

      const params = {
        Bucket: 'leerfahrten24',
        Key: `${id}.jpeg`,
        Body: imageData,
        ACL: 'public-read',
        ContentType: `image/jpeg`,
      }

      S3.upload(params, async (err, data) => {
        if (err) {
          console.log(err)
        }
        const uploadedAvatar = data.Location
        await updateUserInfo(token, uploadedAvatar, name, tel).then((res) => {
          dispatch({
            type: 'LOGGED_IN_USER',
            payload: { token: authState.token, user: res.data },
          })
          window.localStorage.setItem(
            'auth',
            JSON.stringify({ token: authState.token, user: res.data })
          )
          toast.success('Profil update erfolgreich!')
        })
      })
    } else {
      await updateUserInfo(token, clientAvatar, name, tel).then((res) => {
        dispatch({
          type: 'LOGGED_IN_USER',
          payload: { token: authState.token, user: res.data },
        })
        window.localStorage.setItem(
          'auth',
          JSON.stringify({ token: authState.token, user: res.data })
        )
        toast.success('Profil update erfolgreich!')
      })
    }
  }
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <div className='settings-topbar'>
          <Container>
            <h1 className='settings-topbar-header'>Einstellungen</h1>
          </Container>
        </div>
        <Container className='mt-4'>
          <div
            className='mb-2 cursor-pointer mobile-back-btn'
            onClick={() => navigate(-1)}>
            zurück
          </div>
          <Row>
            <SettingsSidebar classN='mobile-hide' />
            <Col xs={12} md={8} lg={8}>
              <div className='border-container'>
                <h3 className='settings-header'>Persönliche Daten</h3>
              </div>
              <div className='border-container'>
                <Form
                  onSubmit={submitHandler}
                  className='leerfahrten-settings-form'>
                  <div className='leerfahrten-input-container'>
                    <div className='settings-avatar'>
                      <img src={avatar.clientAvatar} alt='avatar' />
                    </div>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => chooseFile(e.target.files[0])}
                    />
                  </div>
                  <div className='leerfahrten-input-container'>
                    <label>E-Mail-Adresse</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        rows={1}
                        disabled
                        style={{ resize: 'none' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className='leerfahrten-input-container'>
                    <label>Name</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        rows={1}
                        style={{ resize: 'none' }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className='leerfahrten-input-container'>
                    <label>Telefonnummer (optional)</label>
                    <Form.Group controlId='message'>
                      <Form.Control
                        as='input'
                        rows={1}
                        style={{ resize: 'none' }}
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className='text-right'>
                    <Button type='submit' variant='primary'>
                      Änderungen speichern
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SettingsPersonal
