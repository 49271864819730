import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import NoFooter from '../core/NoFooter'
import { checkNotification, getNotifications } from '../actions/auth'
import { useSelector } from 'react-redux'
import { HeartFilled } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Logo from '../images/Logo'

const Notifications = () => {
  const { auth } = useSelector((state) => ({ ...state }))
  const navigate = useNavigate()

  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (auth) {
      getNotifications(auth.token).then((res) => {
        setNotifications(res.data)
      })
    }
  }, [])

  const handleCheckNotification = (e) => {
    checkNotification(auth.token, e.target.id)
      .then((res) => {
        setNotifications(res.data)
        toast.success('Benachrichtigung als erledigt markiert.')
      })
      .catch(() => toast.error('Network Error'))
  }
  return (
    <NoFooter>
      <div className='leerfahrten-container'>
        <Container>
          <div className='top-back-bar'>
            <div onClick={() => navigate(-1)}>zurück</div>
          </div>
          <div className='leerfahrten-user-rides-container'>
            <h1>Benachrichtigungen ({notifications.length}) </h1>
            <div className='user-rides-results-container'>
              {notifications &&
                notifications.map((n) => (
                  <div className='result-cell'>
                    <div className='result-inner'>
                      <div className='noti-inner d-flex align-items-center'>
                        <div className='noti-flex'>
                          <div className='noti-img'>
                            <img src='/images/profile.jpg' alt='PB' />
                          </div>
                          <div className='noti-text'>
                            <span>
                              {n.text}{' '}
                              <Link
                                to={`/ride/end/${n.ride}`}
                                className='noti-link'>
                                Zur Fahrt gehen
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className='noti-date'>1 min</div>
                        <button
                          id={n._id}
                          onClick={handleCheckNotification}
                          className='noti-check'>
                          <img id={n._id} src='/images/check.png' alt='check' />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Container>
        <div className='ride-details-footer'>
          <Container className='d-flex align-items-center justify-content-between'>
            <div className='ride-details-footer-logo navbar-leerfahrten-brand'>
              <Logo />
            </div>
            <div className='ride-details-footer-links d-flex'>
              <a href='/impress'>Impressum</a>
              <a href='/privacy'>Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  )
}

export default Notifications
