import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NoFooter from "../core/NoFooter";
import { getAllChats } from "../actions/msg";
import moment from "moment";
import "moment/locale/de";
import Logo from "../images/Logo";

const Chat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { auth } = useSelector((state) => ({ ...state }));
  const { user } = auth;

  const getChats = useSelector((state) => state.getChats);
  const { chats } = getChats;

  useEffect(() => {
    dispatch(getAllChats());
  }, [dispatch]);
  return (
    <NoFooter>
      <div className="leerfahrten-container chat-phone">
        <Container>
          <div className="top-back-bar">
            <div onClick={() => navigate(-1)}>zurück</div>
          </div>
          <div className="chat-wrapper">
            <Row>
              <Col lg={4}>
                <div className="chat-side-container border-container">
                  <div className="chat-side-top-bar d-flex align-items-center">
                    <div>Anzahl Chats: {chats?.length}</div>
                    <p>Chats</p>
                  </div>
                  <div className="chat-inner-wrapper">
                    {chats &&
                      chats.map((c) => (
                        <Link
                          to={`/user/chat/${c._id}`}
                          key={`key-${c._id}`}
                          className="chat-side-inner-container d-flex"
                        >
                          <div className="chat-side-pic">
                            {auth &&
                              c.recipients.map((r) => {
                                if (r._id !== user?._id) {
                                  return (
                                    <img
                                      key={r?._id.toString()}
                                      src={r.avatar}
                                      alt="profile"
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </div>
                          <div
                            className="chat-side-text"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color: "#363636",
                            }}
                          >
                            {auth &&
                              c.recipients.map((r) => (
                                <span
                                  key={r?._id.toString()}
                                  className="chat-side-text-name"
                                >
                                  {r._id !== user?._id ? r.name : null}
                                </span>
                              ))}

                            <span
                              className="chat-side-text-text"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {c.lastMessage}
                            </span>
                          </div>
                          <div className="chat-side-ntf">
                            <span className="chat-side-ntf-days">
                              {moment.unix(c.date).fromNow()}
                            </span>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </Col>

              <Col lg={8} className="phone-hide">
                <div className="chat-main-container border-container">
                  <div className="chat-main-top-bar d-flex align-items-center">
                    <div className="chat-main-id">
                      <span className="font-weight-bold">Anfrage ID: </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <Link
                        to="/user/chat"
                        className="chat-order-details ml-auto"
                      ></Link>
                      <div className="chat-settings d-flex align-items-center">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>

                  <div className="chat-main-profile-info"></div>
                  <div className="chat-main-inner-container">
                    <div className="chat-show-message-container"></div>
                    <div className="chat-main-message-container">
                      <div className="chat-main-message-input">
                        <Form.Group controlId="message">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="Ihre Nachricht"
                            style={{ resize: "none" }}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="chat-main-message-bottom d-flex align-items-center justify-content-between">
                        <div className="chat-message-bottom-actions">
                          <img src="/images/attachment.svg" alt="" />
                        </div>
                        <Button className="chat-message-bottom-send">
                          Senden
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="ride-details-footer">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="ride-details-footer-logo navbar-leerfahrten-brand">
              <Logo />
            </div>
            <div className="ride-details-footer-links d-flex">
              <a href="/impress">Impressum</a>
              <a href="/privacy">Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  );
};

export default Chat;
