import React from "react";
import { Modal } from "react-bootstrap";

const UploadPicturesModal = ({
  show,
  setShow,
  images,
  addImage,
  uploadPictures,
  loading,
}) => {
  return (
    <Modal show={show} centered onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="feedback-header">
          Laden Sie Bilder hoch
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex uploadPictures-container">
          {images?.length > 0 &&
            images.map((image, index) => (
              <div key={index.toString()} className="uploadPictures-col">
                <img src={image.path} alt="upload" />
              </div>
            ))}
          <div
            onClick={addImage}
            className="uploadPictures-col uploadPictures-add"
          >
            <label for="uploadBtn">+</label>
            <input
              type="file"
              id="uploadBtn"
              accept="image/*"
              onChange={(e) => addImage(e.target.files[0])}
              hidden
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="prm-btn"
          type="submit"
          onClick={uploadPictures}
          style={{ margin: "0 10px", width: "100%" }}
        >
          {loading ? "Bilder werden hochgeladen..." : "Bilder hochladen"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadPicturesModal;
