import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Layout from "../core/Layout";
import { toast } from "react-toastify";
import { login } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonText] = useState("Anmelden");

  const { auth } = useSelector((state) => ({ ...state }));

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let res = await login({
        email,
        password,
      });

      if (res.data) {
        window.localStorage.setItem("auth", JSON.stringify(res.data));
        dispatch({ type: "LOGGED_IN_USER", payload: res.data });
      }
      toast.success("Login success.");
      navigate("/");
    } catch (err) {
      toast.error("Benutzername oder Passwort falsch.");
    }
  };
  return (
    <Layout>
      <div className="leerfahrten-container">
        {auth && <Navigate to="/" />}
        <Container>
          <div className="auth-container">
            <div className="auth-wrapper">
              <h1 className="mid-heading">Anmelden</h1>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="email">
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-Mail-Adresse"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="paassword">
                  <Form.Label>Passwort</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Passwort"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <p>
                  Neu bei Leerfahrten24?{" "}
                  <Link to={"/register"} style={{ color: "#FF441F" }}>
                    Kundenkonto erstellen
                  </Link>{" "}
                  oder{" "}
                  <Link to={"/forgot_password"} style={{ color: "#FF441F" }}>
                    Passwort vergessen?
                  </Link>
                </p>
                <Button variant="primary" type="submit">
                  {buttonText}
                </Button>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Login;
