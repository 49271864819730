import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

const PlacesAutocomplete = ({ type, setState, initialValue }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    // setState({ address: e.target.value, geometry: { lat: null, lng: null } })
    setValue(e.target.value)
  }

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)
      clearSuggestions()
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0])
        setState({
          address: description,
          geometry: { lat, lng },
        })
        localStorage.setItem(
          type,
          JSON.stringify({
            address: description,
            geometry: { lat, lng },
          })
        )
      })
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <div
          className='autocompleteItems'
          key={place_id}
          onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </div>
      )
    })

  return (
    <div style={{ flex: 1, width: '100%' }} ref={ref}>
      <input
        value={!value ? initialValue : value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={type}
        className='autocompleteInput'
      />
      {status === 'OK' && (
        <div className='autocompleteContainer'>{renderSuggestions()}</div>
      )}
    </div>
  )
}

export default PlacesAutocomplete
