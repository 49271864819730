import React from 'react'

const Logo = () => {
  return (
    <svg
      id='Ebene_1'
      data-name='Ebene 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 648 144'>
      <defs></defs>
      <rect
        className='cls-1'
        x='0.5'
        y='0.5'
        width='647'
        height='143'
        rx='14.5'
      />
      <path
        className='cls-2'
        d='M1206.17,362.4a14,14,0,0,1,14,14v114a14,14,0,0,1-14,14h-618a14,14,0,0,1-14-14v-114a14,14,0,0,1,14-14h618m0-1h-618a15,15,0,0,0-15,15v114a15,15,0,0,0,15,15h618a15,15,0,0,0,15-15v-114a15,15,0,0,0-15-15Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M657.51,447.93v13.38H623.83v-53h14.81v39.66Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M705.18,440.47a26.49,26.49,0,0,1-.29,3.73H674.5c.93,4.69,3.91,7.12,8.36,7.12a8.22,8.22,0,0,0,7.4-3.84h14c-2.88,9.34-11.15,15.17-21.42,15.17-12.77,0-22.23-9.33-22.23-21.83s9.39-21.77,22.23-21.77c13.19,0,22.32,9.41,22.32,21.41Zm-30.43-4.08H691.3a8.23,8.23,0,0,0-8.44-6.29C678.75,430.1,675.89,432.33,674.75,436.39Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M753.78,440.47a26.49,26.49,0,0,1-.29,3.73H723.1c.92,4.69,3.9,7.12,8.36,7.12a8.19,8.19,0,0,0,7.39-3.84h14c-2.88,9.34-11.16,15.17-21.42,15.17-12.77,0-22.23-9.33-22.23-21.83s9.38-21.77,22.23-21.77c13.18,0,22.32,9.41,22.32,21.41Zm-30.43-4.08h16.54a8.21,8.21,0,0,0-8.43-6.29C727.35,430.1,724.49,432.33,723.35,436.39Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M786.86,420.2v12.9h-5.47c-5,0-7.08,2.18-7.08,7.4v20.81h-14.1V420.39h9.44l2.2,4.54c2.89-3.33,6.4-4.73,11.27-4.73Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M819.57,432.13h-9.18v29.18H796.3V432.13h-6.77l0-11.07h7v-3.91c0-8.2,4.5-13,13.18-13h9.84V415h-6.22a3.05,3.05,0,0,0-3.34,3.33v2.78h9.64Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M867.75,420.35v40.92H856.86l-1.21-2.89a19.38,19.38,0,0,1-12.34,4.27c-12.52,0-21.46-9.12-21.46-21.84s8.94-21.69,21.46-21.69a19.42,19.42,0,0,1,12.5,4.4l1.44-3.17Zm-13.4,20.49a9.16,9.16,0,1,0-9.16,9.34A9,9,0,0,0,854.35,440.84Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M917.11,436.67v24.64H903v-23c0-4.72-1.66-7.13-5.34-7.13-4.26,0-6.92,3-6.92,8.28v21.85h-14.1V405.62h14.1v17.47a17.43,17.43,0,0,1,10.92-3.91C911.07,419.18,917.11,426.07,917.11,436.67Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M952.21,420.2v12.9h-5.47c-5,0-7.08,2.18-7.08,7.4v20.81h-14.1V420.39H935l2.2,4.54c2.88-3.33,6.39-4.73,11.27-4.73Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M985.83,449v12.34h-9.69c-9,0-14.55-5.53-14.55-14.62V431.25H954.1V428l18.33-19.52h2.9v12h10.28v10.86h-9.93v13c0,2.93,1.77,4.7,4.75,4.7Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M1034.22,440.47a26.49,26.49,0,0,1-.29,3.73h-30.39c.92,4.69,3.9,7.12,8.36,7.12a8.19,8.19,0,0,0,7.39-3.84h14c-2.88,9.34-11.16,15.17-21.42,15.17-12.77,0-22.23-9.33-22.23-21.83s9.38-21.77,22.23-21.77c13.18,0,22.32,9.41,22.32,21.41Zm-30.43-4.08h16.54a8.21,8.21,0,0,0-8.43-6.29C1007.79,430.1,1004.93,432.33,1003.79,436.39Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-2'
        d='M1081.1,436.36v24.95H1067v-23c0-4.76-1.79-7.13-5.34-7.13-4.4,0-6.92,3.07-6.92,8.28v21.85h-14.1V420.39h9.89l1.94,4.22a17,17,0,0,1,12.88-5.43C1074.86,419.18,1081.1,425.94,1081.1,436.36Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-3'
        d='M1124.64,449.36v11.95h-37.48v-3.06c20.28-23.92,23.07-27.78,23.07-32.11a4.25,4.25,0,0,0-4.38-4.56c-2.83,0-4.56,1.9-4.56,4.94v1H1088v-.93c0-10.19,6.68-16.92,17.71-16.92,10.69,0,18.07,6.57,18.07,16,0,6.34-2.34,11.21-13.12,23.69Z'
        transform='translate(-573.17 -361.4)'
      />
      <path
        className='cls-3'
        d='M1170.51,452.26H1166v9.05H1153v-9.05h-24.19v-2.47L1145,410.92h13.38L1145.86,441h8.06l2.95-11.1H1166V441h4.56Z'
        transform='translate(-573.17 -361.4)'
      />
    </svg>
  )
}

export default Logo
