import React from 'react'
import { Modal } from 'react-bootstrap'

const ChatModal = ({ show, setShow, input, setInput, submitHandler }) => {
  return (
    <Modal
      show={show}
      centered
      onHide={() => {
        setShow(false)
        setInput('')
      }}>
      <Modal.Header closeButton>
        <Modal.Title className='feedback-header'>
          Beginnen Sie einen Chat mit dem Nutzer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className='chatInput'
          placeholder='Ihre Nachricht'
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className='prm-btn'
          type='submit'
          onClick={submitHandler}
          style={{ margin: '0 10px', width: '100%' }}>
          Chat beginnen
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChatModal
