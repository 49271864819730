import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../core/Layout";

const Agb = () => {
  return (
    <Layout>
      <div className="leerfahrten-container agb-container">
        <Container className="pt-4">
          <h1 className="settings-topbar-header">
            AGB - Allgemeine Geschäftsbedingungen
          </h1>
          <p>
            Auf dieser Internetpräsenz wird eine webbasierte Anwendung
            angeboten, um private und gewerbliche Versender, folgend
            Auftraggeber genannt, mit privaten und gewerblichen Transporteuren
            für sämtliche und individuelle Transportdienstleistungen, folgend
            Kuriere genannt, zusammenzuführen.
          </p>
          <p>
            Hier haben sowohl der Auftraggeber als auch der Kurier die
            Möglichkeit, über die Leerfahrten24.de-Webseite kostenlos über die
            websiteinterne Chatfunktion miteinander zu kommunizieren und alle
            Details zum Versand/Transport zu klären. Beide Parteien können sich
            kostenfrei auf Leerfahrten24.de registrieren. Leerfahrtenangebote
            bzw. Leerfahrtengesuche sind kostenfrei. Kosten entstehen, wenn sich
            beide Parteien auf eine Fahrt geeinigt haben.
          </p>
          <h2>§1 Geltung und allgemeine Regelungen</h2>
          <p>
            (1) Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten für
            alle Vermittlungsleistungen durch Leerfahrten24.de zum Zeitpunkt der
            Verwendung gültigen Fassung (www.leerfahrten24.de).
          </p>
          <p>
            (2) Leehrfahrten24.de bietet die webbasierte Anwendung an, die eine
            technologische Lösung zum Angebot und dem Buchen von
            Überbringdienstleistungen zur Verfügung stellt.
          </p>
          <p>
            (3) Erbringer von Überbringdienstleistungen im Sinne dieser
            Geschäftsbedingungen werden Kuriere genannt.
          </p>
          <p>
            (4) Auftraggeber im Sinne dieser Geschäftsbedingungen ist jede
            Person, die eine Transportleistung über Leerfahrten24.de gegen einen
            bestimmten Preis in Anspruch nimmt.
          </p>
          <h2>§2 Zustandekommen des Vertrages</h2>
          <p>
            (1) Die folgenden Regelungen gelten für alle Vermittlungsleistungen
            über www.leerfahrten24.de
          </p>
          <p>
            (2) Im Falle des Vertragsschlusses, also wenn sich Kurier und
            Auftraggeber einigen, kommt ein Vermittlungsvertrag zwischen
            Leerfarhrten24 GmbH mit dem Kurier zustande. Leerfahrten24 GmbH
            erhebt Gebühren gem. Preistabelle.
          </p>
          <p>
            (3) Das Erstellen und Präsentieren von Angeboten und Gesuchen auf
            diesem Internetportal stellt kein rechtlich bindendes
            Vertragsangebot dar, sondern ist nur eine unverbindliche
            Aufforderung an potenzielle Interessenten. Mit der Buchung des
            gewünschten Botengangs gibt der Interessent ein für ihn
            verbindliches Angebot für den Abschluss eines Buchungsvertrages mit
            dem Kurier ab.
          </p>
          <p>
            (4) Der Kurier erstellt auf leerfahrten24.de sein Angebot. Der
            Auftraggeber kann über den direkten Chat mit dem Kurier mehr Details
            über die Route, Laderaum, Dauer und andere Fragen in Erfahrung
            bringen. Nachdem beide Parteien einen Preis festgelegt haben,
            erfolgt die Buchung durch das Klicken auf die dafür vorhergesehene
            Schaltfläche. Dieses Angebot muss dann von der anderen Partei
            angenommen werden. Der Preis wird auf eines der Konten von
            Leerfahrten24 GmbH vom Auftraggeber eingezahlt. Im Falle des
            erfolgreichen Erbringens der Dienstleistung wird die zwischen dem
            Auftraggeber und dem Kurier vereinbarte Prämie auf das Konto des
            Kuriers abzüglich der Gebühren für Leerfahrten24 GmbH überwiesen.
            Beide Parteien bestätigen dies im Chat.
          </p>
          <p>
            (5) Vorherige Registrierung auf Leerfahrten24.de ist für beide
            Parteien, also Kurier und Auftraggeber, erforderlich. Registrierung
            bei Leerfahrten24.de unter Eingabe der Anmeldeangaben. (Name,
            Adresse, E-Mail-Adresse und Passwort). Je nach Einzelfall:
            Betätigung des Buttons „Buchen/Bezahlen/Verrechnen“. Eingabe der
            Bezahldaten (wenn gefordert) und verbindliche Absendung der Buchung.
          </p>
          <p>
            (6) Die Buchung einer Leerfahrt stellt keine Garantie dafür, dass
            der Transporteur die gebuchte Leerfahrt final erledigt. Der Bucher
            hat anschließend die Möglichkeit, sich das Guthaben auf ein
            deutsches Konto auszahlen zu lassen.
          </p>
          <p>
            (7) Erstellung eines Angebots erfolgt wie folgt 1) Anmelden nach der
            Registrierung. 2) Anklicken des Buttons „Leerfahrt anbeiten“. 3)
            Eingabe der Daten zum zukünftigen Angebot, hier besteht die
            Möglichkeit, Fotos hochzuladen- 4) Prüfung der Angaben im
            Vorschaubereich. 5) Betätigung des Buttons „Kostenlos anbieten“. Die
            Einstellung eines Leerfahrtangebots ist keine Garantie dafür, dass
            diese von Auftraggebern gebucht wird. Das erfolgreiche
            Zustandekommen einer Überbringleistung zwischen den Parteien hängt
            ausschließlich davon ab, ob beide Parteien sich in den Details, wie
            z.B. Transportgut, Zeitraum, Strecke etc. geeinigt haben.
          </p>

          <p>
            (8) Bewertung 7.1 Jeder Leerfahrten24-User, der eine über
            Leerfahrten24.de vermittelte Leerfahrt erfolgreich gebucht bzw.
            absolviert hat, kann die jeweils andere Partei bewerten. Unwahre
            oder gegen allgemeine Gesetze verstoßende Kommentare werden von uns
            gelöscht. 7.2 Eine Bewertung setzt sich zusammen aus einer
            Sternenbewertung, die bis fünf Sterne zählen kann. Je höher die
            Sterne, desto besser die Reputation. Des Weiteren erfolgt ein
            optionaler Kommentar zur abgegebenen Sternenbewertung. Die
            Einzelbewertung kann sowohl positiv als auch negativ ausfallen. So
            muss ein User, das bewertet wird, „Kritik an seinen Leistungen und
            die öffentliche Erörterung geäußerter Kritik grundsätzlich
            hinnehmen“ Quelle (BGH-Urteil 14.01.2020 zu Bewertungsportalen: VI
            ZR 496/18). 7.3 Aus der öffentlichen Einzelbewertung ermittelt
            Leerfahrten24 ein Gesamtprädiakt des Users, die auch öffentlich
            sichtbar ist.
          </p>
        </Container>
      </div>
    </Layout>
  );
};

export default Agb;
