import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Layout from "../core/Layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [accepted, setAccepted] = useState(false);
  const [alreadyAccepted, setAlreadyAccepted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const emailConfirmation = async () =>
      await axios.post(`/api/user/confirm`, { token });

    emailConfirmation()
      .then((res) => {
        if (res.data.alreadyAccepted) {
          setAlreadyAccepted(true);
        }
        if (res.data.accepted) {
          setAccepted(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  if (error) {
    return (
      <Layout>
        <div className="leerfahrten-container">
          <Container>
            <div className="confirm-email-container">
              <h1 style={{ textAlign: "center" }}>Error!</h1>
              <p>Der Token ist falsch oder abgelaufen.</p>
              <Button onClick={() => navigate("/")} variant="primary">
                Zur Homepage
              </Button>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }

  if (alreadyAccepted) {
    return (
      <Layout>
        <div className="leerfahrten-container">
          <Container>
            <div className="confirm-email-container">
              <h1 style={{ textAlign: "center" }}>E-Mail bereits bestätigt!</h1>
              <p>
                Du hast deine E-Mail bereits bestätigt. Du kannst dich jetzt
                anmelden und alle Funktionen nutzen
              </p>
              <Button onClick={() => navigate("/")} variant="primary">
                Zur Homepage
              </Button>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }

  if (accepted) {
    return (
      <Layout>
        <div className="leerfahrten-container">
          <Container>
            <div className="confirm-email-container">
              <h1 style={{ textAlign: "center" }}>Willkommen!</h1>
              <p>
                Deine E-Mail wurde erfolgreich bestätigt. Logge dich jetzt ein
                um alle Features zu nutzen.
              </p>
              <Button onClick={() => navigate("/login")} variant="primary">
                Zum Login
              </Button>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="leerfahrten-container">
        <Container>
          <div className="confirm-email-container">
            <p>Loading...</p>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
