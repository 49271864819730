import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LayoutNoBg from "../core/LayoutNoBg";
import { Button, DatePicker } from "antd";
import DepartureSidebar from "../components/DepartureSidebar";
import DestinationSidebar from "../components/DestinationSidebar";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { useNavigate } from "react-router-dom";
import locale from "antd/es/date-picker/locale/de_DE";

export function Home() {
  const navigate = useNavigate();
  const [start, setStart] = useState({
    address: "",
    geometry: {
      lat: null,
      lng: null,
    },
  });
  const [destination, setDestination] = useState({
    address: "",
    geometry: {
      lat: null,
      lng: null,
    },
  });
  const [date, setDate] = useState();

  const chooseDate = (date, dateString) => {
    localStorage.setItem("search-time", dateString);
    setDate(dateString);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (start?.address && destination?.address) {
      navigate(
        `/search?start=${start?.address}&destination=${destination?.address}&slat=${start?.geometry.lat}&slng=${start?.geometry.lng}&dlat=${destination?.geometry.lat}&dlng=${destination?.geometry.lng}&date=${date}`
      );
    } else {
      toast.error("Bitte fülle alle Felder aus.");
    }
  };

  useEffect(() => {
    const departureSide = document.getElementById("departureSidebar");
    departureSide.style.transform = "translate(100%)";
    document.body.style.overflow = "auto";
  }, [start]);

  useEffect(() => {
    const destinationSidebar = document.getElementById("destinationSidebar");
    destinationSidebar.style.transform = "translate(100%)";
    document.body.style.overflow = "auto";
  }, [destination]);

  const openDeparture = () => {
    const departureSide = document.getElementById("departureSidebar");
    const departureInput = document.getElementById("departure-input");
    departureSide.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
    window.scrollTo({ top: 0, behavior: "smooth" });
    departureInput?.focus();
  };

  const openDestination = () => {
    const destinationSidebar = document.getElementById("destinationSidebar");
    const destinationInput = document.getElementById("destination-input");
    destinationSidebar.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
    window.scrollTo({ top: 0, behavior: "smooth" });
    destinationInput?.focus();
  };

  return (
    <LayoutNoBg>
      <DepartureSidebar start={setStart} />
      <DestinationSidebar destination={setDestination} />
      <div className="leerfahrten-container-no-margin">
        <main id="leerfahrten-main">
          <div className="leerfahrten-main-background">
            <Container>
              <h1>Kein Bock mehr auf Leerfahrten?</h1>
            </Container>
          </div>
          <Container>
            <div className="leerfahrten-main-form">
              <form className="main-mobile-form-home" onSubmit={handleSubmit}>
                <Row>
                  <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                    <div
                      className="main-form-input-mobile"
                      onClick={openDeparture}
                    >
                      <LogoutOutlined />
                      {start ? <p>{start.address}</p> : <span>Start</span>}
                    </div>
                  </Col>
                  <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                    <div
                      className="main-form-input-mobile"
                      onClick={openDestination}
                    >
                      <LoginOutlined />
                      {destination ? (
                        <p>{destination.address}</p>
                      ) : (
                        <span>Ziel</span>
                      )}
                    </div>
                  </Col>
                  <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                    <DatePicker
                      className="autocompleteInput"
                      locale={locale}
                      mode="date"
                      selected={date}
                      format="DD.MM.YYYY"
                      onChange={chooseDate}
                      timeIntervals={15}
                      placeholder="Wann startest du?"
                      style={{ fontSize: 16 }}
                    />
                  </Col>
                  <Col className="main-form-input-col" xs={12} md={12} lg={2}>
                    <Button htmlType="submit" size="large">
                      Suchen
                    </Button>
                  </Col>
                </Row>
              </form>
              <form className="main-desktop-form-home" onSubmit={handleSubmit}>
                <Row>
                  <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                    <PlacesAutocomplete type="Start" setState={setStart} />
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <PlacesAutocomplete type="Ziel" setState={setDestination} />
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <DatePicker
                      className="autocompleteInput"
                      locale={locale}
                      mode="date"
                      selected={date}
                      format="DD.MM.YYYY"
                      onChange={chooseDate}
                      timeIntervals={15}
                      placeholder="Wann startest du?"
                    />
                  </Col>

                  <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                    <Button htmlType="submit" size="large">
                      Suchen
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </Container>
        </main>
        {/*<div>
          <Container>
            <Row className="home-trust-section">
              <Col xs={12} md={12} lg={4}>
                <div className="border-container">
                  <div className="home-trust-container d-flex align-items-center">
                    <div>
                      <img src="/images/test1.png" />
                    </div>
                    <div className="home-trust-text-container d-flex flex-column">
                      <p>Vertrauenswürdig</p>
                      <span>
                        Wir nehmen uns Zeit, unsere Mitglieder zu prüfen.
                        Profile, Bewertungen und Ausweißdokumente werden
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="border-container">
                  <div className="home-trust-container d-flex align-items-center">
                    <div>
                      <img src="/images/test2.png" />
                    </div>
                    <div className="home-trust-text-container d-flex flex-column">
                      <p>Vertrauenswürdig</p>
                      <span>
                        Wir nehmen uns Zeit, unsere Mitglieder zu prüfen.
                        Profile, Bewertungen und Ausweißdokumente werden
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="border-container">
                  <div className="home-trust-container d-flex align-items-center">
                    <div>
                      <img src="/images/test3.png" />
                    </div>
                    <div className="home-trust-text-container d-flex flex-column">
                      <p>Vertrauenswürdig</p>
                      <span>
                        Wir nehmen uns Zeit, unsere Mitglieder zu prüfen.
                        Profile, Bewertungen und Ausweißdokumente werden
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
                      </div>*/}
        <div style={{ marginTop: 50 }}>
          <Container>
            <div id="about" className="home-text-section">
              <h2>
                Kein Bock mehr auf Leerfahrten? Spare bares Geld mit unserem
                Service!
              </h2>
              <div className="home-text-section-container">
                <h3>Über uns</h3>
                <div className="home-text-section-container-paragrap">
                  <p>
                    Wir bieten einen Vermittlungsservice für Leerfahrten an. Wir
                    verstehen, dass Leerfahrten für Unternehmen kosten- und
                    zeitaufwendig sein können, und deshalb sind wir hier, um zu
                    helfen. Leerfahrten treten auf, wenn ein LKW oder
                    Lieferfahrzeug ohne Fracht an Bord zu einem Zielort fährt.
                    Dies kann aus verschiedenen Gründen passieren, z.B. nach
                    einer Lieferung, wenn keine Rücksendung erfolgt oder
                    aufgrund einer Fehlkommunikation oder Stornierung des
                    Absenders.
                  </p>
                </div>
              </div>
              <div className="home-text-section-container">
                <div className="home-text-section-container-paragrap">
                  <p>
                    Leerfahrten können für Unternehmen frustrierend sein, da sie
                    Zeit und Geld kosten. Nicht nur verbraucht das Fahrzeug
                    Kraftstoff und andere Ressourcen, um ohne Fracht zu reisen,
                    sondern das Unternehmen kann auch potenzielle Einnahmen
                    verpassen, wenn sich keine Gelegenheit bietet, Waren zu
                    transportieren. Unser Vermittlungsservice zielt darauf ab,
                    Unternehmen oder Privatpersonen und Speditionsunternehmen zu
                    verbinden, um die Anzahl der Leerfahrten zu reduzieren.
                    Durch die Nutzung unserer Plattform können Unternehmen oder
                    Privatpersonen ihre Versandbedürfnisse bewerben und Angebote
                    von Speditionsunternehmen erhalten, die verfügbaren Platz
                    auf ihren Fahrzeugen haben. Dies hilft, die Anzahl der
                    Leerfahrten zu reduzieren, da das Speditionsunternehmen
                    zusätzliche Fracht auf der Rückreise aufnehmen kann.
                  </p>
                </div>
              </div>
              <div className="home-text-section-container">
                <div className="home-text-section-container-paragrap">
                  <p>
                    Unser Service ist einfach zu bedienen und ermöglicht es
                    Unternehmen und Privatpersonen, schnell und einfach
                    Speditionsunternehmen mit verfügbarem Platz für ihre
                    Sendungen zu finden. Durch die Reduzierung der Anzahl der
                    Leerfahrten können Unternehmen Zeit und Geld sparen und dazu
                    beitragen, ihren CO2-Fußabdruck zu reduzieren, indem sie
                    unnötige Emissionen vermeiden. Wir sind stolz auf unser
                    Engagement für Kundenzufriedenheit und arbeiten hart daran,
                    sicherzustellen, dass unser Vermittlungsservice sowohl
                    effizient als auch effektiv ist. Unser Team steht Ihnen zur
                    Beantwortung von Fragen und zur Unterstützung während des
                    gesamten Prozesses zur Verfügung. Wenn Sie genug von
                    Leerfahrten haben und Kosten sparen und die Effizienz
                    steigern möchten, sollten Sie unseren Vermittlungsservice in
                    Betracht ziehen. Wir sind zuversichtlich, dass wir die
                    richtige Lösung für Ihre Versandbedürfnisse finden können.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="app-ad-container">
          <Container>
            <div>
              <h2 className="app-heading">
                Mit der Leerfahrten24-App kostengünstig transportieren
              </h2>
              <p className="app-sub">
                Wir helfen dir günstig zu transportieren oder einen geeigneten
                Reisepartner zu finden
              </p>
              <div className="app-download-img d-flex align-items-center">
                <a href="https://apps.apple.com/de/app/leerfahrten24/id6443995494">
                  <img src="/images/ios-badge-de.svg" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.lukas2k21.l24app&hl=de">
                  <img src="/images/android-badge-de.svg" />
                </a>
              </div>
            </div>
            <img className="app-add-img" src="/images/leerfahrten-handy.png" />
          </Container>
        </div>
      </div>
    </LayoutNoBg>
  );
}

export default Home;
