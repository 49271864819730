import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "../images/Logo";

const HeaderNoBgTest = () => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);

  const toggleOpen = () => {
    const mobileNav = document.getElementById("mobile-nav");
    mobileNav.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
  };

  const toggleClose = () => {
    const mobileNav = document.getElementById("mobile-nav");
    mobileNav.style.transform = "translate(100%)";
    document.body.style.overflow = "auto";
  };

  const changeNavbar = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);

  const [user, setUser] = useState({});
  const [name, setName] = useState();

  const dispatch = useDispatch();

  const { auth } = useSelector((state) => ({ ...state }));

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
  };

  return (
    <header className={navbar ? "header-no-bg-active" : "header-no-bg"}>
      <Container>
        <nav className="navbar-leerfahrten">
          <LinkContainer to="/">
            <Navbar.Brand className="navbar-leerfahrten-brand">
              <Logo />
            </Navbar.Brand>
          </LinkContainer>
          <div className="navbar-bar">
            <div className="navbar-navigation navbar-nav">
              <Link to="/search" className="d-flex align-items-center">
                <div className="header-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 71.4 72.72"
                  >
                    <g id="Ebene_2" data-name="Ebene 2">
                      <g id="Ebene_1-2" data-name="Ebene 1">
                        <path
                          className="cls-1"
                          d="M52.25,51.44a30.39,30.39,0,1,0-2.19,2.06L69.28,72.72l2.12-2.13ZM3,30.38A27.38,27.38,0,1,1,30.37,57.75,27.41,27.41,0,0,1,3,30.38Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="nav-link">Suchen</div>
              </Link>
              <Link
                to="/create/start"
                className="d-flex align-items-center ml-2"
              >
                <div className="header-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 60.75 60.75"
                  >
                    <g id="Ebene_2" data-name="Ebene 2">
                      <g id="Ebene_1-2" data-name="Ebene 1">
                        <path
                          className="cls-1"
                          d="M30.38,3A27.38,27.38,0,1,1,3,30.38,27.41,27.41,0,0,1,30.38,3m0-3A30.38,30.38,0,1,0,60.75,30.38,30.38,30.38,0,0,0,30.38,0Z"
                        />
                        <rect
                          className="cls-1"
                          x="12.28"
                          y="29.3"
                          width="36.2"
                          height="2.14"
                        />
                        <rect
                          className="cls-1"
                          x="29.3"
                          y="12.28"
                          width="2.14"
                          height="36.2"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="nav-link">Fahrt anbieten</div>
              </Link>
              {auth ? (
                <>
                  <div className="header-user-container d-flex align-items-center">
                    <NavDropdown
                      className="header-user-name"
                      title={auth.user.name}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item href="/dashboard">
                        Übersicht
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/user/rides">
                        Deine Fahrten
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/user/notifications">
                        Benachrichtigungen
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/user/notes">
                        Merkzettel
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/settings">
                        Einstellungen
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          logout(() => {
                            navigate("/");
                          });
                        }}
                      >
                        Abmelden
                      </NavDropdown.Item>
                    </NavDropdown>
                    <div className="header-user-img">
                      <img src={auth?.user?.avatar} alt="avatar" />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <Nav.Link>Anmelden</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>Account erstellen</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </div>
            <button
              onClick={toggleOpen}
              id="navbar-toggle-open"
              className="navbar-toggle"
            >
              <svg
                viewBox="0 0 20 14"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <path
                    fill="currentColor"
                    d="M1 0h18a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 6h18a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 6h18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"
                    id="hamburgera"
                  ></path>
                </defs>
                <use xlinkHref="#hamburgera" fillRule="evenodd"></use>
              </svg>
            </button>
          </div>
        </nav>
      </Container>
      <div id="mobile-nav" className="navbar-navigation-mobile">
        <div>
          <Container>
            <div className="navbar-leerfahrten">
              <div className="navbar-leerfahrten-brand">
                <svg
                  version="1.1"
                  id="Ebene_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 648 144"
                  xmlSpace="preserve"
                >
                  <path
                    className="st0"
                    d="M84.3,86.5v13.4H50.7v-53h14.8v39.7L84.3,86.5z"
                  />
                  <path
                    className="st0"
                    d="M132,79.1c0,1.2-0.1,2.5-0.3,3.7h-30.4c0.9,4.7,3.9,7.1,8.4,7.1c3,0.2,5.8-1.3,7.4-3.8h14
	c-2.9,9.3-11.2,15.2-21.4,15.2c-12.8,0-22.2-9.3-22.2-21.8s9.4-21.8,22.2-21.8C122.9,57.7,132,67.1,132,79.1L132,79.1z M101.6,75
	h16.5c-0.9-3.9-4.5-6.5-8.4-6.3C105.6,68.7,102.7,70.9,101.6,75z"
                  />
                  <path
                    className="st0"
                    d="M180.6,79.1c0,1.2-0.1,2.5-0.3,3.7h-30.4c0.9,4.7,3.9,7.1,8.4,7.1c3,0.2,5.8-1.3,7.4-3.8h14
	c-2.9,9.3-11.2,15.2-21.4,15.2c-12.8,0-22.2-9.3-22.2-21.8s9.4-21.8,22.2-21.8C171.4,57.7,180.6,67.1,180.6,79.1L180.6,79.1z
	 M150.2,75h16.5c-0.9-3.9-4.5-6.5-8.4-6.3C154.2,68.7,151.3,70.9,150.2,75L150.2,75z"
                  />
                  <path
                    className="st0"
                    d="M213.7,58.8v12.9h-5.5c-5,0-7.1,2.2-7.1,7.4v20.8H187V59h9.4l2.2,4.5c2.9-3.3,6.4-4.7,11.3-4.7H213.7z"
                  />
                  <path
                    className="st0"
                    d="M246.4,70.7h-9.2v29.2h-14.1V70.7h-6.8V59.7h7v-3.9c0-8.2,4.5-13,13.2-13h9.8v10.9h-6.2
	c-1.7-0.2-3.2,1.1-3.3,2.7c0,0.2,0,0.4,0,0.6v2.8h9.6L246.4,70.7z"
                  />
                  <path
                    className="st0"
                    d="M294.6,59v40.9h-10.9l-1.2-2.9c-3.5,2.8-7.9,4.3-12.3,4.3c-12.5,0-21.5-9.1-21.5-21.8s8.9-21.7,21.5-21.7
	c4.6,0,9,1.5,12.5,4.4l1.4-3.2L294.6,59z M281.2,79.4c-0.1-5.1-4.3-9.1-9.3-9c-5.1,0.1-9.1,4.3-9,9.3c0.1,5,4.2,9,9.2,9
	c5,0.1,9.1-3.9,9.2-8.8C281.2,79.8,281.2,79.6,281.2,79.4z"
                  />
                  <path
                    className="st0"
                    d="M343.9,75.3v24.6h-14.1v-23c0-4.7-1.7-7.1-5.3-7.1c-4.3,0-6.9,3-6.9,8.3v21.9h-14.1V44.2h14.1v17.5
	c3.1-2.5,6.9-3.9,10.9-3.9C337.9,57.8,343.9,64.7,343.9,75.3z"
                  />
                  <path
                    className="st0"
                    d="M379,58.8v12.9h-5.5c-5,0-7.1,2.2-7.1,7.4v20.8h-14.1V59h9.4l2.2,4.5c2.9-3.3,6.4-4.7,11.3-4.7H379z"
                  />
                  <path
                    className="st0"
                    d="M412.7,87.6v12.3H403c-9,0-14.5-5.5-14.5-14.6V69.9h-7.5v-3.3l18.3-19.5h2.9v12h10.3v10.9h-9.9v13
	c0,2.9,1.8,4.7,4.8,4.7L412.7,87.6z"
                  />
                  <path
                    className="st0"
                    d="M461,79.1c0,1.2-0.1,2.5-0.3,3.7h-30.4c0.9,4.7,3.9,7.1,8.4,7.1c3,0.2,5.8-1.3,7.4-3.8h14
	c-2.9,9.3-11.2,15.2-21.4,15.2c-12.8,0-22.2-9.3-22.2-21.8s9.4-21.8,22.2-21.8C451.9,57.7,461,67.1,461,79.1L461,79.1z M430.6,75
	h16.5c-0.9-3.9-4.5-6.5-8.4-6.3C434.6,68.7,431.8,70.9,430.6,75z"
                  />
                  <path
                    className="st0"
                    d="M507.9,75v25h-14.1v-23c0-4.8-1.8-7.1-5.3-7.1c-4.4,0-6.9,3.1-6.9,8.3v21.9h-14.1V59h9.9l1.9,4.2
	c3.3-3.6,8-5.6,12.9-5.4C501.7,57.8,507.9,64.5,507.9,75z"
                  />
                  <path
                    className="st1"
                    d="M551.5,88v12H514v-3.1c20.3-23.9,23.1-27.8,23.1-32.1c0.2-2.3-1.6-4.4-3.9-4.6c-0.2,0-0.3,0-0.5,0
	c-2.8,0-4.6,1.9-4.6,4.9v1h-13.3v-0.9c0-10.2,6.7-16.9,17.7-16.9c10.7,0,18.1,6.6,18.1,16c0,6.3-2.3,11.2-13.1,23.7L551.5,88z"
                  />
                  <path
                    className="st1"
                    d="M597.3,90.9h-4.5v9h-13v-9h-24.2v-2.5l16.2-38.9h13.4l-12.5,30.1h8.1l2.9-11.1h9.1v11.1h4.6L597.3,90.9z"
                  />
                </svg>
              </div>
              <div className="navbar-bar">
                <button
                  onClick={toggleClose}
                  id="navbar-toggle-close"
                  className="navbar-toggle"
                >
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <path
                        fill="currentColor"
                        d="M9.192 7.778l5.657 5.657a1 1 0 1 1-1.414 1.414L7.778 9.192 2.121 14.85a1 1 0 1 1-1.414-1.414l5.657-5.657L.707 2.121A1 1 0 0 1 2.121.707l5.657 5.657L13.435.707a1 1 0 0 1 1.414 1.414L9.192 7.778z"
                        id="closea"
                      ></path>
                    </defs>
                    <use xlinkHref="#closea" fillRule="evenodd"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Container>
          <div className="navbar-mobile-body">
            <div className="navbar-mobile-col">
              <button className="button-mobile-body-row">
                <a href="/search" className="navbar-body-row">
                  <div className="navbar-row-padding">
                    <div className="navbar-mobile-flex-row">
                      <div className="navbar-mobile-row-icon">
                        <span className="navbar-mobile-row-icon-cnt">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 71.4 72.72"
                          >
                            <defs></defs>
                            <g id="Ebene_2" data-name="Ebene 2">
                              <g id="Ebene_1-2" data-name="Ebene 1">
                                <path
                                  className="cls-1"
                                  d="M52.25,51.44a30.39,30.39,0,1,0-2.19,2.06L69.28,72.72l2.12-2.13ZM3,30.38A27.38,27.38,0,1,1,30.37,57.75,27.41,27.41,0,0,1,3,30.38Z"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="navbar-body-text-cnt">
                        <div className="navbar-body-text-flex">
                          <div className="text-span-flex">
                            <span>Suchen</span>
                          </div>
                        </div>
                      </div>
                      <div className="fEFwefw">
                        <svg
                          version="1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 600 600"
                        >
                          <path
                            d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </button>
            </div>
            <div className="navbar-mobile-col">
              <button className="button-mobile-body-row">
                <a href="/create/start" className="navbar-body-row">
                  <div className="navbar-row-padding">
                    <div className="navbar-mobile-flex-row">
                      <div className="navbar-mobile-row-icon">
                        <span className="navbar-mobile-row-icon-cnt">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 60.75 60.75"
                          >
                            <defs></defs>
                            <g id="Ebene_2" data-name="Ebene 2">
                              <g id="Ebene_1-2" data-name="Ebene 1">
                                <path
                                  className="cls-1"
                                  d="M30.38,3A27.38,27.38,0,1,1,3,30.38,27.41,27.41,0,0,1,30.38,3m0-3A30.38,30.38,0,1,0,60.75,30.38,30.38,30.38,0,0,0,30.38,0Z"
                                />
                                <rect
                                  className="cls-1"
                                  x="12.28"
                                  y="29.3"
                                  width="36.2"
                                  height="2.14"
                                />
                                <rect
                                  className="cls-1"
                                  x="29.3"
                                  y="12.28"
                                  width="2.14"
                                  height="36.2"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="navbar-body-text-cnt">
                        <div className="navbar-body-text-flex">
                          <div className="text-span-flex">
                            <span>Fahrt anbieten</span>
                          </div>
                        </div>
                      </div>
                      <div className="fEFwefw">
                        <svg
                          version="1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 600 600"
                        >
                          <path
                            d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </button>
            </div>
            <div className="navbar-mobile-divider">
              <h2>Hilfreiche Links</h2>
            </div>
            <div className="navbar-mobile-col">
              <button className="button-mobile-body-row">
                <a href="/faq" className="navbar-body-row">
                  <div className="navbar-row-padding">
                    <div className="navbar-mobile-flex-row">
                      <div className="navbar-mobile-row-icon">
                        <span className="navbar-mobile-row-icon-cnt">
                          <svg
                            id="Ebene_1"
                            data-name="Ebene 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 139.39 150.76"
                          >
                            <path
                              d="M1999.13,2075.74a15.32,15.32,0,0,1-15.37-14.82l-.06-1.85a83.36,83.36,0,0,0-14.16-43.28,35.83,35.83,0,0,1-5.84-24.2,36.36,36.36,0,0,1,31.89-31.89,36.25,36.25,0,0,1,33.9,56.72,82.92,82.92,0,0,0-15,44.5A15.31,15.31,0,0,1,1999.13,2075.74Zm.64-112.08a33.4,33.4,0,0,0-3.72.21,32.11,32.11,0,0,0-28.18,28.19,31.73,31.73,0,0,0,5.15,21.4,87.51,87.51,0,0,1,14.87,45.46l.06,1.85a11.18,11.18,0,0,0,22.35,0,87,87,0,0,1,15.76-46.75,32.06,32.06,0,0,0-26.29-50.36Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M1999.2,2049.12a32.31,32.31,0,0,1-15.06-3.52,2.09,2.09,0,0,1,2.07-3.64c.5.28,12,6.59,26.52-.08a2.1,2.1,0,1,1,1.75,3.81A36.34,36.34,0,0,1,1999.2,2049.12Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M1999.73,1949a2.1,2.1,0,0,1-2.1-2.1v-19.78a2.1,2.1,0,1,1,4.19,0v19.78A2.1,2.1,0,0,1,1999.73,1949Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M1968.32,1960.39a2.12,2.12,0,0,1-1.61-.75L1954,1944.49a2.1,2.1,0,1,1,3.21-2.7l12.71,15.15a2.09,2.09,0,0,1-1.6,3.45Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M1951.61,1989.33l-.37,0-19.47-3.43a2.1,2.1,0,1,1,.72-4.13l19.48,3.43a2.1,2.1,0,0,1-.36,4.16Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M1940.28,2032.14a2.1,2.1,0,0,1-1-3.91l17.13-9.89a2.1,2.1,0,1,1,2.1,3.63l-17.13,9.89A2.09,2.09,0,0,1,1940.28,2032.14Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M2059.17,2032.14a2.09,2.09,0,0,1-1.05-.28L2041,2022a2.09,2.09,0,1,1,2.09-3.63l17.13,9.89a2.09,2.09,0,0,1-1,3.91Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M2047.84,1989.33a2.1,2.1,0,0,1-.36-4.16l19.48-3.43a2.1,2.1,0,0,1,.73,4.13l-19.48,3.43A2.22,2.22,0,0,1,2047.84,1989.33Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                            <path
                              d="M2031.13,1960.39a2.07,2.07,0,0,1-1.34-.49,2.11,2.11,0,0,1-.26-3l12.71-15.15a2.1,2.1,0,1,1,3.21,2.7l-12.71,15.15A2.1,2.1,0,0,1,2031.13,1960.39Z"
                              transform="translate(-1930.03 -1924.98)"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="navbar-body-text-cnt">
                        <div className="navbar-body-text-flex">
                          <div className="text-span-flex">
                            <span>FAQ</span>
                          </div>
                        </div>
                      </div>
                      <div className="fEFwefw">
                        <svg
                          version="1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 600 600"
                        >
                          <path
                            d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </button>
            </div>
            <div className="navbar-mobile-col">
              <button className="button-mobile-body-row">
                <a href="/contact" className="navbar-body-row">
                  <div className="navbar-row-padding">
                    <div className="navbar-mobile-flex-row">
                      <div className="navbar-mobile-row-icon">
                        <span className="navbar-mobile-row-icon-cnt">
                          <svg
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M21.9632 18.7211C21.9143 18.7214 21.8659 18.7119 21.8206 18.6933C21.7754 18.6747 21.7343 18.6473 21.6997 18.6128C21.665 18.5782 21.6376 18.5371 21.6188 18.4918C21.6001 18.4466 21.5904 18.3981 21.5904 18.349V9.36056C21.5904 7.07582 20.6854 4.88465 19.0746 3.26909C17.4638 1.65354 15.2791 0.745925 13.0011 0.745925C10.723 0.745925 8.53828 1.65354 6.92747 3.26909C5.31665 4.88465 4.4117 7.07582 4.4117 9.36056V18.349C4.40519 18.4435 4.36319 18.532 4.29416 18.5966C4.22514 18.6612 4.13425 18.6971 4.03984 18.6971C3.94543 18.6971 3.85453 18.6612 3.78551 18.5966C3.71649 18.532 3.67448 18.4435 3.66797 18.349V9.36056C3.66797 6.87798 4.65127 4.49709 6.40157 2.74164C8.15186 0.986199 10.5258 0 13.0011 0C15.4763 0 17.8502 0.986199 19.6005 2.74164C21.3508 4.49709 22.3341 6.87798 22.3341 9.36056V18.349C22.3344 18.398 22.3249 18.4465 22.3064 18.4917C22.2878 18.537 22.2605 18.5781 22.226 18.6127C22.1915 18.6473 22.1505 18.6747 22.1054 18.6933C22.0603 18.7119 22.0119 18.7214 21.9632 18.7211Z" />
                            <path d="M4.03997 18.7212H1.44133C1.25152 18.7209 1.06362 18.6831 0.888394 18.61C0.713167 18.5368 0.554054 18.4297 0.420169 18.2947C0.286283 18.1598 0.180255 17.9997 0.108158 17.8236C0.0360602 17.6475 -0.000690717 17.4588 9.82974e-06 17.2685V12.4814C0.000948807 12.0984 0.15316 11.7315 0.423307 11.4609C0.693455 11.1903 1.05952 11.0381 1.44133 11.0376H4.03997C4.13852 11.0381 4.23288 11.0777 4.3024 11.1477C4.37192 11.2178 4.41095 11.3126 4.41095 11.4115V18.3544C4.40955 18.4522 4.36984 18.5455 4.30042 18.6141C4.231 18.6827 4.13744 18.7212 4.03997 18.7212ZM1.44133 11.7782C1.25646 11.7787 1.0793 11.8525 0.948581 11.9836C0.81786 12.1147 0.744214 12.2924 0.743745 12.4778V17.2685C0.742335 17.3613 0.759324 17.4535 0.793727 17.5398C0.82813 17.626 0.879262 17.7045 0.944156 17.7707C1.00905 17.837 1.08641 17.8896 1.17176 17.9257C1.25711 17.9617 1.34873 17.9804 1.44133 17.9806H3.66721V11.7817L1.44133 11.7782Z" />
                            <path d="M24.5595 18.7211H21.9626C21.9137 18.7214 21.8653 18.7119 21.8201 18.6933C21.7749 18.6747 21.7338 18.6474 21.6991 18.6128C21.6645 18.5782 21.637 18.5371 21.6183 18.4919C21.5995 18.4466 21.5898 18.3981 21.5898 18.3491V11.4061C21.5898 11.3069 21.6291 11.2118 21.699 11.1417C21.7689 11.0716 21.8637 11.0322 21.9626 11.0322H24.5595C24.9419 11.0327 25.3085 11.1854 25.5787 11.4568C25.849 11.7282 26.0008 12.096 26.0008 12.4796V17.2684C26.0015 17.4588 25.9647 17.6474 25.8926 17.8235C25.8205 17.9996 25.7145 18.1598 25.5806 18.2947C25.4467 18.4296 25.2876 18.5368 25.1124 18.6099C24.9372 18.6831 24.7493 18.7209 24.5595 18.7211ZM22.3336 17.9805H24.5595C24.6521 17.9806 24.7439 17.9621 24.8293 17.9261C24.9148 17.8902 24.9922 17.8375 25.0572 17.7712C25.1221 17.7049 25.1732 17.6263 25.2076 17.54C25.2419 17.4537 25.2587 17.3614 25.257 17.2684V12.4814C25.257 12.3895 25.239 12.2985 25.2039 12.2136C25.1689 12.1287 25.1175 12.0516 25.0527 11.9866C24.988 11.9217 24.9111 11.8701 24.8264 11.835C24.7418 11.7998 24.6511 11.7817 24.5595 11.7817H22.3336V17.9805Z" />
                            <path d="M11.6809 26.0007C11.3399 26.0011 11.0064 25.8999 10.7227 25.7101C10.439 25.5203 10.2178 25.2504 10.0871 24.9344C9.95643 24.6185 9.92215 24.2708 9.9886 23.9353C10.055 23.5998 10.2192 23.2916 10.4604 23.0498C10.7015 22.8079 11.0088 22.6432 11.3433 22.5766C11.6778 22.5099 12.0245 22.5443 12.3395 22.6754C12.6545 22.8064 12.9237 23.0283 13.1129 23.3128C13.3022 23.5974 13.403 23.9318 13.4027 24.2739C13.4022 24.7317 13.2206 25.1707 12.8978 25.4944C12.5751 25.8181 12.1374 26.0002 11.6809 26.0007ZM11.6809 23.2929C11.4875 23.2929 11.2984 23.3505 11.1375 23.4583C10.9767 23.566 10.8513 23.7192 10.7773 23.8985C10.7033 24.0777 10.6839 24.2749 10.7216 24.4652C10.7594 24.6555 10.8525 24.8303 10.9893 24.9675C11.1261 25.1047 11.3004 25.1981 11.4901 25.2359C11.6798 25.2738 11.8765 25.2544 12.0552 25.1801C12.2339 25.1059 12.3866 24.9801 12.4941 24.8188C12.6016 24.6575 12.6589 24.4679 12.6589 24.2739C12.6589 24.0137 12.5559 23.7642 12.3725 23.5802C12.189 23.3963 11.9403 23.2929 11.6809 23.2929Z" />
                            <path d="M10.329 24.6456H9.94028C8.27576 24.6437 6.67995 23.9797 5.50296 22.7992C4.32596 21.6188 3.6639 20.0183 3.66202 18.3489C3.6585 18.2977 3.6655 18.2464 3.68259 18.1981C3.69968 18.1498 3.7265 18.1055 3.76137 18.068C3.79625 18.0305 3.83844 18.0006 3.88532 17.9802C3.9322 17.9598 3.98277 17.9492 4.03389 17.9492C4.085 17.9492 4.13558 17.9598 4.18246 17.9802C4.22934 18.0006 4.27153 18.0305 4.3064 18.068C4.34128 18.1055 4.3681 18.1498 4.38519 18.1981C4.40228 18.2464 4.40928 18.2977 4.40576 18.3489C4.40763 19.8204 4.99134 21.2312 6.02886 22.2718C7.06638 23.3124 8.47301 23.8978 9.94028 23.8997H10.3237C10.3747 23.8961 10.4259 23.9032 10.474 23.9203C10.5222 23.9374 10.5664 23.9643 10.6037 23.9993C10.6411 24.0343 10.6709 24.0766 10.6913 24.1236C10.7116 24.1707 10.7222 24.2214 10.7222 24.2726C10.7222 24.3239 10.7116 24.3746 10.6913 24.4216C10.6709 24.4687 10.6411 24.511 10.6037 24.546C10.5664 24.5809 10.5222 24.6078 10.474 24.625C10.4259 24.6421 10.3747 24.6491 10.3237 24.6456H10.329Z" />
                          </svg>
                        </span>
                      </div>
                      <div className="navbar-body-text-cnt">
                        <div className="navbar-body-text-flex">
                          <div className="text-span-flex">
                            <span>Kontakt</span>
                          </div>
                        </div>
                      </div>
                      <div className="fEFwefw">
                        <svg
                          version="1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 600 600"
                        >
                          <path
                            d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </button>
            </div>
            {auth ? (
              <>
                <div className="navbar-mobile-divider">
                  <h2>Konto</h2>
                </div>
                <div className="navbar-mobile-col">
                  <button className="button-mobile-body-row">
                    <a href="/dashboard" className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 140.2 139.6"
                              >
                                <g id="Ebene_2" data-name="Ebene 2">
                                  <g id="Ńëîé_1" data-name="Ńëîé 1">
                                    <path d="M27.58,139.6h-.09a2.11,2.11,0,0,1-2-2.18L27,101.06a2.1,2.1,0,0,1,4.19.17l-1.5,36.36A2.09,2.09,0,0,1,27.58,139.6Z" />
                                    <path d="M112.62,139.6a2.11,2.11,0,0,1-2.09-2L109,101.23a2.1,2.1,0,1,1,4.19-.17l1.49,36.36a2.1,2.1,0,0,1-2,2.18Z" />
                                    <path d="M127.06,139.6H13.14A13.13,13.13,0,0,1,.08,125.05L4.34,85.51a21,21,0,0,1,20.83-18H59a2.09,2.09,0,0,1,1.56.7,2.06,2.06,0,0,1,.52,1.63l-.32,2.81a11.92,11.92,0,0,0,2.11,7.57,9.46,9.46,0,0,0,14.44,0,11.92,11.92,0,0,0,2.11-7.57l-.31-2.81a2.08,2.08,0,0,1,2.08-2.33H115a20.92,20.92,0,0,1,20.81,17.9l4.28,39.63a13.14,13.14,0,0,1-13.07,14.55ZM25.17,71.71A16.77,16.77,0,0,0,8.5,86.05L4.24,125.5a8.95,8.95,0,0,0,8.9,9.9H127.06a9,9,0,0,0,8.9-9.91L131.7,86A16.75,16.75,0,0,0,115,71.71H83.52l0,.48a15.88,15.88,0,0,1-3.08,10.75,13.63,13.63,0,0,1-20.83,0,15.94,15.94,0,0,1-3.09-10.75l.06-.48Z" />
                                    <path d="M70.07,87.76A13.6,13.6,0,0,1,56.53,72.61l1.62-14.38a2.1,2.1,0,0,1,.92-1.5,2.14,2.14,0,0,1,1.74-.28,23.81,23.81,0,0,0,6.54.93h5.44a23.81,23.81,0,0,0,6.54-.93,2.1,2.1,0,0,1,1.74.28,2.07,2.07,0,0,1,.92,1.5l1.63,14.38A13.62,13.62,0,0,1,70.07,87.76Zm-8-26.7-1.36,12a9.44,9.44,0,1,0,18.76,0h0l-1.36-12a28,28,0,0,1-5.3.52H67.35A28.08,28.08,0,0,1,62.05,61.06Z" />
                                    <path d="M72.79,61.58H67.35A28.14,28.14,0,0,1,39.28,33.51V23.62A23.65,23.65,0,0,1,62.91,0H77.24a23.65,23.65,0,0,1,23.62,23.62v9.89A28.14,28.14,0,0,1,72.79,61.58ZM62.91,4.19A19.45,19.45,0,0,0,43.48,23.62v9.89A23.94,23.94,0,0,0,67.35,57.38h5.44A23.95,23.95,0,0,0,96.67,33.51V23.62A19.46,19.46,0,0,0,77.24,4.19Z" />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Übersicht</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>
                </div>
                <div className="navbar-mobile-col">
                  <button className="button-mobile-body-row">
                    <a href="/user/rides" className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 140.2 139.6"
                              >
                                <g id="Ebene_2" data-name="Ebene 2">
                                  <g id="Ńëîé_1" data-name="Ńëîé 1">
                                    <path d="M27.58,139.6h-.09a2.11,2.11,0,0,1-2-2.18L27,101.06a2.1,2.1,0,0,1,4.19.17l-1.5,36.36A2.09,2.09,0,0,1,27.58,139.6Z" />
                                    <path d="M112.62,139.6a2.11,2.11,0,0,1-2.09-2L109,101.23a2.1,2.1,0,1,1,4.19-.17l1.49,36.36a2.1,2.1,0,0,1-2,2.18Z" />
                                    <path d="M127.06,139.6H13.14A13.13,13.13,0,0,1,.08,125.05L4.34,85.51a21,21,0,0,1,20.83-18H59a2.09,2.09,0,0,1,1.56.7,2.06,2.06,0,0,1,.52,1.63l-.32,2.81a11.92,11.92,0,0,0,2.11,7.57,9.46,9.46,0,0,0,14.44,0,11.92,11.92,0,0,0,2.11-7.57l-.31-2.81a2.08,2.08,0,0,1,2.08-2.33H115a20.92,20.92,0,0,1,20.81,17.9l4.28,39.63a13.14,13.14,0,0,1-13.07,14.55ZM25.17,71.71A16.77,16.77,0,0,0,8.5,86.05L4.24,125.5a8.95,8.95,0,0,0,8.9,9.9H127.06a9,9,0,0,0,8.9-9.91L131.7,86A16.75,16.75,0,0,0,115,71.71H83.52l0,.48a15.88,15.88,0,0,1-3.08,10.75,13.63,13.63,0,0,1-20.83,0,15.94,15.94,0,0,1-3.09-10.75l.06-.48Z" />
                                    <path d="M70.07,87.76A13.6,13.6,0,0,1,56.53,72.61l1.62-14.38a2.1,2.1,0,0,1,.92-1.5,2.14,2.14,0,0,1,1.74-.28,23.81,23.81,0,0,0,6.54.93h5.44a23.81,23.81,0,0,0,6.54-.93,2.1,2.1,0,0,1,1.74.28,2.07,2.07,0,0,1,.92,1.5l1.63,14.38A13.62,13.62,0,0,1,70.07,87.76Zm-8-26.7-1.36,12a9.44,9.44,0,1,0,18.76,0h0l-1.36-12a28,28,0,0,1-5.3.52H67.35A28.08,28.08,0,0,1,62.05,61.06Z" />
                                    <path d="M72.79,61.58H67.35A28.14,28.14,0,0,1,39.28,33.51V23.62A23.65,23.65,0,0,1,62.91,0H77.24a23.65,23.65,0,0,1,23.62,23.62v9.89A28.14,28.14,0,0,1,72.79,61.58ZM62.91,4.19A19.45,19.45,0,0,0,43.48,23.62v9.89A23.94,23.94,0,0,0,67.35,57.38h5.44A23.95,23.95,0,0,0,96.67,33.51V23.62A19.46,19.46,0,0,0,77.24,4.19Z" />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Deine Fahrten</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>
                </div>
                <div className="navbar-mobile-col">
                  <button className="button-mobile-body-row">
                    <a href="/user/notifications" className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 140.2 139.6"
                              >
                                <g id="Ebene_2" data-name="Ebene 2">
                                  <g id="Ńëîé_1" data-name="Ńëîé 1">
                                    <path d="M27.58,139.6h-.09a2.11,2.11,0,0,1-2-2.18L27,101.06a2.1,2.1,0,0,1,4.19.17l-1.5,36.36A2.09,2.09,0,0,1,27.58,139.6Z" />
                                    <path d="M112.62,139.6a2.11,2.11,0,0,1-2.09-2L109,101.23a2.1,2.1,0,1,1,4.19-.17l1.49,36.36a2.1,2.1,0,0,1-2,2.18Z" />
                                    <path d="M127.06,139.6H13.14A13.13,13.13,0,0,1,.08,125.05L4.34,85.51a21,21,0,0,1,20.83-18H59a2.09,2.09,0,0,1,1.56.7,2.06,2.06,0,0,1,.52,1.63l-.32,2.81a11.92,11.92,0,0,0,2.11,7.57,9.46,9.46,0,0,0,14.44,0,11.92,11.92,0,0,0,2.11-7.57l-.31-2.81a2.08,2.08,0,0,1,2.08-2.33H115a20.92,20.92,0,0,1,20.81,17.9l4.28,39.63a13.14,13.14,0,0,1-13.07,14.55ZM25.17,71.71A16.77,16.77,0,0,0,8.5,86.05L4.24,125.5a8.95,8.95,0,0,0,8.9,9.9H127.06a9,9,0,0,0,8.9-9.91L131.7,86A16.75,16.75,0,0,0,115,71.71H83.52l0,.48a15.88,15.88,0,0,1-3.08,10.75,13.63,13.63,0,0,1-20.83,0,15.94,15.94,0,0,1-3.09-10.75l.06-.48Z" />
                                    <path d="M70.07,87.76A13.6,13.6,0,0,1,56.53,72.61l1.62-14.38a2.1,2.1,0,0,1,.92-1.5,2.14,2.14,0,0,1,1.74-.28,23.81,23.81,0,0,0,6.54.93h5.44a23.81,23.81,0,0,0,6.54-.93,2.1,2.1,0,0,1,1.74.28,2.07,2.07,0,0,1,.92,1.5l1.63,14.38A13.62,13.62,0,0,1,70.07,87.76Zm-8-26.7-1.36,12a9.44,9.44,0,1,0,18.76,0h0l-1.36-12a28,28,0,0,1-5.3.52H67.35A28.08,28.08,0,0,1,62.05,61.06Z" />
                                    <path d="M72.79,61.58H67.35A28.14,28.14,0,0,1,39.28,33.51V23.62A23.65,23.65,0,0,1,62.91,0H77.24a23.65,23.65,0,0,1,23.62,23.62v9.89A28.14,28.14,0,0,1,72.79,61.58ZM62.91,4.19A19.45,19.45,0,0,0,43.48,23.62v9.89A23.94,23.94,0,0,0,67.35,57.38h5.44A23.95,23.95,0,0,0,96.67,33.51V23.62A19.46,19.46,0,0,0,77.24,4.19Z" />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Benachrichtigungen</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>
                </div>
                <div className="navbar-mobile-col">
                  <button className="button-mobile-body-row">
                    <a href="/user/notes" className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 140.2 139.6"
                              >
                                <g id="Ebene_2" data-name="Ebene 2">
                                  <g id="Ńëîé_1" data-name="Ńëîé 1">
                                    <path d="M27.58,139.6h-.09a2.11,2.11,0,0,1-2-2.18L27,101.06a2.1,2.1,0,0,1,4.19.17l-1.5,36.36A2.09,2.09,0,0,1,27.58,139.6Z" />
                                    <path d="M112.62,139.6a2.11,2.11,0,0,1-2.09-2L109,101.23a2.1,2.1,0,1,1,4.19-.17l1.49,36.36a2.1,2.1,0,0,1-2,2.18Z" />
                                    <path d="M127.06,139.6H13.14A13.13,13.13,0,0,1,.08,125.05L4.34,85.51a21,21,0,0,1,20.83-18H59a2.09,2.09,0,0,1,1.56.7,2.06,2.06,0,0,1,.52,1.63l-.32,2.81a11.92,11.92,0,0,0,2.11,7.57,9.46,9.46,0,0,0,14.44,0,11.92,11.92,0,0,0,2.11-7.57l-.31-2.81a2.08,2.08,0,0,1,2.08-2.33H115a20.92,20.92,0,0,1,20.81,17.9l4.28,39.63a13.14,13.14,0,0,1-13.07,14.55ZM25.17,71.71A16.77,16.77,0,0,0,8.5,86.05L4.24,125.5a8.95,8.95,0,0,0,8.9,9.9H127.06a9,9,0,0,0,8.9-9.91L131.7,86A16.75,16.75,0,0,0,115,71.71H83.52l0,.48a15.88,15.88,0,0,1-3.08,10.75,13.63,13.63,0,0,1-20.83,0,15.94,15.94,0,0,1-3.09-10.75l.06-.48Z" />
                                    <path d="M70.07,87.76A13.6,13.6,0,0,1,56.53,72.61l1.62-14.38a2.1,2.1,0,0,1,.92-1.5,2.14,2.14,0,0,1,1.74-.28,23.81,23.81,0,0,0,6.54.93h5.44a23.81,23.81,0,0,0,6.54-.93,2.1,2.1,0,0,1,1.74.28,2.07,2.07,0,0,1,.92,1.5l1.63,14.38A13.62,13.62,0,0,1,70.07,87.76Zm-8-26.7-1.36,12a9.44,9.44,0,1,0,18.76,0h0l-1.36-12a28,28,0,0,1-5.3.52H67.35A28.08,28.08,0,0,1,62.05,61.06Z" />
                                    <path d="M72.79,61.58H67.35A28.14,28.14,0,0,1,39.28,33.51V23.62A23.65,23.65,0,0,1,62.91,0H77.24a23.65,23.65,0,0,1,23.62,23.62v9.89A28.14,28.14,0,0,1,72.79,61.58ZM62.91,4.19A19.45,19.45,0,0,0,43.48,23.62v9.89A23.94,23.94,0,0,0,67.35,57.38h5.44A23.95,23.95,0,0,0,96.67,33.51V23.62A19.46,19.46,0,0,0,77.24,4.19Z" />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Merkzettel</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>
                </div>
                <div className="navbar-mobile-col">
                  <button className="button-mobile-body-row">
                    <a href="/settings" className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                id="Ebene_1"
                                data-name="Ebene 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 139.65 139.65"
                              >
                                <defs></defs>
                                <path
                                  className="cls-1"
                                  d="M1999.73,2037.27a36.91,36.91,0,1,1,36.91-36.91A36.94,36.94,0,0,1,1999.73,2037.27Zm0-69.62a32.72,32.72,0,1,0,32.71,32.71A32.75,32.75,0,0,0,1999.73,1967.65Z"
                                  transform="translate(-1929.9 -1930.54)"
                                />
                                <path
                                  className="cls-1"
                                  d="M2003.73,2070.19h-8a10.25,10.25,0,0,1-10.24-10.24v-7a54.2,54.2,0,0,1-12.88-5.33l-4.95,5a10.25,10.25,0,0,1-14.48,0l-5.66-5.66a10.25,10.25,0,0,1,0-14.48l5-5a54.2,54.2,0,0,1-5.33-12.88h-7a10.25,10.25,0,0,1-10.24-10.24v-8a10.24,10.24,0,0,1,10.24-10.23h7a54.2,54.2,0,0,1,5.33-12.88l-5-5a10.25,10.25,0,0,1,0-14.48l5.66-5.66a10.25,10.25,0,0,1,14.48,0l4.95,4.95a54.2,54.2,0,0,1,12.88-5.33v-7a10.25,10.25,0,0,1,10.24-10.24h8a10.25,10.25,0,0,1,10.24,10.24v7a54.3,54.3,0,0,1,12.87,5.33l5-4.95a10.24,10.24,0,0,1,14.48,0l5.66,5.66a10.25,10.25,0,0,1,0,14.48l-4.95,5a54.2,54.2,0,0,1,5.33,12.88h7a10.24,10.24,0,0,1,10.24,10.23v8a10.25,10.25,0,0,1-10.24,10.24h-7a54.2,54.2,0,0,1-5.33,12.88l4.95,5a10.25,10.25,0,0,1,0,14.48l-5.66,5.66a10.24,10.24,0,0,1-14.48,0l-5-5A54.3,54.3,0,0,1,2014,2053v7A10.25,10.25,0,0,1,2003.73,2070.19Zm-31.45-27.31a2.14,2.14,0,0,1,1.1.31,50,50,0,0,0,14.69,6.09,2.09,2.09,0,0,1,1.61,2V2060a6.05,6.05,0,0,0,6,6.05h8a6,6,0,0,0,6-6.05v-8.63a2.09,2.09,0,0,1,1.61-2,50,50,0,0,0,14.66-6.07l.1,0a2.1,2.1,0,0,1,2.65.47l6,6a6,6,0,0,0,8.55,0L2049,2044a6.08,6.08,0,0,0,0-8.56l-6-6a2.08,2.08,0,0,1-.52-2.54.51.51,0,0,1,.09-.16h0a50,50,0,0,0,6.08-14.7,2.1,2.1,0,0,1,2-1.61h8.63a6.05,6.05,0,0,0,6.05-6.05v-8a6,6,0,0,0-6.05-6h-8.63a2.1,2.1,0,0,1-2-1.61,50,50,0,0,0-6.08-14.69,2.1,2.1,0,0,1,.3-2.58l6.11-6.11a6.07,6.07,0,0,0,0-8.55l-5.66-5.66a6,6,0,0,0-8.55,0l-6.11,6.11a2.1,2.1,0,0,1-2.58.3,50,50,0,0,0-14.69-6.08,2.1,2.1,0,0,1-1.61-2v-8.63a6,6,0,0,0-6-6h-8a6.05,6.05,0,0,0-6,6v8.63a2.1,2.1,0,0,1-1.61,2,50,50,0,0,0-14.69,6.08,2.09,2.09,0,0,1-2.58-.3l-6.1-6.11a6.06,6.06,0,0,0-8.55,0l-5.66,5.65a6,6,0,0,0,0,8.55l6.1,6.11a2.09,2.09,0,0,1,.3,2.58,50.22,50.22,0,0,0-6.08,14.69,2.09,2.09,0,0,1-2,1.61h-8.63a6,6,0,0,0-6.05,6v8a6.05,6.05,0,0,0,6.05,6.05h8.63a2.09,2.09,0,0,1,2,1.61,50,50,0,0,0,6.09,14.7,2.1,2.1,0,0,1-.43,2.7l-6,6a6,6,0,0,0,0,8.55l5.66,5.65a6,6,0,0,0,8.55,0l6-6A2.11,2.11,0,0,1,1972.28,2042.88Z"
                                  transform="translate(-1929.9 -1930.54)"
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Einstellungen</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>
                </div>
                <div className="navbar-mobile-col">
                  <button
                    className="button-mobile-body-row"
                    onClick={() => {
                      logout(() => {
                        navigate("/");
                      });
                    }}
                  >
                    <div className="navbar-body-row">
                      <div className="navbar-row-padding">
                        <div className="navbar-mobile-flex-row">
                          <div className="navbar-mobile-row-icon">
                            <span className="navbar-mobile-row-icon-cnt">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 150.6 143.82"
                              >
                                <g id="Ebene_2" data-name="Ebene 2">
                                  <g id="Ńëîé_1" data-name="Ńëîé 1">
                                    <path d="M143.5,0H42.59a7.11,7.11,0,0,0-7.1,7.1V37.15a1.14,1.14,0,0,0,1.14,1.14h1.92a1.14,1.14,0,0,0,1.14-1.14V7.1a2.9,2.9,0,0,1,2.9-2.9H143.5a2.91,2.91,0,0,1,2.9,2.9V136.72a2.92,2.92,0,0,1-2.91,2.91H42.59a2.92,2.92,0,0,1-2.9-2.91V106.48a.94.94,0,0,0-.94-.94H36.43a.94.94,0,0,0-.94.94v30.24a7.11,7.11,0,0,0,7.1,7.1H143.5a7.11,7.11,0,0,0,7.1-7.1V7.1A7.13,7.13,0,0,0,143.5,0Z" />
                                    <path d="M71.82,74.41H24v9.48a1.64,1.64,0,0,1-2.46,1.42l-10.38-6-10.38-6a1.64,1.64,0,0,1,0-2.84l10.38-6,10.38-6A1.64,1.64,0,0,1,24,59.93v9.48H71.82C75,69.41,75,74.41,71.82,74.41Z" />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="navbar-body-text-cnt">
                            <div className="navbar-body-text-flex">
                              <div className="text-span-flex">
                                <span>Logout</span>
                              </div>
                            </div>
                          </div>
                          <div className="fEFwefw">
                            <svg
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 600 600"
                            >
                              <path
                                d="M190.3 79c-10.5 5.3-15.6 13.5-15.6 25 0 12.7-4.7 7.4 94.1 106.2l89.7 89.8-89.7 89.7c-99 99.1-94.1 93.5-94.1 106.5 0 11.2 5.2 19.5 15.7 25.1 5.9 3.1 19.3 3.1 25.1 0 2.3-1.3 46-44.2 105.1-103.5 90.5-90.6 101.4-101.8 103-106.3 2.2-6 2.4-15.1.5-22-1.3-4.8-6.3-9.9-103-106.8C258.7 120.1 218 80.1 215.5 78.8c-2.7-1.4-6.5-2.3-11.5-2.5-6.9-.4-8-.2-13.7 2.7z"
                                fill="currentColor"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </>
            ) : (
              <div className="mobile-button-body">
                <div className="navbar-mobile-col">
                  <LinkContainer to="/login" className="mobile-button-row">
                    <Button variant="primary">Anmelden</Button>
                  </LinkContainer>
                </div>
                <div className="navbar-mobile-col">
                  <LinkContainer to="/register" className="mobile-button-row">
                    <Button variant="secondary">Registrieren</Button>
                  </LinkContainer>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNoBgTest;
