import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Register from "./screens/Register";
import Settings from "./screens/Settings";
import SettingsPersonal from "./screens/SettingsPersonal";
import Create from "./screens/Create";
import WaypointsScreen from "./screens/WaypointsScreen";
import RideDetails from "./screens/RideDetails";
import Offer from "./screens/Offer";
import Search from "./screens/Search";
import Chat from "./screens/Chat";
import Message from "./screens/Message";
import UserRides from "./screens/UserRides";
import Notepad from "./screens/Notepad";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadScreen from "./screens/UploadScreen";
import Rating from "./screens/Rating";
import NotFound from "./screens/NotFound";
import Security from "./screens/Security";
import Faq from "./screens/Faq";
import Contact from "./screens/Contact";
import Privacy from "./screens/Privacy";
import RideDetailsPictures from "./screens/RideDetailsPictures";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import NotPrivateRoute from "./NotPrivateRoute";
import DeleteScreen from "./screens/DeleteScreen";
import RideDetailsParticipants from "./screens/RideDetailsParticipants";
import Notifications from "./screens/Notifications";
import EndRide from "./screens/EndRide";
import AppPayment from "./screens/AppPayment";
import { CreateStart } from "./screens/CreateStart";
import { CreateDestination } from "./screens/CreateDestination";
import { CreateDate } from "./screens/CreateDate";
import { CreateTime } from "./screens/CreateTime";
import { CreateDescription } from "./screens/CreateDescription";
import PaymentMethods from "./screens/PaymentMethods";
import Agb from "./screens/Agb";
import Impress from "./screens/Impress";
import PaymentOverview from "./screens/PaymentOverview";
import PaymentAddress from "./screens/PaymentAddress";
import PaymentResult from "./screens/PaymentResult";
import ConfirmEmail from "./screens/ConfirmEmail";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <BrowserRouter>
      <div className="toast-container">
        <ToastContainer position="bottom-left" />
      </div>
      <CookieConsent location="bottom" buttonText="Verstanden" overlay>
        Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
        Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
        unsere Website zu analysieren.
      </CookieConsent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app-payment" element={<AppPayment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/confirm/:token" element={<ConfirmEmail />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/agb" element={<Agb />} />
        <Route path="/impress" element={<Impress />} />

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/personal" element={<SettingsPersonal />} />
          <Route path="/settings/security" element={<Security />} />
          <Route path="/settings/delete" element={<DeleteScreen />} />
          <Route path="/create" element={<Create />} />
          <Route path="/ride/pictures/:id" element={<RideDetailsPictures />} />
          <Route
            path="/ride/participants/:id"
            element={<RideDetailsParticipants />}
          />
          <Route path="/ride/end/:id" element={<EndRide />} />
          <Route path="/offer/:id" element={<Offer />} />
          <Route
            path="/payment/methods/:offerId"
            element={<PaymentMethods />}
          />
          <Route
            path="/payment/address/:offerId"
            element={<PaymentAddress />}
          />
          <Route
            path="/payment/overview/:offerId"
            element={<PaymentOverview />}
          />
          <Route path="/payment/result/:offerId" element={<PaymentResult />} />
          <Route path="/user/chat" element={<Chat />} />
          <Route path="/user/chat/:id" element={<Message />} />
          <Route path="/upload/:id" element={<UploadScreen />} />
          <Route path="/rating/:id" element={<Rating />} />
          <Route path="/user/rides" element={<UserRides />} />
          <Route path="/user/notifications" element={<Notifications />} />
          <Route path="/user/notes" element={<Notepad />} />
          <Route path="/create/start" element={<CreateStart />} />
          <Route path="/create/destination" element={<CreateDestination />} />
          <Route path="/create/date" element={<CreateDate />} />
          <Route path="/create/time" element={<CreateTime />} />
          <Route path="/create/description" element={<CreateDescription />} />
        </Route>

        <Route path="/create/waypoints" element={<WaypointsScreen />} />
        <Route path="/search" element={<Search />} />
        <Route path="/ride/:id" element={<RideDetails />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
