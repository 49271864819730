import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { forgotPasswordHandler } from "../actions/auth";
import { toast } from "react-toastify";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    if (email) {
      forgotPasswordHandler(email)
        .then(() => {
          toast.success("Passwort reset erfolgreich");
        })
        .catch(() => {
          toast.error("Fehler");
        });
    }
  };
  return (
    <div className="forgot-password-cnt">
      <h1>Passwort zurücksetzen</h1>
      <div className="divider"></div>
      <p>
        Bitte gib deine E-Mail-Adresse ein und wir senden dir einen Link zum
        Zurücksetzen deines Passworts.
      </p>
      <Form onSubmit={submitHandler}>
        <Form.Control
          type="email"
          placeholder="Gib deine E-Mail-Adresse ein"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="primary" type="submit">
          Abschicken
        </Button>
      </Form>
      <Link to={"/login"} style={{ color: "#FF441F" }}>
        zur Anmeldung
      </Link>
    </div>
  );
};

export default ForgotPasswordForm;
