import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import { userRides } from "../actions/ride";
import { getAllChats } from "../actions/msg";
import { userRatings } from "../actions/rating";
import { StarFilled } from "@ant-design/icons";
import moment from "moment";

const Dashboard = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { user } = auth;

  const dispatch = useDispatch();

  const [rides, setRides] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [ratingAverage, setRatingAverage] = useState();

  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  const getChats = useSelector((state) => state.getChats);
  const { chats } = getChats;

  useEffect(() => {
    let numbers = ratings;
    setRatingAverage(
      numbers.reduce((sum, { rating }) => sum + rating, 0) / numbers.length
    );
  }, [ratings]);

  useEffect(() => {
    if (rides) {
      setTotalResults(rides.totalResults);
    }
  }, [rides]);

  useEffect(() => {
    const prev = document.getElementById("prev");
    const next = document.getElementById("next");
    if (rides) {
      if (rides.total) {
        if (page === rides.total) {
          next.disabled = true;
        } else {
          next.disabled = false;
        }
        if (page === 1) {
          prev.disabled = true;
        } else {
          prev.disabled = false;
        }
      }
    }
    // getUserRides()
  }, [page, rides]);

  useEffect(() => {
    LoadRides(auth).then((res) => setRides(res));
    LoadRating(auth).then((res) => setRatings(res));
    dispatch(getAllChats());
  }, [auth, dispatch]);

  return (
    <Layout>
      <div className="leerfahrten-container">
        <Container className="pt-4">
          <Row>
            <Col xs={12} md={4} lg={4}>
              <div className="border-container">
                <div className="profile-tab-top d-flex align-items-center">
                  <div className="profile-tab-top-image">
                    <img src="/images/profile.jpg" alt="PB" />
                  </div>
                  <div className="profile-tab-top-details d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="profile-name">
                        {auth && user && user.name && user.name}
                      </h2>
                      <div className="d-flex align-items-center">
                        <b
                          style={{
                            marginRight: "3px",
                            color: "#777",
                            fontSize: 14,
                          }}
                        >
                          {typeof ratingAverage === "number" &&
                            ratingAverage.toFixed(1)}{" "}
                        </b>
                        <StarFilled
                          style={{ color: "rgb(250, 182, 20)", fontSize: 16 }}
                        />
                        <div
                          style={{
                            marginLeft: "3px",
                            color: "#777",
                            fontSize: 14,
                          }}
                        >
                          ({ratings && ratings.length})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-container">
                <div className="chats-top-bar d-flex align-items-center justify-content-between">
                  <div className="chats-top-bar-number d-flex align-items-center">
                    <img
                      className="mr-2"
                      height="14px"
                      src="/images/email-icon.svg"
                      alt=""
                    />
                    <div className="d-flex align-items-center">
                      <p>Chats </p>
                      <span style={{ fontWeight: "bold" }}>
                        ({chats ? chats.length : "0"})
                      </span>
                    </div>
                  </div>
                  <Link to="/user/chat" className="chats-top-bar-link">
                    alle ansehen
                  </Link>
                </div>
              </div>
              <div className="border-container">
                {!user?.emailAccepted || !user.tel ? (
                  <>
                    <div className="profile-complete-top">
                      <h2 className="container-heading">
                        Profil vervollständigen
                      </h2>
                    </div>
                    <div className="profile-complete-body">
                      {!user?.emailAccepted ? (
                        <div className="profile-complete-item d-flex align-items-center">
                          <img src="/images/complete.svg" alt="" />
                          <Link
                            to="/dashboard"
                            className="profile-complete-text"
                          >
                            Email bestätigen
                          </Link>
                        </div>
                      ) : null}
                      {!user?.tel ? (
                        <div className="profile-complete-item d-flex align-items-center">
                          <img src="/images/complete.svg" alt="" />
                          <Link
                            to="/settings/personal"
                            className="profile-complete-text"
                          >
                            Füge deine Telefonnummer hinzu
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="help-container">
                <h2 className="small-heading">Hilfreiches</h2>
                <div className="divider"></div>
                <div className="help-links-container">
                  <Link
                    to="/settings"
                    className="help-link d-flex align-items-center"
                  >
                    <div className="help-link-img">
                      <img src="/images/settings-icon.svg" alt="" />
                    </div>
                    <div className="help-link-text">
                      <h3>Einstellungen</h3>
                      <p>Bearbeiten Sie Ihr Profil</p>
                    </div>
                  </Link>
                  {/*<Link
                    to="/faq"
                    className="help-link d-flex align-items-center"
                  >
                    <div className="help-link-img">
                      <img src="/images/faq-icon.svg" alt="" />
                    </div>
                    <div className="help-link-text">
                      <h3>FAQ</h3>
                      <p>Häufig gestellte Fragen</p>
                    </div>
                        </Link>*/}
                  <Link
                    to="/contact"
                    className="help-link d-flex align-items-center"
                  >
                    <div className="help-link-img">
                      <img src="/images/support.svg" alt="" />
                    </div>
                    <div className="help-link-text">
                      <h3>Kundensupport</h3>
                      <p>Wir helfen Ihnen gerne weiter</p>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="border-container mb-3">
                <div className="dashboard-container-top d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="container-heading-icon mr-2">
                      <img src="/images/mark.png" alt="" />
                    </div>
                    <h3 className="container-heading">Deine Fahrten</h3>
                  </div>
                  <Link to={"/user/rides"}>Alle ansehen</Link>
                </div>
                <div className="dashboard-container-body">
                  {rides && rides.self && rides.self.length === 0 && (
                    <div className="dashboard-container-empty-body">
                      Du hast noch keine Fahrten erstellt.
                    </div>
                  )}
                  {rides &&
                    rides.self &&
                    rides.self.splice(0, 5).map((ride) => (
                      <div key={ride._id} className="dashboard-body-row">
                        <div className="align-items-center d-flex">
                          <div
                            xs={8}
                            md={6}
                            lg={6}
                            className="route-adress-col"
                          >
                            <div className="route-row d-flex align-items-center">
                              <div className="route-divider d-flex align-items-center">
                                <div className="divider-dot"></div>
                              </div>
                              <div className="route-address">
                                <p>{ride.start.address}</p>
                              </div>
                            </div>
                            <div className="divider-line"></div>
                            <div className="route-row d-flex align-items-center">
                              <div className="route-divider d-flex align-items-center">
                                <div className="divider-dot"></div>
                              </div>
                              <div className="route-address">
                                <p>{ride.destination.address}</p>
                              </div>
                            </div>
                          </div>
                          <div
                            xs={0}
                            md={3}
                            lg={3}
                            className="ml-auto text-right ride-adress-date"
                          >
                            <div className="d-flex align-items-center">
                              <div className="order-date-bold order-date">
                                Datum:
                              </div>
                              <div className="order-date ml-2">
                                {moment(ride.date).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </div>
                          <div
                            xs={4}
                            md={3}
                            lg={3}
                            className="ride-details-btn text-right"
                          >
                            <Button className="dashboard-ride-details-btn">
                              <Link to={`/ride/${ride._id}`}>Details</Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="border-container mb-3">
                <div className="dashboard-container-top d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="container-heading-icon mr-2">
                      <img src="/images/mark.png" alt="" />
                    </div>
                    <h3 className="container-heading">Deine Mitfahrten</h3>
                  </div>
                  <Link to={"/user/rides"}>Alle ansehen</Link>
                </div>
                <div className="dashboard-container-body">
                  {rides &&
                    rides.participant &&
                    rides.participant.length === 0 && (
                      <div className="dashboard-container-empty-body">
                        Du hast noch an keiner Fahrt teilgenommen.
                      </div>
                    )}
                  {rides &&
                    rides.participant &&
                    rides.participant.splice(0, 5).map((ride) => (
                      <div key={ride._id} className="dashboard-body-row">
                        <div className="align-items-center d-flex">
                          <div
                            xs={8}
                            md={6}
                            lg={6}
                            className="route-adress-col"
                          >
                            <div className="route-row d-flex align-items-center">
                              <div className="route-divider d-flex align-items-center">
                                <div className="divider-dot"></div>
                              </div>
                              <div className="route-address">
                                <p>{ride.start.address}</p>
                              </div>
                            </div>
                            <div className="divider-line"></div>
                            <div className="route-row d-flex align-items-center">
                              <div className="route-divider d-flex align-items-center">
                                <div className="divider-dot"></div>
                              </div>
                              <div className="route-address">
                                <p>{ride.destination.address}</p>
                              </div>
                            </div>
                          </div>
                          <div
                            xs={0}
                            md={3}
                            lg={3}
                            className="ml-auto text-right ride-adress-date"
                          >
                            <div className="d-flex align-items-center">
                              <div className="order-date-bold order-date">
                                Datum:
                              </div>
                              <div className="order-date ml-2">
                                {moment(ride.date).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </div>
                          <div
                            xs={4}
                            md={3}
                            lg={3}
                            className="ride-details-btn text-right"
                          >
                            <Button className="dashboard-ride-details-btn">
                              <Link to={`/ride/${ride._id}`}>Details</Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="border-container">
                <div className="dashboard-container-top d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="container-heading-icon mr-2">
                      <img src="/images/feedback-icon.svg" alt="" />
                    </div>
                    <h3 className="container-heading">Deine Bewertungen</h3>
                  </div>
                </div>
                <div className="dashboard-container-body">
                  {ratings.length === 0 && (
                    <div className="dashboard-container-empty-body">
                      Du hast noch keine Bewertungen erhalten.
                    </div>
                  )}
                  {ratings.map((rating) => (
                    <div key={rating._id} className="dashboard-body-row">
                      <div className="d-flex">
                        <div className="rating-img-container">
                          <img src="/images/profile.jpg" alt="" />
                        </div>
                        <div className="rating-text-container">
                          <div className="rating-text-top d-flex align-items-center">
                            <p>{rating.user.name}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <b
                                  style={{
                                    marginRight: "3px",
                                    color: "#777",
                                    fontSize: 14,
                                  }}
                                >
                                  ({rating.rating})
                                </b>
                              </div>
                              <StarFilled
                                style={{
                                  color: "rgb(250, 182, 20)",
                                  fontSize: 14,
                                }}
                              />
                            </div>
                          </div>
                          <div className="rating-text-message">
                            <p>{rating.ratingText}</p>
                          </div>
                        </div>
                        <div className="rating-date-container">
                          <p>{moment(rating.createdAt).fromNow()}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Dashboard;

async function LoadRides(auth) {
  let { data } = await userRides(auth.token);
  return data;
}

async function LoadRating(auth) {
  let { data } = await userRatings(auth.token);
  return data;
}
