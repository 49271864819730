import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoFooter from "../core/NoFooter";
import { Input, Button, Spin, DatePicker } from "antd";
import {
  LoginOutlined,
  LogoutOutlined,
  LoadingOutlined,
  StarFilled,
} from "@ant-design/icons";
import DepartureSidebar from "../components/DepartureSidebar";
import DestinationSidebar from "../components/DestinationSidebar";
import { useSelector } from "react-redux";
import { searchRides } from "../actions/ride";
import {
  addToWishlist,
  getOnlyWishlist,
  readProfile,
  removeFromWishlist,
} from "../actions/auth";
import { toast } from "react-toastify";
import MapComponent from "../components/MapComponent";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import Logo from "../images/Logo";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ResultCol from "../components/ResultCol";
import moment from "moment";
import locale from "antd/es/date-picker/locale/de_DE";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Search = ({ location }) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { auth } = useSelector((state) => ({ ...state }));
  const [start, setStart] = useState({
    address: searchParams.get("start"),
    geometry: {
      lat: searchParams.get("slat"),
      lng: searchParams.get("slng"),
    },
  });
  const [destination, setDestination] = useState({
    address: searchParams.get("destination"),
    geometry: {
      lat: searchParams.get("dlat"),
      lng: searchParams.get("dlng"),
    },
  });
  const [date, setDate] = useState(searchParams.get("date"));
  const [loading, setLoading] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [rides, setRides] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setProfileName();
    setProfileAvatar();
    setProfileEmail();
    setProfileRatings([]);
    setRatingAverage();
  };
  const handleShow = () => setShow(true);

  const [profileName, setProfileName] = useState();
  const [profileAvatar, setProfileAvatar] = useState();
  const [profileEmail, setProfileEmail] = useState();
  const [profileRatings, setProfileRatings] = useState([]);
  const [ratingAverage, setRatingAverage] = useState();
  const [countRides, setCountRides] = useState();
  const [createdAt, setCreatedAt] = useState();

  const loadProfile = async (e) => {
    const user = e.currentTarget.id;
    readProfile(user).then((res) => {
      handleShow();
      setProfileName(res.data.profile.name);
      setProfileAvatar(res.data.profile.avatar);
      setProfileEmail(res.data.profile.email);
      setProfileRatings(res.data.all);
      setCountRides(res.data.count);
      setCreatedAt(res.data.profile.createdAt);
      let numbers = res.data.all;
      setRatingAverage(
        numbers.reduce((sum, { rating }) => sum + rating, 0) / numbers.length
      );
    });
  };

  useEffect(() => {
    if (auth) {
      getOnlyWishlist(auth.token).then((res) => {
        setWishlist(res.data.wishlist);
      });
    }
    loadRides({ start, destination, date });
  }, []);

  const handleAddToWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      addToWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.success("Zum Merkzettel hinzugefügt");
      });
    }
  };

  const handleRemoveFromWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      removeFromWishlist(auth.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.error("Aus Merkzettel entfernt");
      });
    }
  };

  const loadRides = (arg) => {
    setLoading(true);
    console.log(arg);
    searchRides(arg)
      .then((res) => {
        setRides(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRides([]);
        setLoading(false);
      });
  };

  const searchSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (start && destination) {
      loadRides({ start, destination, date });
      navigate(
        `/search?start=${start.address}&destination=${destination.address}&slat=${start.geometry.lat}&slng=${start.geometry.lng}&dlat=${destination.geometry.lat}&dlng=${destination.geometry.lng}&date=${date}`
      );
    }
    closeForm();
    setLoading(false);
  };

  useEffect(() => {
    const departureSide = document.getElementById("departureSidebar");
    departureSide.style.transform = "translate(100%)";
    document.body.style.overflow = "auto";
  }, [start]);

  useEffect(() => {
    const destinationSidebar = document.getElementById("destinationSidebar");
    destinationSidebar.style.transform = "translate(100%)";
    document.body.style.overflow = "auto";
  }, [destination]);

  const showForm = () => {
    const searchForm = document.getElementById("search-main-form-mobile");
    searchForm.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
  };

  const closeForm = () => {
    const searchForm = document.getElementById("search-main-form-mobile");
    searchForm.style.transform = "translateY(-100%)";
    document.body.style.overflow = "auto";
  };

  const openDeparture = () => {
    const departureSide = document.getElementById("departureSidebar");
    const departureInput = document.getElementById("departure-input");
    departureSide.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
    window.scrollTo({ top: 0, behavior: "smooth" });
    departureInput?.focus();
  };

  const openDestination = () => {
    const destinationSidebar = document.getElementById("destinationSidebar");
    const destinationInput = document.getElementById("destination-input");
    destinationSidebar.style.transform = "translate(0%)";
    document.body.style.overflow = "hidden";
    window.scrollTo({ top: 0, behavior: "smooth" });
    destinationInput?.focus();
  };

  const chooseDate = (date, dateString) => {
    localStorage.setItem("search-time", dateString);
    setDate(dateString);
  };
  return (
    <NoFooter>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="profile-tab-top d-flex">
              <div className="profile-tab-top-image">
                <img src={profileAvatar} alt="PB" />
              </div>
              <div
                style={{ flexDirection: "row", marginLeft: "10px" }}
                className="profile-tab-top-details d-flex justify-content-between"
              >
                <div className="d-flex align-items-center">
                  <h2
                    className="profile-name"
                    style={{
                      fontSize: "16px",
                      color: "#363636",
                      marginBottom: "0",
                    }}
                  >
                    {profileName}
                  </h2>
                  <div className="d-flex align-items-start">
                    <b
                      style={{
                        marginRight: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      {typeof ratingAverage === "number" &&
                        ratingAverage.toFixed(1)}{" "}
                    </b>
                    <StarFilled
                      style={{ color: "rgb(250, 182, 20)", fontSize: 16 }}
                    />
                    <div
                      style={{
                        marginLeft: "3px",
                        color: "#777",
                        fontSize: 14,
                      }}
                    >
                      ({profileRatings && profileRatings.length})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border-container mb-3" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Allgemeines</h3>
              </div>
            </div>
            <div className="dashboard-container-body">
              <div className="dashboard-body-row d-flex align-items-center justify-space-between">
                <span className="profile-info-general-text">
                  Abgeschlossene Fahrten:
                </span>
                <span className="profile-info-general-text-right">
                  {countRides}
                </span>
              </div>
              <div className="dashboard-body-row d-flex align-items-center justify-space-between">
                <span className="profile-info-general-text">
                  Registriert seit:
                </span>
                <span className="profile-info-general-text-right">
                  {createdAt && createdAt.split("T")[0]}
                </span>
              </div>
            </div>
          </div>
          <div className="border-container" style={{ boxShadow: "none" }}>
            <div className="dashboard-container-top d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="container-heading">Bewertungen</h3>
              </div>
            </div>
            <div className="dashboard-container-body dashboard-rating-body">
              {profileRatings.length === 0 && (
                <div className="dashboard-container-empty-body">
                  Der User hat noch keine Bewertungen erhalten.
                </div>
              )}
              {profileRatings.map((rating) => (
                <div key={rating._id} className="dashboard-body-row">
                  <div className="d-flex">
                    <div className="rating-img-container">
                      <img src="/images/profile.jpg" alt="" />
                    </div>
                    <div className="rating-text-container">
                      <div className="rating-text-top d-flex align-items-center">
                        <p>{rating.user.name}</p>
                        <div className="d-flex align-items-center">
                          <div>
                            <b
                              style={{
                                marginRight: "3px",
                                color: "#777",
                                fontSize: 14,
                              }}
                            >
                              ({rating?.rating})
                            </b>
                          </div>
                          <StarFilled
                            style={{
                              color: "rgb(250, 182, 20)",
                              fontSize: 14,
                            }}
                          />
                        </div>
                      </div>
                      <div className="rating-text-message">
                        <p>{rating?.ratingText}</p>
                      </div>
                    </div>
                    <div className="rating-date-container">
                      <p>{moment(rating?.createdAt).fromNow()}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DepartureSidebar start={setStart} />
      <DestinationSidebar destination={setDestination} />
      <div id="search-main-form-mobile" className="search-main-form-mobile">
        <Form onSubmit={searchSubmit} className="main-form-mobile-search">
          <Container>
            <div className="navbar-leerfahrten">
              <div className="navbar-leerfahrten-brand">
                <Logo />
              </div>
              <div className="navbar-bar">
                <div
                  onClick={closeForm}
                  id="navbar-toggle-close"
                  className="navbar-toggle"
                >
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <path
                        fill="currentColor"
                        d="M9.192 7.778l5.657 5.657a1 1 0 1 1-1.414 1.414L7.778 9.192 2.121 14.85a1 1 0 1 1-1.414-1.414l5.657-5.657L.707 2.121A1 1 0 0 1 2.121.707l5.657 5.657L13.435.707a1 1 0 0 1 1.414 1.414L9.192 7.778z"
                        id="closea"
                      ></path>
                    </defs>
                    <use xlinkHref="#closea" fillRule="evenodd"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div className="mobile-search-form-body">
              <Row>
                <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                  <Input
                    className="main-form-input"
                    size="large"
                    placeholder="Start"
                    prefix={<LogoutOutlined />}
                    value={start.address}
                    onClick={openDeparture}
                  />
                </Col>
                <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                  <Input
                    className="main-form-input"
                    size="large"
                    placeholder="Ziel"
                    prefix={<LoginOutlined />}
                    value={destination.address}
                    onClick={openDestination}
                  />
                </Col>
                <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                  <DatePicker
                    className="autocompleteInput"
                    locale={locale}
                    mode="date"
                    selected={date}
                    format="DD.MM.YYYY"
                    onChange={chooseDate}
                    timeIntervals={15}
                    placeholder="Wann startest du?"
                    style={{ fontSize: 16 }}
                  />
                </Col>
                <Col className="main-form-input-col" xs={12} md={12} lg={2}>
                  <Button htmlType="submit" size="large">
                    Suchen
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </Form>
      </div>
      <div className="leerfahrten-container">
        <div id="listing-search-container">
          <Container>
            <div className="search-mobile-form-container d-flex align-items-center">
              <div className="search-mobile-container-close">
                <svg
                  viewBox="0 0 11 20"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <path
                      fill="currentColor"
                      d="M2.571 10.355l8.16 8.093a.904.904 0 0 1 0 1.286.922.922 0 0 1-1.296 0L.268 10.643a.904.904 0 0 1 0-1.286L9.435.267a.922.922 0 0 1 1.297 0 .904.904 0 0 1 0 1.285L2.57 9.645a.5.5 0 0 0-.003.707.5.5 0 0 0 .003.003z"
                      id="chevron2a"
                    ></path>
                  </defs>
                  <use xlinkHref="#chevron2a"></use>
                </svg>
              </div>
              <div onClick={showForm} className="search-mobile-container-text">
                <span>
                  {start?.address &&
                    destination?.address &&
                    start.address + " < " + destination.address}
                </span>
              </div>
              <div onClick={showForm} className="search-mobile-container-icon">
                <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.299 11.884l5.609 5.61a1 1 0 0 1-1.414 1.414l-5.701-5.7a1.013 1.013 0 0 1-.06-.067 6.5 6.5 0 1 1 1.567-1.256zM7.5 12a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
            <Form onSubmit={searchSubmit} className="search-main-form">
              <Row>
                <Col xs={12} md={12} lg={3}>
                  <PlacesAutocomplete
                    type="Start"
                    initialValue={start.address}
                    setState={setStart}
                  />
                </Col>
                <Col xs={12} md={12} lg={3}>
                  <PlacesAutocomplete
                    type="Ziel"
                    initialValue={destination.address}
                    setState={setDestination}
                  />
                </Col>
                <Col className="main-form-input-col" xs={12} md={12} lg={3}>
                  <DatePicker
                    className="autocompleteInput"
                    locale={locale}
                    mode="date"
                    selected={date}
                    format="DD.MM.YYYY"
                    onChange={chooseDate}
                    timeIntervals={15}
                    placeholder="Wann startest du?"
                    style={{ fontSize: 16 }}
                  />
                </Col>
                <Col xs={12} md={12} lg={3}>
                  <Button variant="primary" htmlType="submit" size="large">
                    Suchen
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
        <div className="ride-details-body-map-mobile">
          {width < 993 && <MapComponent />}
        </div>
        <div className="listing-container">
          <Container>
            <Row className="result-content-container">
              <Col className="result-container" xs={12} md={12} lg={7}>
                <div>
                  <div>
                    {!start || !destination ? (
                      <b>Gib Start und Ziel an</b>
                    ) : null}
                    {loading && <Spin indicator={antIcon} />}
                    {rides && rides?.length > 0 ? (
                      rides.map((ride) => (
                        <ResultCol
                          ride={ride}
                          rideId={ride.rideId}
                          loadProfile={loadProfile}
                          wishlist={wishlist}
                          handleRemoveFromWishlist={handleRemoveFromWishlist}
                          handleAddToWishlist={handleAddToWishlist}
                        />
                      ))
                    ) : rides?.length === 0 ? (
                      <p>
                        Für diese Route wurden leider keine Fahrten gefunden.
                      </p>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col className="map-container" xs={0} md={12} lg={5}>
                <div id="map">{width > 992 && <MapComponent />}</div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </NoFooter>
  );
};

export default Search;
