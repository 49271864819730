import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createRide } from "../actions/ride";
import NoFooter from "../core/NoFooter";
import moment from "moment";
import { toast } from "react-toastify";

export function CreateDescription() {
  const { auth } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState({
    address: "",
    geometry: { lat: null, lng: null },
  });
  const [destination, setDestination] = useState({
    address: "",
    geometry: { lat: null, lng: null },
  });
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [description, setDescription] = useState("");

  const validateStep = async (step, url) => {
    const before = await getValueFromStorage(step);
    if (!before) {
      return navigate(url);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateStep("create-time", "/create/time");
    const storageStart = getValueFromStorage("create-start");
    const storageDestination = getValueFromStorage("create-destination");
    const storageDate = getValueFromStorage("create-date");
    const storageTime = getValueFromStorage("create-time");
    storageStart && setStart(JSON.parse(storageStart));
    storageDestination && setDestination(JSON.parse(storageDestination));
    storageDate && setDate(Date.parse(storageDate));
    storageTime && setTime(Date.parse(storageTime));
  }, []);

  const submitHandler = () => {
    if (start && destination && date && time) {
      createRide(
        start,
        destination,
        moment(date).format("DD.MM.YYYY"),
        moment(time).format("HH:mm"),
        description,
        auth.token
      )
        .then((res) => {
          localStorage.removeItem("create-start");
          localStorage.removeItem("create-destination");
          localStorage.removeItem("create-date");
          localStorage.removeItem("create-time");
          localStorage.removeItem("create-description");
          toast.success("Ihre Fahrt wurde erstellt.");
          navigate(`/ride/${res.data._id}`);
        })
        .catch(() => toast.error("Fehler beim erstellen Ihrer Fahrt."));
    }
  };

  if (loading) {
    return (
      <div className="create">
        <NoFooter>
          <div className="leerfahrten-container">
            <Container>
              <div className="create-container d-flex"></div>
            </Container>
          </div>
        </NoFooter>
      </div>
    );
  }

  return (
    <div className="create">
      <NoFooter>
        <div className="leerfahrten-container">
          <Container>
            <div className="create-container d-flex">
              <div className="leerfahrten-create-text-container">
                <h1>Gibt es noch zusätzliche Anmerkungen?</h1>
                <p>optional</p>
              </div>
              <div className="leerfahrten-create-image-container"></div>
              <div className="leerfahrten-create-input-container">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows="5"
                  placeholder="Zusätzliche Infos"
                  className="autocompleteInput"
                />
              </div>
            </div>
          </Container>
          <div className="leerfahrten-create-btn-cnt">
            <Container>
              <div className="d-flex justify-content-between">
                <Button onClick={() => navigate(-1)} variant="secondary">
                  zurück
                </Button>

                <Button onClick={submitHandler} type="submit">
                  Fahrt erstellen
                </Button>
              </div>
            </Container>
          </div>
        </div>
      </NoFooter>
    </div>
  );
}

function getValueFromStorage(value) {
  return localStorage.getItem(value);
}
