export const USER_GET_ALL_CHATS_REQUEST = 'USER_GET_ALL_CHATS_REQUEST'
export const USER_GET_ALL_CHATS_SUCCESS = 'USER_GET_ALL_CHATS_SUCCESS'
export const USER_GET_ALL_CHATS_FAIL = 'USER_GET_ALL_CHATS_FAIL'

export const GET_CHAT_BY_ID_REQUEST = 'GET_CHAT_BY_ID_REQUEST'
export const GET_CHAT_BY_ID_SUCCESS = 'GET_CHAT_BY_ID_SUCCESS'
export const GET_CHAT_BY_ID_FAIL = 'GET_CHAT_BY_ID_FAIL'

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL'

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'
