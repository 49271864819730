import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NotPrivateRoute = ({ ...rest }) => {
  const { auth } = useSelector((state) => ({ ...state }))

  return auth && auth.token ? <Navigate to='/dashboard' /> : <Route {...rest} />
}

export default NotPrivateRoute
