import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ ...rest }) => {
  const { auth } = useSelector((state) => ({ ...state }))

  return auth && auth.token ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoute
