import React from "react";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";

const ResultCol = ({
  ride,
  loadProfile,
  wishlist,
  handleRemoveFromWishlist,
  handleAddToWishlist,
  rideId,
}) => {
  return (
    <div key={rideId.toString()} className="result-cell">
      <div className="result-inner">
        <div className="result-inner-container d-flex">
          <div className="d-flex justify-content-between">
            <div>
              <div className="profile-section d-flex">
                <div className="profile-img">
                  <img
                    style={{ height: 40, width: 40, objectFit: "contain" }}
                    src={ride.user.avatar}
                    alt="avatar"
                  />
                </div>
                <div className="profile-details d-flex justify-content-between">
                  <div className="profile-name d-flex">
                    <div className="mr-1 p-name">{ride.user.name}</div>
                    <div>{/*Rating*/}</div>
                  </div>
                  <div
                    onClick={loadProfile}
                    className="d-flex profile-link"
                    style={{ cursor: "pointer" }}
                    id={ride && ride.user && ride.user._id}
                  >
                    <img src="/images/user.svg" alt="avatar" />
                    <span>Profil ansehen</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="like-section">
                {ride && wishlist && wishlist.includes(rideId) ? (
                  <HeartFilled
                    id={rideId}
                    data-key={rideId}
                    onClick={handleRemoveFromWishlist}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      color: "#ff441f",
                    }}
                  />
                ) : (
                  <HeartOutlined
                    id={rideId}
                    onClick={handleAddToWishlist}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      color: "#ff441f",
                    }}
                  />
                )}
                <p>
                  {ride?.date
                    ? moment.unix(ride?.date).format("DD.MM.YYYY HH:mm")
                    : "undefined"}
                </p>
              </div>
            </div>
          </div>
          <div className="result-cell-bottom d-flex justify-content-between align-items-end">
            <div style={{ overflow: "hidden", flex: 1, paddingRight: 10 }}>
              <div className="route-row d-flex align-items-center">
                <div className="route-divider d-flex align-items-center">
                  <div className="divider-dot"></div>
                </div>
                <div className="route-address">
                  <p>{ride.start.address}</p>
                </div>
              </div>
              <div className="divider-line"></div>
              <div className="route-row d-flex align-items-center">
                <div className="route-divider d-flex align-items-center">
                  <div className="divider-dot"></div>
                </div>
                <div className="route-address">
                  <p>{ride.destination.address}</p>
                </div>
              </div>
            </div>
            <div>
              <Link to={`/ride/${rideId}`} className="details-button-section">
                Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCol;
