import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { authReducer } from './reducers/auth'
import {
  userGetChatsReducer,
  getChatByIdReducer,
  getMessagesReducer,
  sendMessageReducer,
} from './reducers/msgReducers'
import { searchReducer } from './reducers/ride'

const reducer = combineReducers({
  auth: authReducer,
  getChats: userGetChatsReducer,
  chatById: getChatByIdReducer,
  messagesFromConversation: getMessagesReducer,
  msgSend: sendMessageReducer,
  search: searchReducer,
})

const middleware = [thunk]

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
