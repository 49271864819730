import axios from "axios";

export const createRating = async (token, arg, id) =>
  await axios.post(`/api/rating/create/${id}`, arg, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const userRatings = async (token) =>
  await axios.get(`/api/user/ratings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
