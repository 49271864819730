import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../core/Layout";

const Impress = () => {
  return (
    <Layout>
      <div className="leerfahrten-container law-container">
        <Container className="pt-4">
          <h1 className="settings-topbar-header">Impressum</h1>
          <span>
            <strong>Leerfahrten 24 GmbH</strong>
          </span>
          <br />
          <span>
            <strong>Glienicker Str. 497,</strong>
          </span>
          <br />
          <span>
            <strong>12526 Berlin</strong>
          </span>
          <br />
          <a href="mailto:kontakt@leerfahrten24.de">kontakt@leerfahrten24.de</a>
          <br />
          <br />
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            <span style={{ display: "block" }}>Geschäftsführung:</span>
            <span style={{ display: "block" }}>Mahmoud Bolhos</span>
          </div>
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            <span style={{ display: "block" }}>Geschäftszeiten:</span>
            <span style={{ display: "block" }}>
              Mo – Fr: 08:00 Uhr bis 19:00 Uhr
            </span>
          </div>
          <br />
          <h3>Rechtshinweis:</h3>
          <span>
            Alle Rechte sind vorbehalten, soweit nicht ausdrücklich etwas
            anderes angegeben wird. Bei Zitaten wird um Quellenangabe gebeten.
          </span>
          <span>
            Alle Inhalte vermitteln lediglich einen unverbindlichen Überblick
            über die den Service von Leerfahrten 24 GmbH. Für die Richtigkeit,
            Vollständigkeit oder Aktualität der Daten wird keine Gewähr
            übernommen. Dies betrifft auch die Hyperlinks. Änderungen oder
            Ergänzungen erfolgen ohne vorherige Ankündigung.
          </span>
          <h3>Verbraucherstreitbeilegungsgesetz:</h3>
          <span>
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die
            unter www.ec.europa.eu/consumers/odr aufrufbar ist. Wir weisen Sie
            darauf hin, dass wir nicht bereit und nicht verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </span>
          <br />
        </Container>
      </div>
    </Layout>
  );
};

export default Impress;
