import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import NoFooter from "../core/NoFooter";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { read, acceptOffer } from "../actions/offer";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../images/Logo";

const Offer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));

  const [start, setStart] = useState();
  const [destination, setDestination] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [description, setDescription] = useState();
  const [date, setDate] = useState();
  const [profileName, setProfileName] = useState();
  const [userId, setUserId] = useState();
  const [isUser, setIsUser] = useState(true);
  const [price, setPrice] = useState();
  const [status, setStatus] = useState();
  const [rideId, setRideId] = useState();

  useEffect(() => {
    const loadOfferDetails = async () => {
      let res = await read(auth.token, id);
      setStart(res.data.ride.start.address);
      setWaypoints(res.data.ride.waypoints);
      setDestination(res.data.ride.destination.address);
      setProfileName(res.data.user.name);
      setPrice(res.data.offer.price);
      setStatus(res.data.offer.status);
      setRideId(res.data.ride._id);
      console.log(res.data);
      if (auth) {
        if (auth.user._id !== res.data.user._id) {
          setIsUser(false);
        }
      }
    };
    loadOfferDetails();
  }, [auth, id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    navigate(`/payment/methods/${id}`);
  };

  const goToRideHandler = async () => {
    navigate(`/ride/${rideId}`);
  };
  return (
    <NoFooter>
      <div className="ride-details-mobile-contact-container">
        <Container>
          {isUser ? (
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="price-header">
                Gesamtsumme: <span className="mwst">inkl. MwSt.</span>
              </h2>
              <h2 className="price-header">{price}€</h2>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="price-header">
                  Gesamtsumme: <span className="mwst">inkl. MwSt.</span>
                </h2>
                <h2 className="price-header">{price}€</h2>
              </div>
              {status && status === "open" ? (
                <>
                  <div className="ride-details-profile-details-contact">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<MessageOutlined />}
                      size="large"
                      onClick={submitHandler}
                    >
                      Angebot annehmen
                    </Button>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="payment-col">
                      <img src="/images/visa.svg"></img>
                    </div>
                    <div className="payment-col">
                      <img src="/images/master.svg"></img>
                    </div>
                    <div className="payment-col">
                      <img src="/images/sepa.svg"></img>
                    </div>
                    <div className="payment-col">
                      <img src="/images/paypal.svg"></img>
                    </div>
                    <div className="payment-col">
                      <img src="/images/amex.svg"></img>
                    </div>
                    <div className="payment-col">
                      <img src="/images/klarna.svg"></img>
                    </div>
                  </div>
                </>
              ) : status === "paid" ? (
                <>
                  <div className="ride-details-profile-details-contact">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<MessageOutlined />}
                      size="large"
                      onClick={goToRideHandler}
                    >
                      Zur Fahrt gehen
                    </Button>
                  </div>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
      <div className="leerfahrten-container">
        <div className="ride-details-mobile-top">
          <Container>
            <div className="d-flex align-items-center justify-content-between">
              <span className="top-id">
                ID: <span className="top-id-bold">{id}</span>
              </span>
            </div>
          </Container>
        </div>
        <div className="ride-details-body-map-mobile">
          <img src="/images/map2.png"></img>
        </div>
        <Container className="pt-4 mobile-margin-container">
          <Row>
            <Col xs={12} md={12} lg={4}>
              <div className="border-container">
                <div className="profile-tab-top d-flex">
                  <div className="profile-tab-top-image">
                    <img src="/images/profile.jpg" alt="PB" />
                  </div>
                  <div className="profile-tab-top-details d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="profile-name">{profileName}</h2>
                      <div>Rating</div>
                    </div>
                    <div>
                      <a href="#">Profil anzeigen</a>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="ride-details-profile-details-body d-flex">
                  <div className="ride-details-profile-details-contact">
                    {isUser ? (
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="price-header">
                          Gesamtsumme: <span className="mwst">inkl. MwSt.</span>
                        </h2>
                        <h2 className="price-header">{price}€</h2>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="price-header">
                            Gesamtsumme:{" "}
                            <span className="mwst">inkl. MwSt.</span>
                          </h2>
                          <h2 className="price-header">{price}€</h2>
                        </div>
                        {status && status === "open" ? (
                          <>
                            <div className="ride-details-profile-details-contact">
                              <Button
                                type="primary"
                                shape="round"
                                icon={<MessageOutlined />}
                                size="large"
                                onClick={submitHandler}
                              >
                                Angebot annehmen
                              </Button>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="payment-col">
                                <img src="/images/visa.svg"></img>
                              </div>
                              <div className="payment-col">
                                <img src="/images/master.svg"></img>
                              </div>
                              <div className="payment-col">
                                <img src="/images/sepa.svg"></img>
                              </div>
                              <div className="payment-col">
                                <img src="/images/paypal.svg"></img>
                              </div>
                              <div className="payment-col">
                                <img src="/images/amex.svg"></img>
                              </div>
                              <div className="payment-col">
                                <img src="/images/klarna.svg"></img>
                              </div>
                            </div>
                          </>
                        ) : status === "paid" ? (
                          <>
                            <div className="ride-details-profile-details-contact">
                              <Button
                                type="primary"
                                shape="round"
                                size="large"
                                onClick={goToRideHandler}
                              >
                                Zur Fahrt gehen
                              </Button>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <div className="border-container ride-details-container">
                <div className="ride-details-top-container flex-column d-flex">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="top-id">
                      ID: <span className="top-id-bold">{id}</span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="ride-details-top-container-heading">
                      Detailansicht
                    </span>
                    <span className="ride-details-top-container-date">
                      18.03.2021
                    </span>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="ride-details-body d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                    <span className="ride-details-top-container-heading">
                      Detailansicht
                    </span>
                    <span className="ride-details-top-container-date">
                      18.03.2021
                    </span>
                  </div>
                  <div className="ride-details-body-map">
                    <img src="/images/map2.png"></img>
                  </div>
                  <div className="ride-details-body-route">
                    <h2 className="ride-details-body-heading">Route:</h2>
                    <div className="route-row d-flex align-items-center">
                      <div className="route-divider d-flex align-items-center">
                        <div className="divider-dot"></div>
                      </div>
                      <div className="route-address">
                        <p>{start}</p>
                      </div>
                    </div>
                    <div className="divider-line"></div>
                    {waypoints?.length > 0 &&
                      waypoints.map((waypoint) => (
                        <>
                          <div className="route-row d-flex align-items-center">
                            <div className="route-divider d-flex align-items-center">
                              <div className="divider-waypoint-dot"></div>
                            </div>
                            <div className="route-address">
                              <p>{waypoint?.address}</p>
                            </div>
                          </div>
                          <div className="divider-line"></div>
                        </>
                      ))}
                    <div className="route-row d-flex align-items-center">
                      <div className="route-divider d-flex align-items-center">
                        <div className="divider-dot"></div>
                      </div>
                      <div className="route-address">
                        <p>{destination}</p>
                      </div>
                    </div>
                  </div>

                  <div className="ride-details-body-description">
                    <h2 className="ride-details-body-heading">Beschreibung:</h2>
                    <span>
                      Die Preisbewertung gibt Aufschluss über das
                      Preis-Leistungs-Verhältnis eines Angebots verglichen mit
                      ähnlichen Fahrzeugmodellen, die innerhalb der letzten 14
                      Monate inseriert wurden. Die Marktpreis-Berechnung basiert
                      auf einem intelligenten Algorithmus, in den über 10 Mio.
                      Datensätze und mehr als 70 Kriterien einfließen. Generell
                      gehen wir bei allen Angeboten von einem guten
                      Fahrzeug-Zustand aus.
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="ride-details-footer">
            <Container className="d-flex align-items-center justify-content-between">
              <div className="ride-details-footer-logo navbar-leerfahrten-brand">
                <Logo />
              </div>
              <div className="ride-details-footer-links d-flex">
                <a href="/impress">Impressum</a>
                <a href="/privacy">Datenschutz</a>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </NoFooter>
  );
};

export default Offer;
