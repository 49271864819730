import React from 'react'
import Layout from '../core/Layout'
import { Container, Button, Toast, Row, Col } from 'react-bootstrap'
import SettingsSidebar from '../components/SettingsSidebar'

const Settings = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <div className='settings-topbar'>
          <Container>
            <h1 className='settings-topbar-header'>Einstellungen</h1>
          </Container>
        </div>
        <Container className='settings-container-mobile'>
          <Row>
            <SettingsSidebar />
            <Col xs={12} md={8} lg={8}></Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Settings
