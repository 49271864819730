import { Rate } from 'antd'
import React, { useState } from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createFeedback } from '../actions/feedback'

const Feedback = () => {
  const [feedbackShow, setFeedbackShow] = useState(false)
  const [feedbackText, setFeedbackText] = useState()
  const [feedbackValue, setFeedbackValue] = useState(0)

  const handleFeedbackClose = () => setFeedbackShow(false)
  const handleFeedbackShow = () => setFeedbackShow(true)

  const handleFeedback = (value) => {
    setFeedbackValue(value)
  }

  const handleSumit = (e) => {
    e.preventDefault()
    if (feedbackValue !== 0) {
      createFeedback(feedbackText, feedbackValue).then((res) => {
        handleFeedbackClose()
        toast.success('Danke für Ihr Feedback!')
      })
    }
  }
  return (
    <Col>
      <button
        onClick={handleFeedbackShow}
        className='footer-btn footer-feedback'>
        Feedback
      </button>
      <Modal centered show={feedbackShow} onHide={handleFeedbackClose}>
        <Form onSubmit={handleSumit}>
          <Modal.Header closeButton>
            <Modal.Title className='feedback-header'>
              Geben Sie eine Bewertung ab!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='feedback-main-container'>
              <h2>Wie finden Sie unsere Webseite?</h2>
              <Rate
                allowHalf={true}
                value={feedbackValue}
                onChange={handleFeedback}
                style={{ fontSize: 34 }}
              />
              <Form.Group controlId='message'>
                <Form.Control
                  as='textarea'
                  rows={5}
                  placeholder='Ihre Nachricht'
                  style={{ resize: 'none' }}
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' style={{ margin: '0 10px', width: '100%' }}>
              Feedback abgeben
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Col>
  )
}

export default Feedback
