import axios from "axios";

export const register = async (user) => await axios.post(`/api/register`, user);

export const login = async (user) => await axios.post(`/api/login`, user);

export const forgotPasswordHandler = async (email) =>
  await axios.put(`/api/forgot-password`, { email });

export const resetPasswordHandler = async (resetPasswordLink, newPassword) =>
  await axios.put(`/api/reset-password`, {
    resetPasswordLink,
    newPassword,
  });

export const readProfile = async (id) =>
  await axios.get(`/api/user/profile/${id}`);

// Wishlist
export const addToWishlist = async (token, rideId) =>
  await axios.post(
    `/api/user/wishlist`,
    { rideId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getWishlist = async (token) =>
  await axios.get(`/api/user/wishlist`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getNotifications = async (token) =>
  await axios.get(`/api/user/notifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkNotification = async (token, notificationId) =>
  await axios.post(
    `/api/user/checkNotification`,
    { notificationId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getOnlyWishlist = async (token) =>
  await axios.get(`/api/user/onlywishlist`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const removeFromWishlist = async (token, rideId) =>
  await axios.put(
    `/api/user/wishlist/${rideId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateUserInfo = async (token, avatar, name, tel) =>
  await axios.post(
    `/api/user/update`,
    { avatar, name, tel },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updatePassword = async (token, oldPassword, newPassword) =>
  await axios.put(
    `/api/user/security/password`,
    { oldPassword, newPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteUser = async (token, reason) =>
  await axios.delete(`/api/user`, {
    data: { reason: reason },
    headers: { Authorization: `Bearer ${token}` },
  });
