import React, { useEffect, useState } from 'react'
import {
  DirectionsRenderer,
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api'
import { useSearchParams } from 'react-router-dom'

const containerStyle = {
  width: '100%',
  height: '100%',
}

const center = {
  lat: 51.1144908550664,
  lng: 11.406808067039664,
}

const MapComponent = ({ route }) => {
  let [searchParams] = useSearchParams()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDfBQ7pHp2Fjxpxrbg-BRSXRnet3Bgxu0w',
  })

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [directionsResponse, setDirectionsResponce] = useState(null)

  useEffect(() => {
    loadRoute()
  }, [searchParams])

  const loadRoute = async () => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: searchParams.get('start'),
      destination: searchParams.get('destination'),
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })

    setDirectionsResponce(results)
  }

  const onLoad = React.useCallback(
    async function callback(map) {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const results = await directionsService.route({
        origin: searchParams.get('start'),
        destination: searchParams.get('destination'),
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })

      setDirectionsResponce(results)
    },
    [searchParams]
  )

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={6}
      options={{
        disableDefaultUI: true,
        draggableCursor: true,
        gestureHandling: true,
        panControl: false,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}>
      <>
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </>
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(MapComponent)
