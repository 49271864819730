import React from 'react'
import PlacesAutocomplete from './PlacesAutocomplete'

const DepartureSidebar = ({ start }) => {
  const closeDeparture = () => {
    const departureSide = document.getElementById('departureSidebar')
    departureSide.style.transform = 'translate(100%)'
    document.body.style.overflow = 'auto'
  }
  return (
    <div id='departureSidebar' className='leerfahrten-departure-sidebar'>
      <div className='departure-sidebar-header d-flex'>
        <div
          onClick={closeDeparture}
          className='departure-header-close d-flex justify-content-center align-items-center'>
          <svg
            viewBox='0 0 11 20'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
              <path
                fill='currentColor'
                d='M2.571 10.355l8.16 8.093a.904.904 0 0 1 0 1.286.922.922 0 0 1-1.296 0L.268 10.643a.904.904 0 0 1 0-1.286L9.435.267a.922.922 0 0 1 1.297 0 .904.904 0 0 1 0 1.285L2.57 9.645a.5.5 0 0 0-.003.707.5.5 0 0 0 .003.003z'
                id='chevron2a'></path>
            </defs>
            <use xlinkHref='#chevron2a'></use>
          </svg>
        </div>
        <div className='departure-header-input'>
          <PlacesAutocomplete type='Start' setState={start} />
        </div>
      </div>
    </div>
  )
}

export default DepartureSidebar
