import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../core/Layout'

const NotFound = () => {
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <Container>
          <h2 className='text-center pt-5'>
            <b>Oops...</b>
          </h2>
          <h1 className='text-center pt-2'>
            <b>404</b>
          </h1>
          <h2 className='text-center pt-2'>
            <b>Page not Found</b>
          </h2>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFound
