import React, { useState, useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import NoFooter from "../core/NoFooter";
import { Rate } from "antd";
import { createRating } from "../actions/rating";
import { useNavigate, useParams } from "react-router-dom";

const Rating = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));
  const [ratingText, setRatingText] = useState();
  const [ratingValue, setRatingValue] = useState(0);
  const [rideId, setRideId] = useState(id);
  const [ratingSet, setRatingSet] = useState(false);
  const [username, setUsername] = useState();

  const handleRating = (value) => {
    setRatingValue(value);
  };

  useEffect(() => {
    if (auth) {
      if (auth.user) {
        if (auth.user.name) {
          setUsername(auth.user.name);
        }
      }
    }
  }, [auth]);

  const closeRating = () => {
    if (rideId !== "undefined" && rideId !== null) {
      navigate(`/ride/${rideId}`);
    }
    if (rideId === "undefined") {
      navigate(`/`);
    }
  };

  const handleSumit = (e) => {
    e.preventDefault();
    if (ratingValue !== 0) {
      createRating(auth.token, { ratingValue, ratingText }, rideId).then(
        (res) => {
          setRatingText(res.data.ratingText);
          setRatingValue(res.data.rating);
          setRatingSet(true);
        }
      );
    }
  };
  return (
    <Container>
      <nav className="navbar-leerfahrten rating-topbar">
        <Button
          onClick={closeRating}
          variant="secondary"
          className="ml-auto close-button"
        >
          x
        </Button>
      </nav>
      <div className="rating-cnt">
        {ratingSet ? (
          <div>
            <h1>Wir haben Ihre Bewertung erhalten!</h1>
            <h3>{username}</h3>
            <Rate value={ratingValue} disabled={true} />
            <h3>{ratingText}</h3>
          </div>
        ) : (
          <Form onSubmit={handleSumit}>
            <h1>Geben Sie eine Bewertung ab!</h1>
            <h3>Wie hat dir die Fahrt gefallen?</h3>
            <Rate
              allowHalf={true}
              value={ratingValue}
              onChange={handleRating}
            />
            <h3>Schreib noch etwas, wenn du möchtest:</h3>
            <Form.Group controlId="message">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Ihre Nachricht"
                style={{ resize: "none" }}
                value={ratingText}
                onChange={(e) => setRatingText(e.target.value)}
              />
            </Form.Group>
            <Button style={{ marginTop: 10 }} type="submit">
              Bewertung abgeben
            </Button>
          </Form>
        )}
      </div>
    </Container>
  );
};

export default Rating;
