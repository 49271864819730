import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentStatus } from "../actions/payment";
import UploadPicturesModal from "../components/UploadPicturesModal";
import NoFooter from "../core/NoFooter";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import { uploadRideFiles } from "../actions/ride";

export default function PaymentResult() {
  const navigate = useNavigate();
  const { offerId } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));

  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [rideId, setRideId] = useState();

  useEffect(() => {
    if (auth && offerId) {
      getPaymentStatus(auth.token, offerId).then((res) => {
        setPayment(res.data);
        setLoading(false);
        setRideId(res.data.ride);
      });
    }
  }, [auth, offerId]);

  const chooseFile = (file) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      70,
      0,
      async (uri) => {
        console.log(uri);
        try {
          setSelectedImages([
            ...selectedImages,
            {
              base64: uri,
              path: URL.createObjectURL(file),
            },
          ]);
        } catch (error) {
          console.log(error);
        }
      },
      "base64"
    );
  };

  const submitHandler = () => {
    if (selectedImages.length > 0) {
      setLoading(true);
      uploadRideFiles(
        auth.token,
        selectedImages,
        rideId,
        "start",
        auth.user._id
      )
        .then((res) => {
          setLoading(false);
          toast.success("Bilder hochgeladen");
          setShowUploadModal(false);
          navigate(`/ride/${rideId}`);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Bilder hochgeladen fehlgeschlagen");
          setShowUploadModal(false);
        });
    }
  };

  if (loading) {
    return (
      <NoFooter>
        <div className="leerfahrten-container">
          <Container>
            <TailSpin
              height="40"
              width="40"
              color="#ff4848"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                justifyContent: "center",
                paddingTop: 100,
              }}
              wrapperClass=""
              visible={true}
            />
          </Container>
        </div>
      </NoFooter>
    );
  }

  return (
    <NoFooter>
      <UploadPicturesModal
        images={selectedImages}
        addImage={chooseFile}
        loading={loading}
        uploadPictures={submitHandler}
        show={showUploadModal}
        setShow={setShowUploadModal}
      />
      <div className="leerfahrten-container">
        <Container>
          <div className="payment-container">
            <div
              className="payment-main"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 75,
              }}
            >
              <div
                style={{
                  backgroundColor:
                    payment?.status === "paid" ? "#62E05F" : "#F95959",
                  height: 80,
                  width: 80,
                  borderRadius: 100,
                  margin: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {payment?.status === "paid" ? (
                  <img
                    style={{ height: 40, margin: "auto" }}
                    src="/images/checkmark.png"
                    alt="check"
                  />
                ) : (
                  <img
                    style={{ height: 40, margin: "auto" }}
                    src="/images/close.png"
                    alt="check"
                  />
                )}
              </div>
              <h2>
                {payment?.status === "paid"
                  ? "Bezahlung erfolgreich!"
                  : payment?.status === "open"
                  ? "Bezahlung ausstehend"
                  : payment?.status === "canceled"
                  ? "Bezahlung abgebrochen"
                  : "Fehler"}
              </h2>
              <p style={{ textAlign: "center" }}>
                {payment?.status === "paid"
                  ? "Wir wünschen Ihnen eine schöne Fahrt."
                  : "Ihre Zahlung wurde abgelehnt. Bitte versuchen Sie eine andere Zahlmethode"}
              </p>
            </div>
            <div className="payment-footer" style={{ flexDirection: "column" }}>
              {payment?.status === "paid" ? (
                <div
                  className="payment-button"
                  style={{ width: "100%", marginBottom: 10 }}
                >
                  <Button
                    style={{ width: "100%" }}
                    type="submit"
                    onClick={() => {
                      setShowUploadModal(true);
                    }}
                  >
                    Fotos hochladen
                  </Button>
                </div>
              ) : null}
              {payment?.status === "paid" ? (
                <p
                  onClick={() => navigate("/")}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Zurück zur Homepage
                </p>
              ) : (
                <p
                  onClick={() => navigate(`/payment/methods/${offerId}`)}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Zurück zu den Zahlmethoden
                </p>
              )}
            </div>
          </div>
        </Container>
      </div>
    </NoFooter>
  );
}
