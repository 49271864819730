import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { read } from "../actions/offer";
import PaymentMethod from "../components/PaymentMethod";
import NoFooter from "../core/NoFooter";

export default function PaymentMethods() {
  const navigate = useNavigate();
  const { offerId } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState();
  const [price, setPrice] = useState();

  const nextStep = () => {
    if (selectedMethod) {
      localStorage.setItem(
        "paymentMethod",
        JSON.stringify(
          paymentMethods.filter((method) => selectedMethod === method.id)
        )
      );
      navigate(`/payment/address/${offerId}`);
    }
  };

  useEffect(() => {
    const loadOfferDetails = async () => {
      let res = await read(auth.token, offerId);
      setPrice(res.data.offer.price);
    };

    const getPaymentMethods = async () => {
      await axios
        .get(`/api/payment/methods`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setPaymentMethods(res.data);
        })
        .catch((err) => {
          console.log("err");
        });
    };
    if (auth.token) {
      getPaymentMethods();
      loadOfferDetails();
    }
  }, [auth, offerId]);

  return (
    <NoFooter>
      <div className="payment-container">
        <h2>Zahlungsmethode</h2>
        <div className="payment-main">
          {paymentMethods.length > 0 &&
            paymentMethods.map((method, index) => (
              <PaymentMethod
                key={index.toString()}
                index={index}
                name={method.description}
                selectedMethod={selectedMethod}
                chooseMethod={setSelectedMethod}
                uri={method.image.size1x}
                id={method?.id}
              />
            ))}
        </div>
        <div className="payment-footer">
          <div className="payment-price-container">
            <p>Preis:</p>
            <span>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(price)}
            </span>
          </div>
          <div className="payment-button">
            <Button
              type="submit"
              onClick={nextStep}
              disabled={!selectedMethod?.toString()}
            >
              Weiter
            </Button>
          </div>
        </div>
      </div>
    </NoFooter>
  );
}
