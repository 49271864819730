export const searchReducer = (
  state = { startR: '', destinationR: '' },
  action
) => {
  switch (action.type) {
    case 'SEARCH_QUERY':
      return { ...state, ...action.payload }
    default:
      return state
  }
}
