import React from 'react'
import NoFooter from '../core/NoFooter'

const WaypointsScreen = () => {
  return (
    <div className='create'>
      <NoFooter>WaypointsScreen</NoFooter>
    </div>
  )
}

export default WaypointsScreen
