import React from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import HeaderNoBg from '../components/HeaderNoBgTest'
import ScrollToTop from '../components/ScrollToTop'

const LayoutNoBg = ({ children }) => {
  const navigate = useNavigate()
  return (
    <>
      <HeaderNoBg navigate={navigate} />
      <ScrollToTop />
      {children}
      <Footer />
    </>
  )
}

export default LayoutNoBg
