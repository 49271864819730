import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CreateAutocomplete from "../components/CreateAutocomplete";
import NoFooter from "../core/NoFooter";

export function CreateStart() {
  const navigate = useNavigate();
  const [start, setStart] = useState({
    address: "",
    geometry: { lat: null, lng: null },
  });

  useEffect(() => {
    const storage = getValueFromStorage("create-start");
    storage && setStart(JSON.parse(storage));
  }, []);

  const next = () => {
    if (start) {
      navigate("/create/destination");
    }
  };

  return (
    <div className="create">
      <NoFooter>
        <div className="leerfahrten-container">
          <Container>
            <div className="create-container d-flex">
              <div className="leerfahrten-create-text-container">
                <h1>Wo startest du?</h1>
                <p>Gib die Stadt ein in der Du startest</p>
              </div>
              <div className="leerfahrten-create-image-container"></div>
              <div className="leerfahrten-create-input-container">
                <CreateAutocomplete
                  type="create-start"
                  setState={setStart}
                  state={start}
                  initialValue={start.address}
                />
              </div>
            </div>
          </Container>
          <div className="leerfahrten-create-btn-cnt">
            <Container>
              <div className="d-flex justify-content-between">
                <div></div>
                {start?.geometry?.lat && (
                  <Button onClick={next} type="submit">
                    Weiter
                  </Button>
                )}
              </div>
            </Container>
          </div>
        </div>
      </NoFooter>
    </div>
  );
}

function getValueFromStorage(value) {
  return localStorage.getItem(value);
}
