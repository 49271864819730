import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import NoFooter from '../core/NoFooter'
import DatePicker, { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
registerLocale('de', de)

export function CreateTime() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [time, setTime] = useState()

  const validateStep = async (step, url) => {
    const before = await getValueFromStorage(step)
    if (!before) {
      return navigate(url)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    validateStep('create-date', '/create/date')
    const storage = getValueFromStorage('create-time')
    storage && setTime(Date.parse(storage))
  }, [])

  const chooseDate = (value) => {
    localStorage.setItem('create-time', value)
    setTime(value)
  }

  const next = () => {
    if (time) {
      navigate('/create/description')
    }
  }

  if (loading) {
    return (
      <div className='create'>
        <NoFooter>
          <div className='leerfahrten-container'>
            <Container>
              <div className='create-container d-flex'></div>
            </Container>
          </div>
        </NoFooter>
      </div>
    )
  }

  return (
    <div className='create'>
      <NoFooter>
        <div className='leerfahrten-container'>
          <Container>
            <div className='create-container d-flex'>
              <div className='leerfahrten-create-text-container'>
                <h1>Zu welcher Uhrzeit startest du?</h1>
              </div>
              <div className='leerfahrten-create-image-container'></div>
              <div className='leerfahrten-create-input-container'>
                <DatePicker
                  className='autocompleteInput'
                  locale='de'
                  selected={time}
                  onChange={(value) => chooseDate(value)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat='HH:mm'
                  dateFormat='HH:mm'
                  timeIntervals={15}
                />
              </div>
            </div>
          </Container>
          <div className='leerfahrten-create-btn-cnt'>
            <Container>
              <div className='d-flex justify-content-between'>
                <Button onClick={() => navigate(-1)} variant='secondary'>
                  zurück
                </Button>
                {time ? (
                  <Button onClick={next} type='submit'>
                    Weiter
                  </Button>
                ) : null}
              </div>
            </Container>
          </div>
        </div>
      </NoFooter>
    </div>
  )
}

function getValueFromStorage(value) {
  return localStorage.getItem(value)
}
