import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Feedback from "./Feedback";

const Footer = ({ location }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const toTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer>
      <div className="footer-top">
        <Container>
          <Row>
            <div className="d-flex align-items-center justify-content-between">
              <Feedback />
              <Col>
                <button onClick={toTop} className="footer-btn footer-to-top">
                  nach oben
                </button>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
      <div className="d-flex">
        <Container>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <div className="footer-col-inner">
                <h3 className="footer-heading">Leerfahrten24.de</h3>
                <ul>
                  <li>
                    <Link to="/#about">Unternehmen</Link>
                  </li>
                  {auth ? (
                    <>
                      <li>
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li>
                        <Link to="/settings">Einstellungen</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/login">Anmelden</Link>
                      </li>
                      <li>
                        <Link to="/register">Registrieren</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <div className="footer-col-inner">
                <h3 className="footer-heading">Service</h3>
                <ul>
                  {/*<li>
                    <Link to='/faq'>FAQ</Link>
                  </li>*/}
                  <li>
                    <Link to="/contact">Kontakt</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <div className="footer-col-inner">
                <h3 className="footer-heading">Rechtliches</h3>
                <ul>
                  <li>
                    <Link to="/impress">Impressum</Link>
                  </li>
                  <li>
                    <Link to="/agb">AGB</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Datenschutz</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <div className="footer-col-inner">
                <h3 className="footer-heading">In Verbindung bleiben</h3>
              </div>
            </Col>
          </Row>
          <div className="footer-bottom">
            <Row>
              <Col>
                <img src="/images/logo.svg" alt="Logo" id="brand-logo" />
              </Col>
              <Col className="text-right" style={{ textAlign: "right" }}>
                Copyright &copy; Leerfahrten 24 GmbH
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
