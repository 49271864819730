import React, { useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Layout from '../core/Layout'
import { toast } from 'react-toastify'
import { register } from '../actions/auth'
import { useSelector } from 'react-redux'

const Register = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const [buttonText, setButtonText] = useState('Registrieren')
  const [toastClass, setToastClass] = useState('')

  const { auth } = useSelector((state) => ({ ...state }))

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      let res = await register({
        name,
        email,
        password,
      })
      toast.success('Registrierung erfolgreich. Jetzt einloggen.')
      navigate('/login')
    } catch (err) {
      if (err.response.status === 400) toast.error(err.response.data)
    }
  }
  return (
    <Layout>
      <div className='leerfahrten-container'>
        {auth && <Navigate to='/dashboard' />}
        <Container>
          <div className='auth-container'>
            <div className='auth-wrapper'>
              <h1 className='mid-heading'>Registrieren</h1>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Ihr Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='E-Mail-Adresse'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId='paassword'>
                  <Form.Label>Passwort</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Passwort'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <p>
                  Bereits bei Leerfahrten24?{' '}
                  <Link to={'/login'} style={{ color: '#FF441F' }}>
                    Einloggen
                  </Link>
                </p>
                <Button variant='primary' type='submit'>
                  {buttonText}
                </Button>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Register
