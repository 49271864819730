import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AppPayment = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    if (params) {
      window.location.replace(
        `leerfahrten24://paymentMethods?redirect=true&offerId=${params.get(
          "oid"
        )}`
      );
    }
  }, [params]);
  return (
    <div style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <a
        style={{
          display: "block",
          padding: 15,
          color: "#fff",
          backgroundColor: "#FF5358",
          borderRadius: 5,
          margin: 20,
        }}
        href={`leerfahrten24://paymentMethods?redirect=true&offerId=${params.get(
          "oid"
        )}`}
      >
        Zur App
      </a>
    </div>
  );
};

export default AppPayment;
