import React, { useState, useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Upload } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { uploadRidePhotos } from "../actions/ride";
import { useNavigate, useParams } from "react-router-dom";
import { selectImage } from "../utils";

const UploadScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));

  const [images, setImages] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [rideId, setRideId] = useState(id);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadPhotos = () => {
    if (fileList.length > 0) {
      if (auth) {
        if (auth.token) {
          uploadRidePhotos(auth.token, rideId, fileList)
            .then(() => toast.success("Fotos erfolgreich hochgeladen"))
            .catch((err) => {
              toast.error(err.response.data);
            });
        }
      }
    }
  };

  const closeUpload = () => {
    if (rideId !== "undefined" && rideId !== null) {
      navigate(`/ride/${rideId}`);
    }
    if (rideId === "undefined") {
      navigate(`/`);
    }
  };

  const addImageHandler = async (e) => {
    const result = await selectImage(e);
    if (result) {
      setImages({
        base64: result.base64,
        path: result.path,
      });
    }
  };

  return (
    <Container>
      <nav className="navbar-leerfahrten rating-topbar">
        <Button
          onClick={closeUpload}
          variant="secondary"
          className="ml-auto close-button"
        >
          x
        </Button>
      </nav>
      <div className="rating-cnt">
        <h1>Sie haben die Fahrt angenommen</h1>
        <div className="upload-outside">
          <div className="upload-done">
            <CheckOutlined style={{ color: "#fff", fontSize: 24 }} />
          </div>
        </div>

        <h3>Laden Sie Bilder hoch um Ihre Fracht zu schützen!</h3>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
        >
          {fileList.length < 5 && "+ Bilder hochladen"}
        </Upload>
        <Button onClick={uploadPhotos} type="submit">
          Hochladen
        </Button>
      </div>
    </Container>
  );
};

export default UploadScreen;
