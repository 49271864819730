import React, { useState, useEffect } from 'react'
import Layout from '../core/Layout'
import { Container, Button, Toast, Row, Col, Form } from 'react-bootstrap'
import SettingsSidebar from '../components/SettingsSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, updatePassword } from '../actions/auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const DeleteScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => ({ ...state }))

  const [reason, setReason] = useState('Test')

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    window.localStorage.removeItem('auth')
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    deleteUser(auth.token, reason).then(() => {
      logout()
    })
  }
  return (
    <Layout>
      <div className='leerfahrten-container'>
        <div className='settings-topbar'>
          <Container>
            <h1 className='settings-topbar-header'>Einstellungen</h1>
          </Container>
        </div>

        <Container className='mt-4'>
          <div
            className='mb-2 cursor-pointer mobile-back-btn'
            onClick={() => navigate(-1)}>
            zurück
          </div>
          <Row>
            <SettingsSidebar classN='mobile-hide' />
            <Col xs={12} md={8} lg={8}>
              <div className='border-container'>
                <h3 className='settings-header'>Mein Konto deaktivieren</h3>
              </div>
              <div className='border-container'>
                <Form
                  onSubmit={submitHandler}
                  className='leerfahrten-settings-form'>
                  <h3 className='settings-subhead'>Konto deaktivieren</h3>

                  <div className='leerfahrten-input-container'></div>

                  <div className='text-right'>
                    <Button type='submit' variant='primary'>
                      Konto deaktivieren
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default DeleteScreen
